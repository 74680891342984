<template>
    <div class="col-md-4 mt-4">
        <div class="box-dashboard w-100">
            <h5 class="fw-semibold mb-0">CRM Specialist</h5>
            <div class="mt-4">
                <apexchart
                    type="donut"
                    :options="crmSpecialist"
                    :series="crmSpecialists"
                ></apexchart>
            </div>
            <div class="row">
                <div class="col-md-6 mt-2">
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <img
                            src="../../assets/point-green.png"
                            width="14"
                            alt=""
                        />
                        <div class="ms-2">Perempuan</div>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <img
                            src="../../assets/point-green-soft.png"
                            width="14"
                            alt=""
                        />
                        <div class="ms-2">Laki-laki</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-dashboard w-100 mt-4">
            <h5 class="fw-semibold mb-0">Today’s Jurnal Syukur</h5>
            <div class="mt-4">
                <span class="fw-bold size-20">
                    {{ decimalFormat(default_dashboard?.journal?.fill ?? 0) }}
                </span>
                <small class="size-14 text-gray-second">
                    /
                    {{ decimalFormat(default_dashboard?.journal?.user ?? 0) }}
                    CRM Specialist
                </small>
            </div>
            <div class="progress mt-1">
                <div
                    class="progress-bar green"
                    role="progressbar"
                    :style="{
                        width: default_dashboard?.journal?.percentage[0] + '%',
                    }"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    name: "CrmSpecialist",
    props: ["default_dashboard", "crmSpecialist", "crmSpecialists"],
    data() {
        return {
            moment: moment,
        };
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.progress {
    background-color: #e6e6e6;
    border-radius: 30px;
    height: 10px;
}
.progress-bar.blue {
    background-color: #1291ff;
    border-radius: 30px;
}
.progress-bar.green {
    background-color: #4ec681;
    border-radius: 30px;
}
.progress-bar.yellow {
    background-color: #bed515;
    border-radius: 30px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h1',[_c('span',{staticClass:"fw-bold size-32"},[_vm._v(_vm._s(_vm.default_dashboard.user_name)+", ")]),_c('small',{staticClass:"size-14 text-gray-second"},[_vm._v("selamat datang!")])]),_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"ms-3 mt-3 dropdown"},[_c('button',{staticClass:"btn btn-outline-green-second px-4 btn-lg",on:{"click":function($event){_vm.isCompare = !_vm.isCompare}}},[_c('img',{staticClass:"me-2",attrs:{"src":require("../../assets/compare.png"),"width":"18","alt":""}}),_vm._v(" Compare Data ")]),(_vm.isCompare)?_c('div',{staticClass:"dropdown-menu compare-data dropdown-menu-end mt-3 show"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 border-right"},[_c('div',[_c('p',{staticClass:"text-center text-gray-second"},[_vm._v(" Pembanding Pertama ")]),_c('date-range-picker',{ref:"picker1",attrs:{"opens":"left","locale-data":{
                                        direction: 'ltr',
                                        format: 'mm/dd/yyyy',
                                        separator: ' - ',
                                        applyLabel: 'Apply',
                                        cancelLabel: 'Reset',
                                        weekLabel: 'W',
                                        customRangeLabel: 'Custom Range',
                                        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        firstDay: 1
                                    },"show-dropdowns":false,"auto-apply":false,"linked-calendars":true},on:{"update":_vm.updateValuesFirst,"cancel":_vm.resetValueFirst},scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.date_first_start == '' || _vm.date_first_end == '')?_c('span',[_vm._v("Belum memilih Pembanding")]):_c('span',[_vm._v(_vm._s(_vm.date_first_start)+" - "+_vm._s(_vm.date_first_end))])]},proxy:true},{key:"footer",fn:function(data){return _c('div',{staticClass:"slot drp-buttons"},[_c('p',[_vm._v("Tanggal terpilih : "),_c('b',{staticClass:"text-black"},[_vm._v(_vm._s(data.rangeText))])]),_c('button',{staticClass:"cancelBtn btn btn-sm btn-secondary",attrs:{"type":"button"},on:{"click":data.clickCancel}},[_vm._v("Tutup ")]),_c('button',{staticClass:"cancelBtn btn btn-sm btn-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.resetValueFirst(data)}}},[_vm._v("Reset ")]),_c('button',{staticClass:"applyBtn btn btn-sm btn-success",attrs:{"disabled":data.in_selection,"type":"button"},on:{"click":data.clickApply}},[_vm._v("Apply ")])])}}],null,false,2035185141),model:{value:(_vm.dateRangeFirst),callback:function ($$v) {_vm.dateRangeFirst=$$v},expression:"dateRangeFirst"}}),_c('p',{staticClass:"text-center text-gray-second"},[_vm._v(" Pembanding Kedua ")]),_c('date-range-picker',{ref:"picker2",attrs:{"opens":"left","locale-data":{
                                        direction: 'ltr',
                                        format: 'mm/dd/yyyy',
                                        separator: ' - ',
                                        applyLabel: 'Apply',
                                        cancelLabel: 'Reset',
                                        weekLabel: 'W',
                                        customRangeLabel: 'Custom Range',
                                        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        firstDay: 1
                                    },"show-dropdowns":false,"auto-apply":false,"linked-calendars":true},on:{"update":_vm.updateValuesSecond,"cancel":_vm.resetValueSecond},scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.date_second_start == '' || _vm.date_second_end == '')?_c('span',[_vm._v("Belum memilih Pembanding")]):_c('span',[_vm._v(_vm._s(_vm.date_second_start)+" - "+_vm._s(_vm.date_second_end))])]},proxy:true},{key:"footer",fn:function(data){return _c('div',{staticClass:"slot drp-buttons"},[_c('p',[_vm._v("Tanggal terpilih : "),_c('b',{staticClass:"text-black"},[_vm._v(_vm._s(data.rangeText))])]),_c('button',{staticClass:"cancelBtn btn btn-sm btn-secondary",attrs:{"type":"button"},on:{"click":data.clickCancel}},[_vm._v("Tutup ")]),_c('button',{staticClass:"cancelBtn btn btn-sm btn-secondary",attrs:{"type":"button"},on:{"click":function($event){return _vm.resetValueFirst(data)}}},[_vm._v("Reset ")]),_c('button',{staticClass:"applyBtn btn btn-sm btn-success",attrs:{"disabled":data.in_selection,"type":"button"},on:{"click":data.clickApply}},[_vm._v("Apply ")])])}}],null,false,2737627445),model:{value:(_vm.dateRangeSecond),callback:function ($$v) {_vm.dateRangeSecond=$$v},expression:"dateRangeSecond"}})],1)])]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('button',{staticClass:"btn btn-outline-red px-4",on:{"click":function($event){_vm.isCompare = false}}},[_vm._v(" Tutup ")])]),_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"me-2"},[_c('button',{staticClass:"btn btn-outline-gray px-4",attrs:{"disabled":_vm.compare_data_first == '' ||
                                            _vm.compare_data_second == ''},on:{"click":function($event){return _vm.resetCompare()}}},[_vm._v(" Reset ")])]),_c('div',[_c('button',{staticClass:"btn btn-green-second px-4",attrs:{"disabled":_vm.compare_data_first == '' ||
                                            _vm.compare_data_second == ''},on:{"click":function($event){return _vm.compareData()}}},[_vm._v(" Simpan ")])])])])])]):_vm._e()])])]),_c('follow-up-activities',{attrs:{"default_dashboard":_vm.default_dashboard}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mt-4"},[_c('div',{staticClass:"box-dashboard w-100"},[_c('h5',{staticClass:"fw-semibold mb-0"},[_vm._v("Target Penjualan")]),_c('div',{staticClass:"mt-3"},[_c('apexchart',{ref:"targetPenjualan",attrs:{"type":"radialBar","height":"350","options":_vm.target,"series":_vm.targets}})],1),_vm._m(0)])]),_c('div',{staticClass:"col-md-8 mt-4"},[_c('div',{staticClass:"box-dashboard w-100"},[_c('h5',{staticClass:"fw-semibold mb-0"},[_vm._v("Grafik Harian Penjualan")]),_c('div',{staticClass:"mt-3"},[_c('apexchart',{ref:"areaChart",attrs:{"type":"area","height":"350","options":_vm.saleGrafik,"series":_vm.saleGrafiks}})],1)])])]),_c('deal-predic-lost',{attrs:{"default_dashboard":_vm.default_dashboard,"first_dashboard":_vm.first_dashboard,"second_dashboard":_vm.second_dashboard,"compare_data_first":_vm.compare_data_first,"compare_data_second":_vm.compare_data_second}}),_c('div',{staticClass:"box-dashboard mt-4"},[_vm._m(1),_c('div',{staticClass:"row justify-content-center mb-4"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.default_dashboard?.leaderboard?.slice(0, 3)),function(leaderboardTopThree,index){return _c('div',{key:index,staticClass:"col-md-4 d-flex align-items-stretch",class:{
                            'mt-leaderboard': leaderboardTopThree.rank != 1,
                            'mt-4': leaderboardTopThree.rank == 1,
                            'order-first': leaderboardTopThree.rank == 2,
                            'order-last': leaderboardTopThree.rank == 3,
                        }},[_c('div',{staticClass:"leaderboard w-100 position-relative",class:{
                                green:
                                    leaderboardTopThree.rank ==
                                    _vm.default_dashboard.leaderboard_current
                                        .rank,
                            }},[(
                                    leaderboardTopThree.rank ==
                                    _vm.default_dashboard.leaderboard_current
                                        .rank
                                )?_c('div',{staticClass:"congrats-position"},[_c('img',{attrs:{"src":require("../../assets/congrats.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"score-position"},[(leaderboardTopThree.rank == 2)?_c('img',{attrs:{"src":require("../../assets/second.png"),"alt":""}}):_vm._e(),(leaderboardTopThree.rank == 1)?_c('img',{attrs:{"src":require("../../assets/first.png"),"alt":""}}):_vm._e(),(leaderboardTopThree.rank == 3)?_c('img',{attrs:{"src":require("../../assets/third.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"mt-3"},[_c('center',[(leaderboardTopThree.rank == 1)?_c('div',{staticClass:"size-22 text-green fw-bold mb-2"},[_vm._v(" Penjualan Terbanyak! ")]):_vm._e(),_c('div',{staticClass:"leaderboard-logo",style:({
                                            'background-image':
                                                'url(' +
                                                leaderboardTopThree.avatar +
                                                ')',
                                        })}),_c('div',{staticClass:"size-18 fw-reguler mt-2 capitalize"},[_vm._v(" "+_vm._s(leaderboardTopThree.name)+" ")]),_c('div',{staticClass:"size-24 fw-bold text-green"},[_vm._v(" "+_vm._s(_vm.currency( leaderboardTopThree.deal ))+" ")])])],1)])])}),0)])]),_c('div',{staticClass:"position-relative z-9"},[_vm._l((_vm.default_dashboard.leaderboard),function(leaderboardAfterTopThree,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(leaderboardAfterTopThree.rank >= 4),expression:"leaderboardAfterTopThree.rank >= 4"}],key:index,staticClass:"leaderboard custom mt-3 p-2 position-relative",class:{
                    green:
                        leaderboardAfterTopThree.rank ==
                        _vm.default_dashboard.leaderboard_current.rank,
                }},[(
                        leaderboardAfterTopThree.rank ==
                        _vm.default_dashboard.leaderboard_current.rank
                    )?_c('div',{staticClass:"congrats-position"},[_c('img',{attrs:{"src":require("../../assets/congrats.png"),"alt":""}})]):_vm._e(),_c('div',{staticClass:"d-flex bd-highlight align-items-center"},[_c('div',{staticClass:"p-2 bd-highlight"},[_c('div',{staticClass:"text-gray-second fw-semibold me-3"},[_vm._v(" "+_vm._s(leaderboardAfterTopThree.rank)+"th ")])]),_c('div',{staticClass:"p-2 bd-highlight flex-grow-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"leaderboard-logo small me-2",style:({
                                    'background-image':
                                        'url(' +
                                        leaderboardAfterTopThree.avatar +
                                        ')',
                                })}),_c('div',{staticClass:"size-18 capitalize"},[_vm._v(" "+_vm._s(leaderboardAfterTopThree.name)+" ")])])]),_c('div',{staticClass:"p-2 bd-highlight"},[_c('div',{staticClass:"size-24 fw-bold text-green"},[_vm._v(" "+_vm._s(_vm.currency(leaderboardAfterTopThree.deal))+" ")])])])])}),(_vm.default_dashboard.leaderboard_current.rank > 5)?_c('div',{staticClass:"shadow-position"},[_c('img',{attrs:{"src":require("../../assets/box-shadow.png"),"width":"95%","alt":""}})]):_vm._e()],2),(_vm.default_dashboard.leaderboard_current.rank > 5)?_c('div',[_c('div',{staticClass:"leaderboard green p-2 position-relative mt-congrats"},[_vm._m(2),_c('div',{staticClass:"d-flex bd-highlight align-items-center"},[_c('div',{staticClass:"p-2 bd-highlight"},[_c('div',{staticClass:"text-gray-second fw-semibold me-3"},[_vm._v(" "+_vm._s(_vm.default_dashboard.leaderboard_current.rank)+"th ")])]),_c('div',{staticClass:"p-2 bd-highlight flex-grow-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"leaderboard-logo small me-2",style:({
                                    'background-image':
                                        'url(' +
                                        _vm.default_dashboard
                                            .leaderboard_current.avatar +
                                        ')',
                                })}),_c('div',{staticClass:"size-18"},[_vm._v(" "+_vm._s(_vm.default_dashboard.leaderboard_current .name)+" ")])])]),_c('div',{staticClass:"p-2 bd-highlight"},[_c('div',{staticClass:"size-24 fw-bold text-green"},[_vm._v(" "+_vm._s(_vm.currency( _vm.default_dashboard.leaderboard_current .deal ))+" ")])])])])]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{attrs:{"src":require("../../assets/point-green.png"),"width":"14","alt":""}}),_c('div',{staticClass:"ms-2"},[_vm._v("Bulanan")])])]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('img',{attrs:{"src":require("../../assets/point-green-soft.png"),"width":"14","alt":""}}),_c('div',{staticClass:"ms-2"},[_vm._v("Harian")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"fw-semibold mb-0"},[_vm._v("Leaderboards")]),_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"text-green",attrs:{"href":"/crm/target"}},[_c('u',[_vm._v("Lihat Detail")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"congrats-position"},[_c('img',{attrs:{"src":require("../../assets/congrats.png"),"alt":""}})])
}]

export { render, staticRenderFns }
<template>
    <div
        class="page-wrapper chiller-theme"
        v-bind:class="!isMobile ? 'toggled' : ''"
    >
        <notifications group="foo" position="top right" />
        <div class="btn btn-sm btn-primary" id="show-sidebar">
            <i class="fa fa-angle-right"></i>
        </div>
        <nav id="sidebar" class="sidebar-wrapper">
            <div class="sidebar-content">
                <div class="sidebar-brand">
                    <div id="close-sidebar">
                        <img src="../assets/arrow-back.png" width="30" alt="" />
                        <!-- <i class="fa fa-angle-left"></i> -->
                    </div>
                </div>
                <div class="sidebar-header">
                    <div class="">
                        <center>
                            <div>
                                <img
                                    src="../assets/logo.webp"
                                    width="70"
                                    alt=""
                                />
                            </div>
                            <div class="mt-3 fw-bold size-16">
                                {{ appName }}
                            </div>
                            <!-- <div class="size-14 text-gray">
                                {{ user.phone }}
                            </div> -->
                        </center>
                    </div>
                </div>
                <hr class="hr-horizontal mb-3" />
                <div class="sidebar-menu">
                    <ul>
                        <li
                            v-show="isRole(crmNonSpecialist)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/dashboard"
                                :class="{
                                    '': true,
                                    active: this.$route.name == 'dashboard-crm',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li v-show="isRole(onlyAdmin)" class="sidebar-dropdown">
                            <a
                                href="/dashboard"
                                :class="{
                                    '': true,
                                    active: this.$route.name == 'dashboard',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(crmStaffRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/dashboard/specialist"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                        'dashboard-crm-staff',
                                }"
                            >
                                <i class="fa fa-home"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(onlyAdminCRM)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/specialist"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'crm-specialist' ||
                                        this.$route.name ==
                                            'crm-specialist-detail' ||
                                        this.$route.name ==
                                            'crm-specialist-form',
                                }"
                            >
                                <i class="fa fa-user"></i>
                                <span>CRM Specialist</span>
                            </a>
                        </li>
                        <li class="sidebar-dropdown" v-show="isRole(assignCro)">
                            <a
                                href="/crm/assign/cro"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'crm-assign-cro' ||
                                        this.$route.name ==
                                            'crm-assign-cro-detail',
                                }"
                            >
                                <i class="fa fa-user"></i>
                                <span>Assign Data CRO</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(crmRolesNonCRO)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/customers/leads"
                                :class="{
                                    sub: true,
                                    active:
                                        this.$route.name ==
                                            'crm-customers-leads' ||
                                        this.$route.name ==
                                            'crm-customers-leads-detail' ||
                                        this.$route.name ==
                                            'crm-customers-leads-prospect' ||
                                        this.$route.name ==
                                            'crm-customers-form' ||
                                        this.$route.name ==
                                            'crm-customers-form-edit' ||
                                        this.$route.name ==
                                            'crm-customers-leads-tags',
                                }"
                            >
                                <i class="fa fa-credit-card"></i>
                                <span>Prospect and Leads Data</span>
                            </a>
                        </li>

                        <li
                            v-show="isRole(crmRolesNonCRO)"
                            class="sidebar-dropdown"
                            :class="{
                                '': true,
                                active:
                                    this.$route.name == 'crm-prospect-today',
                            }"
                        >
                            <a
                                href="javascript:void(0)"
                                :class="
                                    this.$route.name == 'crm-prospect-today'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-tomorrow'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name == 'crm-prospect-all'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-two-days'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-week'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-month'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-need-follow-up'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-detail'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-prediction'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-lost'
                                        ? 'active'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-deal'
                                        ? 'active'
                                        : ''
                                "
                            >
                                <i class="fa fa-book"></i>
                                <span>Follow Up</span>
                                <i class="fa fa-angle-down pull-right"></i>
                            </a>
                            <div
                                class="sidebar-submenu"
                                :class="
                                    this.$route.name == 'crm-prospect-today'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-tomorrow'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name == 'crm-prospect-all'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-two-days'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-week'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-next-month'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-need-follow-up'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-detail'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-prediction'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-lost'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name ==
                                              'crm-prospect-deal'
                                        ? 'block'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-today'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name != 'crm-prospect-all'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-tomorrow'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-next-two-days'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-next-week'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-next-month'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-need-follow-up'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-detail'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-prediction'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-lost'
                                        ? 'none'
                                        : '' ||
                                          this.$route.name !=
                                              'crm-prospect-deal'
                                        ? 'none'
                                        : ''
                                "
                            >
                                <ul>
                                    <li v-show="isRole(crmRolesNonCRO)">
                                        <a
                                            href="/crm/prospect/all"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-all',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    All Follow Up
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-all',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-all',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.today !=
                                                            null
                                                                ? prospect_count?.today +
                                                                  prospect_count?.tomorrow +
                                                                  prospect_count?.next_2_days +
                                                                  prospect_count?.next_month +
                                                                  prospect_count?.need_follow_up
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-show="isRole(crmRolesNonCRO)">
                                        <a
                                            href="/crm/prospect/today"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-today',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Today
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-today',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-today',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.today
                                                                ? prospect_count?.today
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-show="isRole(crmRolesNonCRO)">
                                        <a
                                            href="/crm/prospect/tomorrow"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-tomorrow',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Tomorrow
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-tomorrow',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-tomorrow',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.tomorrow
                                                                ? prospect_count?.tomorrow
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-show="isRole(crmRolesNonCRO)">
                                        <a
                                            href="/crm/prospect/next-two-days"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-next-two-days',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Next 2 Days
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-two-days',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-next-two-days',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.next_2_days
                                                                ? prospect_count?.next_2_days
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-show="isRole(crmRolesNonCRO)">
                                        <a
                                            href="/crm/prospect/next-week"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-next-week',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Next Week
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-week',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-next-week',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.next_week
                                                                ? prospect_count?.next_week
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-show="isRole(crmRolesNonCRO)">
                                        <a
                                            href="/crm/prospect/next-month"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-next-month',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Next Month
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-next-month',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-next-month',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.next_month
                                                                ? prospect_count?.next_month
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-show="isRole(crmRolesNonCRO)">
                                        <a
                                            href="/crm/prospect/need-follow-up"
                                            :class="{
                                                sub: true,
                                                active:
                                                    this.$route.name ==
                                                    'crm-prospect-need-follow-up',
                                            }"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    <i
                                                        class="fa fa-circle sub"
                                                    ></i>
                                                    Need Follow Up
                                                </div>
                                                <div class="ms-auto">
                                                    <small
                                                        v-bind:class="{
                                                            'blue-primary':
                                                                this.$route
                                                                    .name ==
                                                                'crm-prospect-need-follow-up',
                                                            'gray-solid':
                                                                this.$route
                                                                    .name !=
                                                                'crm-prospect-need-follow-up',
                                                        }"
                                                        class="tag radius"
                                                    >
                                                        {{
                                                            prospect_count?.need_follow_up
                                                                ? prospect_count?.need_follow_up
                                                                : 0
                                                        }}
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li
                            v-show="isRole(crmSpecialistCro)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/follow-up-cro"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'crm-follow-up-cro' ||
                                        this.$route.name ==
                                            'crm-follow-up-cro-detail',
                                }"
                            >
                                <i class="fa fa-book"></i>
                                <span>Follow Up CRO</span>
                            </a>
                        </li>
                        <li
                            v-show="isRole(onlyAdminCRM)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/target"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'crm-target' ||
                                        this.$route.name ==
                                            'crm-order-create' ||
                                        this.$route.name == 'crm-order-edit',
                                }"
                            >
                                <i class="fa fa-building"></i>
                                <span>Company Target</span>
                            </a>
                        </li>

                        <li
                            v-show="isRole(crmRepeatOrder)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/order"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'crm-order' ||
                                        this.$route.name == 'crm-order-detail',
                                }"
                            >
                                <i class="fa fa-cart-plus"></i>
                                <span>Deal Order</span>
                            </a>
                        </li>

                        <li
                            v-show="isRole(crmRepeatOrder)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/order-lost"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'crm-order-lost' ||
                                        this.$route.name ==
                                            'crm-order-lost-detail',
                                }"
                            >
                                <i class="fa fa-shopping-cart"></i>
                                <span>Lost Order</span>
                            </a>
                        </li>

                        <li
                            v-show="isRole(crmNonSpecialist)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/gratitude-journal"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'gratitude-journal' ||
                                        this.$route.name ==
                                            'gratitude-journal-create' ||
                                        this.$route.name ==
                                            'gratitude-journal-user' ||
                                        this.$route.name ==
                                            'gratitude-journal-user-detail',
                                }"
                            >
                                <i class="fa fa-heart"></i>
                                <span>Jurnal Syukur</span>
                            </a>
                        </li>

                        <li v-show="isRole(crmRoles)" class="sidebar-dropdown">
                            <a
                                href="/crm/tags"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'crm-tags' ||
                                        this.$route.name == 'crm-tags-create' ||
                                        this.$route.name == 'crm-tags-detail',
                                }"
                            >
                                <i class="fa fa-tags"></i>
                                <span>Tags</span>
                            </a>
                        </li>

                        <li
                            v-show="isRole(crmAdminOnly)"
                            class="sidebar-dropdown"
                        >
                            <a
                                href="/crm/branchs"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name == 'crm-branchs' ||
                                        this.$route.name ==
                                            'crm-branchs-create' ||
                                        this.$route.name ==
                                            'crm-branchs-edit' ||
                                        this.$route.name ==
                                            'crm-branchs-detail',
                                }"
                            >
                                <i class="fa fa-bank"></i>
                                <span>Klik’s Branch</span>
                            </a>
                        </li>

                        <li
                            v-show="isRole(crmStaffRoles)"
                            class="sidebar-dropdown"
                        >
                            <a
                                :href="'/crm/gratitude-journal/user/' + user_id"
                                :class="{
                                    '': true,
                                    active:
                                        this.$route.name ==
                                            'gratitude-journal' ||
                                        this.$route.name ==
                                            'gratitude-journal-create' ||
                                        this.$route.name ==
                                            'gratitude-journal-user' ||
                                        this.$route.name ==
                                            'gratitude-journal-user-detail',
                                }"
                            >
                                <i class="fa fa-heart"></i>
                                <span>Jurnal Syukur</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <main class="page-content">
            <div class="nav-top-main">
                <div class="d-flex align-items-center">
                    <div></div>
                    <div class="ms-auto">
                        <div class="d-flex align-items-center">
                            <div class="dropdown">
                                <div
                                    class="notifications toggle dd-trigger me-4 cursor-pointer"
                                >
                                    <span
                                        class="count"
                                        id="notifications-count"
                                    >
                                        {{ totalNotif }}
                                    </span>
                                    <span class="fa fa-bell-o size-22"></span>
                                </div>
                                <div class="dropdown-menu">
                                    <div class="d-flex items-align-center">
                                        <div class="size-16 fw-semibold">
                                            Notifikasi
                                        </div>
                                    </div>
                                    <hr />
                                    <template v-if="notif_all.length > 0">
                                        <div
                                            v-bind:class="{
                                                'scroll-notif':
                                                    notif_all.length > 4,
                                            }"
                                        >
                                            <div class="d-flex mb-3">
                                                <div class="ms-auto">
                                                    <a
                                                        href="javascript:void(0)"
                                                        @click="readAllNotif()"
                                                        class="text-green"
                                                    >
                                                        <u
                                                            >Tandai semua telah
                                                            dibaca</u
                                                        >
                                                    </a>
                                                </div>
                                            </div>
                                            <template
                                                v-for="(
                                                    notif, index
                                                ) in notif_all"
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="readNotif(notif.id)"
                                                    :key="index"
                                                >
                                                    <div
                                                        class="d-flex align-items-center mb-2"
                                                    >
                                                        <div class="me-2">
                                                            <div
                                                                class="user-avatar-sidebar user-notif"
                                                                v-bind:class="{
                                                                    grayscale:
                                                                        notif.status ==
                                                                        'read',
                                                                }"
                                                                :style="{
                                                                    'background-image':
                                                                        'url(' +
                                                                        notif.icon +
                                                                        ')',
                                                                }"
                                                            >
                                                                <span
                                                                    class="position-absolute top-0 start-100 translate-middle p-alert-notif bg-warning border border-light rounded-circle"
                                                                    v-if="
                                                                        notif.status ==
                                                                        'pending'
                                                                    "
                                                                >
                                                                    <span
                                                                        class="visually-hidden"
                                                                        >New
                                                                        alerts</span
                                                                    >
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div
                                                                class="d-flex align-items-center"
                                                            >
                                                                <div
                                                                    class="size-16 fw-semibold"
                                                                    v-bind:class="{
                                                                        'text-gray-dashboard':
                                                                            notif.status ==
                                                                            'read',
                                                                        'text-dark':
                                                                            notif.status ==
                                                                            'pending',
                                                                    }"
                                                                >
                                                                    {{
                                                                        notif.title
                                                                    }}
                                                                </div>
                                                                <div
                                                                    class="ms-auto text-gray size-12"
                                                                >
                                                                    {{
                                                                        notif.created_at
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                v-bind:class="{
                                                                    'text-gray-dashboard':
                                                                        notif.status ==
                                                                        'read',
                                                                    'text-dark':
                                                                        notif.status ==
                                                                        'pending',
                                                                }"
                                                            >
                                                                {{
                                                                    notif.subtitle
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="mt-4">
                                            <center>
                                                <img
                                                    src="../assets/no-notif.png"
                                                    width="120"
                                                    alt=""
                                                />
                                                <div
                                                    class="mt-3 text-gray-dashboard"
                                                >
                                                    Belum ada notifikasi
                                                </div>
                                            </center>
                                        </div>
                                    </template>
                                    <hr v-if="notif_all.length > 0" />
                                    <div
                                        class="text-center"
                                        v-if="notif_all.length > 0"
                                    >
                                        <a
                                            href="/notification"
                                            class="text-green"
                                        >
                                            Lihat Semua Notifikasi
                                            <i
                                                class="fa fa-angle-right ms-2"
                                            ></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-profile">
                                <div class="toggle-profile cursor-pointer">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2">
                                            <template
                                                v-if="user.avatar != null"
                                            >
                                                <div
                                                    class="user-avatar-sidebar user-small"
                                                    :style="{
                                                        'background-image':
                                                            'url(' +
                                                            user.avatar +
                                                            ')',
                                                    }"
                                                ></div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="user-avatar-sidebar user-small"
                                                    :style="{
                                                        'background-image':
                                                            'url(' +
                                                            require('../assets/user.jpg') +
                                                            ')',
                                                    }"
                                                ></div>
                                            </template>
                                        </div>
                                        <div
                                            class="fw-medium size-14"
                                            style="line-height: 17px"
                                        >
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div>
                                                    {{ user.name }}
                                                    <div
                                                        class="text-gray-second"
                                                        v-show="
                                                            isRole(
                                                                crmDashboardRole
                                                            )
                                                        "
                                                    >
                                                        {{ user.role_label }}
                                                    </div>
                                                </div>
                                                <div class="ms-2">
                                                    <i
                                                        class="fa fa-angle-down ms-2 size-16 fw-bold"
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-menu-profile">
                                    <router-link :to="{ name: profileRole() }">
                                        <a
                                            class="size-14 fw-semibold text-dark"
                                        >
                                            Edit Profile
                                        </a>
                                    </router-link>
                                    <hr />
                                    <a
                                        href="javascript:void(0)"
                                        @click="logout()"
                                        class="text-danger size-14 fw-semibold"
                                    >
                                        <i class="fa fa-sign-out me-1"></i>
                                        Log Out
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt-nav-top">
                <router-view
                    :message="this.$attrs.message"
                    :totalNotif="totalNotif"
                    @update-data-event="getCountNotif"
                />
            </div>
        </main>
    </div>
</template>

<script>
// import Header from "../components/HeaderAdmin.vue";
import Api from "../api/Api";
import $ from "jquery";
export default {
    components: {},
    data() {
        return {
            appName: "CRM KLIK",
            isShowProfile: false,
            isShowProspect: false,
            user_id: "",
            user: {},
            roles: [],
            prospect_count: {},
            partnership_prospect_count: {},
            crmRoles: [
                "admin-crm",
                "admin-super",
                "crm-manager",
                "crm-specialist",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            crmAdminOnly: ["admin-crm"],
            crmRolesNonCRO: [
                "admin-crm",
                "admin-super",
                "crm-manager",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            crmStaffRoles: [
                "crm-specialist",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            crmRepeatOrder: [
                "admin-crm",
                "crm-manager",
                "crm-specialist",
                "admin-super",
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            crmNonSpecialist: ["crm-manager"],
            onlyAdminCRM: ["admin-crm", "admin-super", "crm-manager"],
            onlyAdmin: ["admin-crm", "admin-super"],
            assignCro: ["admin-crm", "crm-manager"],
            crmSpecialistCro: [
                "crm-specialist-cro",
                "admin-crm",
                "crm-manager",
            ],
            crmDashboardRole: ["crm-specialist", "admin-crm", "crm-manager"],
            isMobile: false,
            notif_all: [],
            notif_trawlpack: [],
            notif_trawltruck: [],
            notif_trawlcarrier: [],
            totalNotif: 0,
        };
    },
    created() {
        this.setAppName();
        this.getUserLogin();
        this.getProspectCount();
        this.getPartnershipProspectCount();
        this.getNotif();
        this.getCountNotif();
    },
    beforeDestroy() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.onResize, {
                passive: true,
            });
        }
    },
    mounted() {
        $(".sidebar-dropdown > a").click(function () {
            $(".sidebar-submenu").slideUp(200);
            if ($(this).parent().hasClass("active")) {
                $(".sidebar-dropdown").removeClass("active");
                $(this).parent().removeClass("active");
            } else {
                $(".sidebar-dropdown").removeClass("active");
                $(this).next(".sidebar-submenu").slideDown(200);
                $(this).parent().addClass("active");
            }
        });
        $("#close-sidebar").click(function () {
            $(".page-wrapper").removeClass("toggled");
        });
        $("#show-sidebar").click(function () {
            $(".page-wrapper").addClass("toggled");
        });

        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });

        const $menu = $(".dropdown");

        const onMouseUp = (e) => {
            if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
                $menu.removeClass("is-active");
            }
        };

        $(".toggle").on("click", () => {
            $menu
                .toggleClass("is-active")
                .promise()
                .done(() => {
                    if ($menu.hasClass("is-active")) {
                        $(document).on("mouseup", onMouseUp);
                    } else {
                        $(document).off("mouseup", onMouseUp);
                    }
                });
        });

        const $menu_profile = $(".dropdown-profile");

        const onMouseUpProfile = (e) => {
            if (
                !$menu_profile.is(e.target) &&
                $menu_profile.has(e.target).length === 0
            ) {
                $menu_profile.removeClass("is-active-profile");
            }
        };

        $(".toggle-profile").on("click", () => {
            $menu_profile
                .toggleClass("is-active-profile")
                .promise()
                .done(() => {
                    if ($menu_profile.hasClass("is-active-profile")) {
                        $(document).on("mouseup", onMouseUpProfile);
                    } else {
                        $(document).off("mouseup", onMouseUpProfile);
                    }
                });
        });
    },
    methods: {
        setAppName() {
            let appName = process.env.VUE_APP_APP_NAME;
            if (appName) {
                this.appName = appName;
            }
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
        profileRole() {
            return "crm-profile";
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user = res.data.data;
                    this.user_id = res.data.data.hash;
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getProspectCount() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospect/count`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.prospect_count = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPartnershipProspectCount() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/prospect/count`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.partnership_prospect_count = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        logout() {
            this.$notify({
                group: "foo",
                type: "success",
                title: "Success",
                text: "Logout success",
            });
            localStorage.clear();
            setTimeout(() => {
                this.$router.push({ path: "/" });
            }, 1500);
        },
        getNotif() {
            var payload = {
                limit: 10,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/notification/user`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    let data = res.data.data.list_data;
                    this.notif_all = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCountNotif() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/count`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.totalNotif = res.data.data.pending;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        readNotif(id) {
            var data = {
                notification_id: id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getNotif();
                    this.getCountNotif();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        readAllNotif() {
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/update_all`,
                "",
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getNotif();
                    this.getCountNotif();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        postNotif() {
            var data = {
                prefix: "users",
                id: this.user_id,
                token: localStorage.token,
                platform: "web",
                deviceId: "unique2",
                type: "logout",
            };
            Api.post(`/cb/fcm/update`, data, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    setTimeout(() => {
                        this.$router.push({ path: "/" });
                    }, 1500);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
@keyframes swing {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    30% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    60% {
        transform: rotate(5deg);
    }
    70% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes sonar {
    0% {
        transform: scale(0.9);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.page-wrapper {
    height: 100vh;
}
.page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
}
.page-wrapper .theme.chiller-theme {
    background: #1e2229;
}
/*----------------toggeled sidebar----------------*/
.page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
}
@media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
        padding-left: 260px;
    }
}
#show-sidebar {
    position: fixed;
    left: 0;
    top: 10px;
    padding: 1px;
    font-size: 20px;
    border-radius: 0 20px 20px 0px;
    width: 35px;
    transition-delay: 0.3s;
    z-index: 9999999999;
}
.page-wrapper.toggled #show-sidebar {
    left: -40px;
}
.sidebar-wrapper {
    width: 260px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
}
.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.sidebar-wrapper a {
    text-decoration: none;
}
.sidebar-content {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto;
    /* position: relative; */
    padding: 15px 20px;
}
.sidebar-content.desktop {
    overflow-y: hidden;
}
/* .sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
} */
.sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    font-weight: bold;
    flex-grow: 1;
}
.sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
}
/*--------------------sidebar-header----------------------*/
.sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-info > span {
    display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
    color: #5cb85c;
}
/*-----------------------sidebar-search------------------------*/
.sidebar-wrapper .sidebar-search > div {
    padding: 10px 20px;
}
/*----------------------sidebar-menu-------------------------*/
.sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
    font-weight: 600;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 0px 8px 4px;
}
.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 4px;
    font-size: 14px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a.active i {
    color: #3782e6;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 10px;
    font-size: 13px;
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
    transform: rotate(90deg);
    right: 17px;
}
.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
}
.sidebar-footer > a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
    color: #3782e6;
    font-weight: 600;
    font-size: 16px;
}
.sidebar-footer > a .notification {
    position: absolute;
    top: 0;
}
.badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
}
.badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    animation: sonar 1.5s infinite;
}
/*--------------------------page-content-----------------------------*/
.page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    /* padding-top: 20px; */
}
.page-wrapper .page-content > div {
    /* padding: 20px 40px; */
    padding-right: 0px;
    padding-left: 0px;
}
.page-wrapper .page-content {
    overflow-x: hidden;
}
.chiller-theme .sidebar-wrapper {
    background: #fff;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
}
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a {
    color: #000;
    font-weight: 500;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active {
    color: #000;
    background: #ebf6ff;
    font-weight: 600;
    border-radius: 12px;
    margin-bottom: 6px;
    padding: 4px 0px 4px 4px;
}
.page-wrapper.chiller-theme.toggled #close-sidebar {
    color: #bdbdbd;
}
.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
    color: #ffffff;
}
.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
}
.chiller-theme .sidebar-footer {
    color: #3782e6;
    box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%);
    padding: 5px;
    background: #fff;
}
.chiller-theme .sidebar-footer > a:first-child {
    border-left: none;
}
.chiller-theme .sidebar-footer > a:last-child {
    border-right: none;
}
#close-sidebar {
    /* background: #3782e6; */
    /* color: #fff !important; */
    /* box-shadow: 0 0.125rem 0.25rem rgb(219 83 99 / 10%); */
    width: 30px;
    height: 30px;
    /* border-radius: 50px; */
    text-align: center;
    position: absolute;
    right: 20px;
    top: 11px;
    z-index: 99999;
}
.radius {
    border-radius: 50px;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.active.sub {
    border-left: none;
    color: #3782e6;
    background: #fff;
}
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a.sub {
    padding: 8px 8px 8px 5px;
}
.sidebar-wrapper .sidebar-menu ul li a i.sub {
    margin-right: 0px;
    font-size: 12px;
}
.block {
    display: block;
}
.none {
    display: none;
}
.user-avatar-sidebar {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.edit-position {
    position: absolute;
    right: -10px;
    color: #dc3545;
    background: #fff;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    box-shadow: 0 2px 5px rgb(196 205 213 / 25%);
    text-align: center;
    padding: 1px;
}
.nav-top-main {
    background: #fff;
    /* box-shadow: 0 0px 30px 0 rgb(144 30 44 / 5%); */
    padding: 10px;
    width: 100%;
    right: 0;
    z-index: 99;
    left: 0;
    padding-right: 40px !important;
    padding-left: 40px !important;
    position: fixed;
}
.user-small {
    width: 35px !important;
    height: 35px !important;
}
.mt-nav-top {
    margin-top: 50px;
}
.profile-box-menu {
    background: #fff;
    padding: 15px !important;
    width: 180px;
    position: fixed;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-top-left-radius: 0px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.hr-horizontal {
    background: rgba(0, 0, 0, 0);
    border: 0;
    height: 1px;
    color: inherit;
    opacity: 0.25;
    margin: 0.5rem 0;
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.4),
        transparent
    );
}
.hidden {
    display: none;
}
.show {
    display: block !important;
}
.p-alert-notif {
    padding: 5px;
    left: 6px !important;
    top: 2px !important;
}
.count {
    position: absolute;
    top: -6px;
    right: 15px;
    height: 15px;
    width: 15px;
    background: #e60013;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: bold;
    z-index: 99;
    line-height: 15px;
}
.grayscale {
    filter: grayscale(1);
    /* background-color: #e0e0e0; */
    background-blend-mode: overlay;
}
.user-notif {
    width: 40px !important;
    height: 40px !important;
}
.scroll-notif {
    height: 240px;
    overflow-y: scroll;
}
.dropdown {
    position: relative;
}
.dropdown-menu {
    display: none;
}
.is-active .dropdown-menu {
    display: block;
    background: #fff;
    padding: 15px !important;
    width: auto;
    position: fixed;
    min-width: 380px;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}
.dropdown-profile {
    position: relative;
}
.dropdown-menu-profile {
    display: none;
}
.is-active-profile .dropdown-menu-profile {
    display: block;
    background: #fff;
    padding: 15px !important;
    width: 180px;
    position: fixed;
    z-index: 9999;
    right: 25px;
    top: 70px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}
</style>

<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Jurnal Syukur</h3>
                <div class="ms-auto">
                    <button
                        class="btn btn-green btn-with-search px-4 pull-right m-mt-1 m-w-100"
                        disabled
                        v-if="is_export"
                    >
                        <span
                            class="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <img
                            src="../../assets/excel.png"
                            width="20"
                            class="me-1"
                            alt=""
                        />
                        Export
                    </button>
                    <button
                        v-else
                        class="btn btn-green btn-with-search px-4 pull-right m-mt-1 m-w-100"
                        @click="exportData()"
                    >
                        <img
                            src="../../assets/excel.png"
                            width="20"
                            class="me-1"
                            alt=""
                        />
                        Export
                    </button>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div>
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search Name"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-4">
                    <button
                        v-bind:class="{
                            'btn-outline-green': range != '',
                            'btn-outline-black': range == '',
                        }"
                        class="btn btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter-green.png"
                            class="me-1"
                            width="18"
                            alt=""
                            v-if="range != ''"
                        />
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                            v-else
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-12 mb-2" v-show="isRole(branchRoles)">
                        <label class="fw-bold mb-1">Branch</label>
                        <multiselect
                            v-model="branch"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Branch"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4">
                        <div>
                            <label class="fw-bold">Date Range</label>
                            <select
                                class="form-control form-lg form-select mt-1"
                                v-model="range"
                                @change="changeDateRange()"
                            >
                                <option value="">Select Range</option>
                                <option value="custom">Custom</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="this_week">This Week</option>
                                <option value="last_week">Last Week</option>
                                <option value="to_weeks_ago">
                                    Two Weeks Ago
                                </option>
                                <option value="this_month">This Month</option>
                                <option value="last_month">Last Month</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="fw-bold">Start</label>
                        <date-picker
                            v-model="req.start"
                            type="date"
                            value-type="format"
                            class="w-100 mt-1"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Start"
                            @change="getStartDate(req.start)"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-4">
                        <label class="fw-bold">End</label>
                        <date-picker
                            v-model="req.end"
                            type="date"
                            value-type="format"
                            class="w-100 mt-1"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="End"
                            @change="getStartDate(req.end)"
                        >
                        </date-picker>
                    </div>
                </div>
                <div class="mt-3">
                    <button
                        class="btn btn-outline-black px-5 me-3"
                        :disabled="range == '' && branch == null"
                        @click="clearFilter()"
                    >
                        Clear
                    </button>
                    <button
                        class="btn btn-green px-5 me-3"
                        :disabled="range == '' && branch == null"
                        @click="filterDate()"
                    >
                        Filter
                    </button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="mt-4" v-if="is_list_ready">
                        <ListLoader />
                    </div>
                    <template v-else>
                        <template v-if="data.list_data.length > 0">
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Name
                                            </th>
                                            <th
                                                class="text-center"
                                                scope="col"
                                                v-show="isRole(branchRoles)"
                                            >
                                                Branch Name
                                            </th>
                                            <th
                                                class="text-center"
                                                scope="col"
                                                style="width: 180px"
                                            >
                                                Status
                                            </th>
                                            <th class="text-center" scope="col">
                                                Penalty
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template
                                            v-for="(
                                                item, index
                                            ) in data.list_data"
                                        >
                                            <tr :key="index">
                                                <td class="text-center">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="text-center">
                                                    {{
                                                        moment(
                                                            item.date
                                                        ).format("DD/MM/YYYY")
                                                    }}
                                                </td>
                                                <td>
                                                    <a
                                                        class="text-link"
                                                        :href="
                                                            '/crm/gratitude-journal/user/' +
                                                            item.id
                                                        "
                                                    >
                                                        {{ item.name }}
                                                    </a>
                                                </td>
                                                <td
                                                    v-show="isRole(branchRoles)"
                                                >
                                                    {{ item.branch_name }}
                                                </td>
                                                <td class="text-center">
                                                    <template
                                                        v-if="
                                                            item.status ==
                                                            'undone'
                                                        "
                                                    >
                                                        <div
                                                            class="tag red-solid w-100 radius"
                                                        >
                                                            Unfinished
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div
                                                            class="tag green-solid w-100 radius"
                                                        >
                                                            Finish
                                                        </div>
                                                    </template>
                                                </td>
                                                <td class="text-center">
                                                    {{
                                                        currencyFormat(
                                                            item.penalty
                                                        )
                                                    }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center mt-section">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </template>
                    <div class="mt-4">
                        <b-pagination-nav
                            v-if="data.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="data.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        Multiselect,
    },
    name: "GratitudeJournal",
    data() {
        return {
            moment: moment,
            req: {
                search: "",
                start: "",
                end: "",
            },
            start_date: "",
            end_date: "",
            is_list_ready: false,
            data: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            range: "",
            isFilter: false,
            is_export: false,
            branchRoles: ["admin-crm"],
            roles: [],
            branch: null,
            branchs: [],
        };
    },
    created() {
        this.getList();
        this.getUserLogin();
        this.getBranchs();
    },
    methods: {
        cancelFilter() {
            this.isFilter = false;
            this.range = "";
            this.req.start = "";
            this.req.end = "";
            this.start_date = "";
            this.end_date = "";
        },
        changePage() {
            this.getList();
        },
        getStartDate(val) {
            if (val) {
                this.range = "custom";
            }
        },
        filterDate() {
            this.start_date = this.req.start;
            this.end_date = this.req.end;
            this.getList();
        },
        clearFilter() {
            this.range = "";
            this.branch = null;
            this.req.start = "";
            this.req.end = "";
            this.start_date = "";
            this.end_date = "";
            this.currentPage = 0;
            this.getList();
        },
        changeDateRange() {
            var today = new Date();
            var thisWeekStart = moment().clone().startOf("isoWeek");
            var thisWeekEnd = moment().clone().endOf("isoWeek");
            var lastWeekStart = moment()
                .subtract(1, "weeks")
                .startOf("isoWeek");
            var lastWeekEnd = moment().subtract(1, "weeks").endOf("isoWeek");
            var yesterday = new Date().setDate(new Date().getDate() - 1);
            var toWeekAgo = moment().subtract(14, "d");
            var thisMonthStart = moment().startOf("month");
            var thisMonthEnd = moment().endOf("month");
            var lastMonthStart = moment()
                .subtract(1, "months")
                .startOf("month");
            var lastMonthEnd = moment().subtract(1, "months").endOf("month");

            if (this.range == "custom") {
                this.req.start = moment(today).format("DD MMMM YYYY");
                this.req.end = moment(today).format("DD MMMM YYYY");
            } else if (this.range == "today") {
                this.req.start = moment(today).format("DD MMMM YYYY");
                this.req.end = moment(today).format("DD MMMM YYYY");
            } else if (this.range == "yesterday") {
                this.req.start = moment(yesterday).format("DD MMMM YYYY");
                this.req.end = moment(yesterday).format("DD MMMM YYYY");
            } else if (this.range == "this_week") {
                this.req.start = moment(thisWeekStart).format("DD MMMM YYYY");
                this.req.end = moment(thisWeekEnd).format("DD MMMM YYYY");
            } else if (this.range == "last_week") {
                this.req.start = moment(lastWeekStart).format("DD MMMM YYYY");
                this.req.end = moment(lastWeekEnd).format("DD MMMM YYYY");
            } else if (this.range == "to_weeks_ago") {
                this.req.start = moment(toWeekAgo).format("DD MMMM YYYY");
                this.req.end = moment(today).format("DD MMMM YYYY");
            } else if (this.range == "this_month") {
                this.req.start = moment(thisMonthStart).format("DD MMMM YYYY");
                this.req.end = moment(thisMonthEnd).format("DD MMMM YYYY");
            } else if (this.range == "last_month") {
                this.req.start = moment(lastMonthStart).format("DD MMMM YYYY");
                this.req.end = moment(lastMonthEnd).format("DD MMMM YYYY");
            } else {
                this.req.start = "";
                this.req.end = "";
                this.start_date = "";
                this.end_date = "";
            }
        },
        changeSearch() {
            this.currentPage = 1;
            this.getList();
        },
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getList() {
            let payload = {
                search: this.req.search,
                per_page: this.per_page,
                branch_id: this.branch?.id,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date).format(
                    "DD-MM-YYYY"
                );
            }
            if (this.end_date) {
                payload.end_date = moment(this.end_date).format("DD-MM-YYYY");
            }
            this.is_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/team`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.data = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        exportData() {
            let payload = {
                search: this.req.search,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date).format(
                    "DD-MM-YYYY"
                );
            }
            if (this.end_date) {
                payload.end_date = moment(this.end_date).format("DD-MM-YYYY");
            }
            this.is_export = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/export`,
                {
                    params: payload,
                    responseType: "blob",
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data_excel_request = res.data;
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "jurnal-syukur.xls");
                    document.body.appendChild(link);
                    link.click();
                    this.is_export = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_export = false;
                });
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #3782e6;
}
.close-icon {
    width: 25px;
    height: 25px;
    background: #3782e6;
    color: #fff;
    border-radius: 50px;
    padding: 3px;
    text-align: center;
    font-size: 13px;
    position: absolute;
    right: -5px;
    top: -5px;
}
</style>

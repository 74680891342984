var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-container"},[_vm._m(0),_c('div',{staticClass:"box mt-4"},[_c('div',{staticClass:"d-flex align-items-center no-flex"},[_c('h3',{staticClass:"fw-bold"},[_vm._v(" Detail Jurnal Syukur of "+_vm._s(_vm.data?.list_data[0]?.name)+" ")]),_vm._m(1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[(_vm.is_list_ready)?_c('div',{staticClass:"mt-4"},[_c('ListLoader')],1):[(_vm.data.list_data.length > 0)?[_c('div',{staticClass:"table-responsive-custom"},[_c('table',{staticClass:"table table-bordered mt-4"},[_vm._m(2),_c('tbody',[_vm._l((_vm.data.list_data),function(item,index){return [_c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.moment( item.date ).format("DD/MM/YYYY"))+" ")]),_c('td',{staticStyle:{"width":"200px"}},[_c('a',{attrs:{"href":'/crm/gratitude-journal/user/detail/' +
                                                        item.id}},[_c('u',[_vm._v(" Detail Jurnal Syukur ")])])]),_c('td',{staticClass:"text-center"},[(
                                                        item.status ==
                                                        'done'
                                                    )?[_c('div',{staticClass:"tag green-solid w-100"},[_vm._v(" Finish ")])]:[_c('div',{staticClass:"tag red-solid w-100"},[_vm._v(" Unfinished ")])]],2),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.currencyFormat( item.penalty ))+" ")])])]})],2)])])]:[_vm._m(3)]],_c('div',{staticClass:"mt-4"},[(_vm.data.list_data.length > 0)?_c('b-pagination-nav',{attrs:{"link-gen":_vm.linkGen,"number-of-pages":_vm.data.total_page,"base-url":"#","first-number":"","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw-bold"},[_c('a',{staticClass:"text-gray-dashboard size-16",attrs:{"href":"/crm/gratitude-journal"}},[_c('i',{staticClass:"fa fa-angle-left me-2"}),_vm._v(" Back ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"btn btn-primary btn-lg px-5 me-2 m-mt-1 m-w-100",attrs:{"href":"/crm/gratitude-journal/create"}},[_vm._v(" Create Jurnal Syukur ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"table-light"},[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" No ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Date ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Jurnal Syukur ")]),_c('th',{staticClass:"text-center",staticStyle:{"width":"180px"},attrs:{"scope":"col"}},[_vm._v(" Status ")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Penalty ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-section"},[_c('img',{attrs:{"src":require("../../assets/no-data.png"),"width":"100","alt":""}}),_c('h4',{staticClass:"mb-0 fw-bold mt-4 mb-4 text-gray"},[_vm._v(" Data Kosong ")])])
}]

export { render, staticRenderFns }
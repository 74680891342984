var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 mt-4"},[_c('div',{staticClass:"box-dashboard w-100"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"fw-semibold mb-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._m(0)]),_vm._l((_vm.leaderboards),function(leaderboard,index){return _c('div',{key:index},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(leaderboard.rank == 1),expression:"leaderboard.rank == 1"}],staticClass:"leaderboard w-100 first position-relative mt-4",class:{
                    green: leaderboard.rank == 1,
                }},[_vm._m(1,true),_c('div',{staticClass:"mt-3"},[_c('center',[_c('div',{staticClass:"size-22 text-green fw-bold mb-2"},[_vm._v(" Penjualan Terbanyak! ")]),_c('div',{staticClass:"leaderboard-logo",style:({
                                'background-image':
                                    'url(' + leaderboard.avatar + ')',
                            })}),_c('div',{staticClass:"size-18 fw-reguler mt-2 capitalize"},[_vm._v(" "+_vm._s(leaderboard.name)+" ")]),_c('div',{staticClass:"size-24 fw-bold text-green"},[_vm._v(" "+_vm._s(_vm.currency(leaderboard.deal))+" ")])])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(leaderboard.rank > 1),expression:"leaderboard.rank > 1"}],staticClass:"box-dashboard custom mt-3 p-2"},[_c('div',{staticClass:"d-flex bd-highlight align-items-center"},[_c('div',{staticClass:"p-2 bd-highlight"},[_c('div',{staticClass:"text-gray-second fw-semibold"},[_vm._v(" "+_vm._s(leaderboard.rank)),(leaderboard.rank == 2)?[_vm._v("nd")]:(leaderboard.rank == 3)?[_vm._v("rd")]:[_vm._v("th")]],2)]),_c('div',{staticClass:"p-2 bd-highlight flex-grow-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"leaderboard-logo small me-2",style:({
                                    'background-image':
                                        'url(' + leaderboard.avatar + ')',
                                })}),_c('div',{staticClass:"size-18 capitalize"},[_vm._v(" "+_vm._s(leaderboard.name)+" ")])])]),_c('div',{staticClass:"p-2 bd-highlight"},[_c('div',{staticClass:"size-24 fw-bold text-green"},[_vm._v(" "+_vm._s(_vm.currency(leaderboard.deal))+" ")])])])])])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"text-green",attrs:{"href":"/crm/target"}},[_c('u',[_vm._v("Lihat Detail")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"score-position first"},[_c('img',{attrs:{"src":require("../../assets/first.png"),"alt":""}})])
}]

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import NewPassword from "../views/NewPassword.vue";
import ForgotOtp from "../views/Otp.vue";
import DashboardCRM from "../views/dashboard/DashboardCRM.vue";
import DashboardCRMStaff from "../views/dashboard/DashboardCRMStaff.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Profile from "../views/profile/Index";
import GratitudeJournal from "../views/crm/GratitudeJournal.vue";
import GratitudeJournalCreate from "../views/crm/GratitudeJournalCreate.vue";
import GratitudeJournalUser from "../views/crm/GratitudeJournalUser.vue";
import GratitudeJournalUserDetail from "../views/crm/GratitudeJournalUserDetail.vue";
import CrmOrder from "../views/crm/Order.vue";
import CrmDealOrder from "../views/crm/CrmDealOrder.vue";
import CrmOrderDetail from "../views/crm/CrmOrderDetail.vue";
import CrmOrderLost from "../views/crm/CrmOrderLost.vue";
import CrmOrderLostDetail from "../views/crm/CrmOrderLostDetail.vue";
import CrmTarget from "../views/crm/TargetHistory.vue";
import CrmOrderCreate from "../views/crm/OrderCreate.vue";
import CrmOrderEdit from "../views/crm/OrderEdit.vue";
import crmSpecialist from "../views/crm/Specialist.vue";
import crmAssignCro from "../views/crm/AssignCro.vue";
import crmAssignCroDetail from "../views/crm/ProspectDetail.vue";
import crmSpecialistDetail from "../views/crm/SpecialistDetail.vue";
import crmSpecialistForm from "../views/crm/SpecialistForm.vue";
import crmCustomers from "../views/crm/Customers.vue";
import crmCustomersOrdered from "../views/crm/CustomersOrdered.vue";
import crmCustomersRegistered from "../views/crm/CustomerRegistered.vue";
import crmCustomersLeads from "../views/crm/CustomersLeads.vue";
import crmCustomersLeadsDetail from "../views/crm/CustomersLeadsDetail.vue";
import crmCustomersLeadsTags from "../views/crm/CustomersLeadsTags.vue";
import crmCustomersForm from "../views/crm/CustomersForm.vue";
import crmCustomersFormEdit from "../views/crm/CustomersFormEdit.vue";
import crmCustomerLeadsProspect from "../views/crm/CustomerLeadsProspect.vue";
import crmCustomerOrderedDetails from "../views/crm/CustomerOrderedDetails.vue";
import crmCustomerOrderedHistory from "../views/crm/CustomerOrderedHistory.vue";
import crmCustomerOrderedProspect from "../views/crm/CustomerOrderedProspect.vue";
import crmCustomerRegisteredProspect from "../views/crm/CustomerRegisteredProspect.vue";
import crmCustomerRegisteredDetail from "../views/crm/CustomerRegisteredDetail.vue";
import crmProspect from "../views/crm/Prospect.vue";
import crmProspectAll from "../views/crm/ProspectAll.vue";
import crmFollowUpCro from "../views/crm/FollowUpCro.vue";
import crmFollowUpCroDetail from "../views/crm/ProspectDetail.vue";
import crmProspectToday from "../views/crm/ProspectToday.vue";
import crmProspectTomorrow from "../views/crm/ProspectTomorrow.vue";
import crmProspectNextTwoDays from "../views/crm/ProspectNextTwoDays.vue";
import crmProspectNextWeek from "../views/crm/ProspectNextWeek.vue";
import crmProspectNextMonth from "../views/crm/ProspectNextMonth.vue";
import crmProspectNeedFollowUp from "../views/crm/ProspectNeedFollowUp.vue";
import crmProspectDealt from "../views/crm/ProspectDealt.vue";
import crmProspectDetail from "../views/crm/ProspectDetail.vue";
import crmProspectLost from "../views/crm/ProspectLost.vue";
import crmProspectPrediction from "../views/crm/ProspectPrediction.vue";
import crmProfile from "../views/crm/CrmProfile.vue";
import crmProfileEdit from "../views/crm/CrmProfileEdit.vue";
import crmTags from "../views/crm/crmTags.vue";
import crmTagsCreate from "../views/crm/crmTagsCreate.vue";
import crmTagsDetail from "../views/crm/crmTagsDetail.vue";
import crmBranchs from "../views/crm/crmBranchs.vue";
import crmBranchsCreate from "../views/crm/crmBranchsCreate.vue";
import crmBranchsEdit from "../views/crm/crmBranchsEdit.vue";
import crmBranchsDetail from "../views/crm/crmBranchsDetail.vue";
import NotificationPage from "../views/Notification";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: Login,
    },
    {
        path: "/forgot/form",
        name: "forgot-password",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: ForgotPassword,
    },
    {
        path: "/forgot/new-password/:id",
        name: "new-password",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: NewPassword,
    },
    {
        path: "/forgot/otp",
        name: "forgot-otp",
        meta: {
            layout: "login",
            requiresAuth: false,
        },
        component: ForgotOtp,
    },
    {
        path: "/crm/dashboard/specialist",
        name: "dashboard-crm-staff",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardCRMStaff,
    },
    {
        path: "/crm/dashboard",
        name: "dashboard-crm",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: DashboardCRM,
    },
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Dashboard,
    },
    {
        path: "/profile",
        name: "admin-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: Profile,
    },
    {
        path: "/crm/specialist",
        name: "crm-specialist",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmSpecialist,
    },
    {
        path: "/crm/assign/cro",
        name: "crm-assign-cro",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmAssignCro,
    },
    {
        path: "/crm/assign/cro/detail/:id",
        name: "crm-assign-cro-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmAssignCroDetail,
    },
    {
        path: "/crm/specialist/detail/:id",
        name: "crm-specialist-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmSpecialistDetail,
    },
    {
        path: "/crm/specialist/form",
        name: "crm-specialist-form",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmSpecialistForm,
    },
    {
        path: "/crm/customers",
        name: "crm-customers",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomers,
    },
    {
        path: "/crm/customers/ordered",
        name: "crm-customers-ordered",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersOrdered,
    },
    {
        path: "/crm/customers/leads",
        name: "crm-customers-leads",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeads,
    },
    {
        path: "/crm/customers/leads/detail/:id",
        name: "crm-customers-leads-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeadsDetail,
    },
    {
        path: "/crm/customers/leads/tags",
        name: "crm-customers-leads-tags",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersLeadsTags,
    },
    {
        path: "/crm/customers/leads/form",
        name: "crm-customers-form",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersForm,
    },
    {
        path: "/crm/customers/leads/form/:id",
        name: "crm-customers-form-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersFormEdit,
    },
    {
        path: "/crm/customers/leads/prospect/:id",
        name: "crm-customers-leads-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerLeadsProspect,
    },
    {
        path: "/crm/customer/order/detail/:id",
        name: "crm-customers-order-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerOrderedDetails,
    },
    {
        path: "/crm/customer/order/history/:id",
        name: "crm-customers-order-history",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerOrderedHistory,
    },
    {
        path: "/crm/customers/order/prospect/:id",
        name: "crm-customers-order-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerOrderedProspect,
    },
    {
        path: "/crm/prospect",
        name: "crm-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspect,
    },
    {
        path: "/crm/prospect/all",
        name: "crm-prospect-all",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectAll,
    },
    {
        path: "/crm/follow-up-cro",
        name: "crm-follow-up-cro",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmFollowUpCro,
    },
    {
        path: "/crm/follow-up-cro/detail/:id",
        name: "crm-follow-up-cro-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmFollowUpCroDetail,
    },
    {
        path: "/crm/prospect/today",
        name: "crm-prospect-today",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectToday,
    },
    {
        path: "/crm/prospect/tomorrow",
        name: "crm-prospect-tomorrow",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectTomorrow,
    },
    {
        path: "/crm/prospect/next-two-days",
        name: "crm-prospect-next-two-days",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextTwoDays,
    },
    {
        path: "/crm/prospect/next-week",
        name: "crm-prospect-next-week",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextWeek,
    },
    {
        path: "/crm/prospect/next-month",
        name: "crm-prospect-next-month",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNextMonth,
    },
    {
        path: "/crm/prospect/need-follow-up",
        name: "crm-prospect-need-follow-up",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectNeedFollowUp,
    },
    {
        path: "/crm/prospect/detail/:id",
        name: "crm-prospect-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDetail,
    },
    {
        path: "/crm/prospect/prediction/:id",
        name: "crm-prospect-prediction",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectPrediction,
    },
    {
        path: "/crm/prospect/lost/:id",
        name: "crm-prospect-lost",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectLost,
    },
    {
        path: "/crm/prospect/deal/:id",
        name: "crm-prospect-deal",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProspectDealt,
    },
    {
        path: "/crm/gratitude-journal",
        name: "gratitude-journal",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournal,
    },
    {
        path: "/crm/gratitude-journal/create",
        name: "gratitude-journal-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournalCreate,
    },
    {
        path: "/crm/gratitude-journal/user/:id",
        name: "gratitude-journal-user",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournalUser,
    },
    {
        path: "/crm/gratitude-journal/user/detail/:id",
        name: "gratitude-journal-user-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: GratitudeJournalUserDetail,
    },
    {
        path: "/crm/order",
        name: "crm-order",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrder,
    },
    {
        path: "/crm/deal-order/create",
        name: "crm-order-form",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmDealOrder,
    },
    {
        path: "/crm/order/detail",
        name: "crm-order-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderDetail,
    },
    {
        path: "/crm/order-lost",
        name: "crm-order-lost",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderLost,
    },
    {
        path: "/crm/order-lost/detail/:id",
        name: "crm-order-lost-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderLostDetail,
    },
    {
        path: "/crm/target",
        name: "crm-target",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmTarget,
    },
    {
        path: "/crm/target/form",
        name: "crm-order-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderCreate,
    },
    {
        path: "/crm/target/edit/:id",
        name: "crm-order-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: CrmOrderEdit,
    },
    {
        path: "/crm/profile",
        name: "crm-profile",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProfile,
    },
    {
        path: "/crm/profile/edit",
        name: "crm-profile-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmProfileEdit,
    },
    {
        path: "/crm/tags",
        name: "crm-tags",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmTags,
    },
    {
        path: "/crm/tags/create",
        name: "crm-tags-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmTagsCreate,
    },
    {
        path: "/crm/tags/detail/:id",
        name: "crm-tags-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmTagsDetail,
    },
    {
        path: "/crm/customers/registered",
        name: "crm-customers-registered",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomersRegistered,
    },
    {
        path: "/crm/customers/registered/prospect/:id",
        name: "crm-customers-registered-prospect",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerRegisteredProspect,
    },
    {
        path: "/crm/customers/registered/detail/:id",
        name: "crm-customers-registered-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmCustomerRegisteredDetail,
    },
    {
        path: "/crm/branchs",
        name: "crm-branchs",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchs,
    },
    {
        path: "/crm/branchs/create",
        name: "crm-branchs-create",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchsCreate,
    },
    {
        path: "/crm/branchs/edit/:id",
        name: "crm-branchs-edit",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchsEdit,
    },
    {
        path: "/crm/branchs/detail/:id",
        name: "crm-branchs-detail",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: crmBranchsDetail,
    },
    {
        path: "/notification",
        name: "notification",
        meta: {
            layout: "admin",
            requiresAuth: false,
        },
        component: NotificationPage,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;

<template>
    <div class="col-md-4 mt-4 d-flex align-items-stretch">
        <div
            class="box-dashboard w-100"
            v-bind:class="{
                'box-active': first_dashboard?.company != null,
            }"
        >
            <!-- Default Data -->
            <div v-if="first_dashboard?.company == null">
                <h5 class="fw-semibold mb-0">Target Perusahaan</h5>
                <div class="mt-3">
                    <span class="fw-bold size-24">
                        {{ currency(default_dashboard?.company?.real_deal) }}
                    </span>
                    <small class="size-14 text-gray-second">
                        /
                        {{ currency(default_dashboard?.company?.target_deal) }}
                    </small>
                </div>
                <div>
                    <apexchart
                        type="radialBar"
                        width="100%"
                        height="350"
                        :options="companyTarget"
                        :series="companyTargets"
                    ></apexchart>
                </div>
            </div>
            <!-- End Default Data -->

            <!-- Compare Data -->
            <div v-if="first_dashboard?.company != null">
                <h5 class="fw-semibold mb-0">Target Perusahaan</h5>
                <div class="mt-3">
                    <div class="text-gray-second">
                        {{ compare_data_first }}
                    </div>
                    <span class="fw-bold size-24">
                        {{ currency(first_dashboard?.company?.real_deal) }}
                    </span>
                    <small class="size-14 text-gray-second">
                        /
                        {{ currency(first_dashboard?.company?.target_deal) }}
                    </small>
                </div>
                <div>
                    <apexchart
                        type="radialBar"
                        width="100%"
                        height="300"
                        :options="companyTarget"
                        :series="companyTargetsFirst"
                    ></apexchart>
                </div>
                <hr />
                <div class="mt-3">
                    <div class="text-gray-second">
                        {{ compare_data_second }}
                    </div>
                    <span class="fw-bold size-24">
                        {{ currency(second_dashboard?.company?.real_deal) }}
                    </span>
                    <small class="size-14 text-gray-second">
                        /
                        {{ currency(second_dashboard?.company?.target_deal) }}
                    </small>
                </div>
                <div>
                    <apexchart
                        type="radialBar"
                        width="100%"
                        height="300"
                        :options="companyTarget"
                        :series="companyTargetsSecond"
                    ></apexchart>
                </div>
            </div>
            <!-- End Compare Data -->
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    name: "CompanyTarget",
    props: [
        "default_dashboard",
        "first_dashboard",
        "second_dashboard",
        "compare_data_first",
        "compare_data_second",
        "companyTarget",
        "companyTargets",
        "companyTargetsFirst",
        "companyTargetsSecond",
    ],
    data() {
        return {
            moment: moment,
        };
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
@keyframes color-mix {
    0% {
        box-shadow: #c1ebd3 0px 1px 3px 0px;
    }
    100% {
        box-shadow: #37a968 0px 1px 3px 0px;
    }
}
.box-active {
    animation: color-mix 1s linear infinite alternate;
    box-shadow: 0 1px 12px -1px currentColor;
}
</style>

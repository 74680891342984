<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a href="/crm/target" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box p-0 mt-4">
            <div class="box no-shadow bc-gray p-4 no-radius-bottom">
                <h5 class="mb-0 fw-bold">Add New Target</h5>
            </div>
            <div class="p-4">
                <div class="row">
                    <div
                        class="col-md-12 mb-2"
                        v-show="!isRole(crmManagerRole)"
                    >
                        <label class="fw-bold mb-2">Branch Name</label>
                        <multiselect
                            v-model="branch"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Branch"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6">
                        <label class="fw-bold mb-o">Customers Target</label>
                        <input
                            type="text"
                            class="form-control mt-2 number"
                            placeholder="Customers Target"
                            v-model="req.customer"
                        />
                    </div>
                    <div class="col-md-6 m-mt-1">
                        <label class="fw-bold mb-o">Nominal Target</label>
                        <money
                            v-model="req.amount"
                            v-bind="money"
                            class="form-control mt-2"
                        >
                        </money>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label class="fw-bold mb-o">Division Target</label>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="text-gray-dashboard">Sales</label>
                                <div class="position-relative">
                                    <money
                                        v-model="req.target_sales"
                                        v-bind="target_sales"
                                        class="form-control mt-2"
                                    >
                                    </money>
                                    <span
                                        class="persen-position cursor-pointer"
                                    >
                                        %
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="text-gray-dashboard m-mt-1"
                                    >Telemarketer</label
                                >
                                <div class="position-relative">
                                    <money
                                        v-model="req.target_telemarketer"
                                        v-bind="target_telemarketer"
                                        class="form-control mt-2"
                                    >
                                    </money>
                                    <span
                                        class="persen-position cursor-pointer"
                                    >
                                        %
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 position-relative">
                                <label class="text-gray-dashboard m-mt-1"
                                    >Customer Service</label
                                >
                                <div class="position-relative">
                                    <money
                                        v-model="req.target_cs"
                                        v-bind="target_cs"
                                        class="form-control mt-2"
                                    >
                                    </money>
                                    <span
                                        class="persen-position cursor-pointer"
                                    >
                                        %
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold mb-o">Month</label>
                        <div class="mt-2">
                            <date-picker
                                v-model="req.month"
                                type="month"
                                value-type="format"
                                format="MMMM"
                                :clearable="false"
                                placeholder="Month"
                                class="w-100"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold mb-o">Years</label>
                        <div class="mt-2">
                            <date-picker
                                v-model="req.year"
                                type="year"
                                value-type="format"
                                format="YYYY"
                                :clearable="false"
                                placeholder="Year"
                                class="w-100"
                            >
                            </date-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="/crm/target"
                            class="btn btn-outline-black btn-lg w-100"
                            >Cancel</a
                        >
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            type="button"
                            disabled
                            v-if="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Save
                        </button>
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="storeDate()"
                            v-else
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a href="/crm/target" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import $ from "jquery";
import { Money } from "v-money";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        DatePicker,
        Money,
        Multiselect,
    },
    name: "CrmOrderCreate",
    data() {
        return {
            moment: moment,
            modalSuccess: false,
            req: {
                month: "",
                year: "",
                customer: null,
                amount: 0,
                target_sales: 0,
                target_telemarketer: 0,
                target_cs: 0,
            },
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
            },
            target_sales: {
                decimal: ",",
                thousands: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            target_telemarketer: {
                decimal: ",",
                thousands: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            target_cs: {
                decimal: ",",
                thousands: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            is_save: false,
            branch: null,
            branchs: [],
            crmManagerRole: ["crm-manager"],
            roles: [],
        };
    },
    created() {
        this.getBranchs();
        this.getUserLogin();
    },
    mounted() {
        $(".number").keypress(function (e) {
            if (
                e.which != 8 &&
                e.which != 0 &&
                (e.which < 48 || e.which > 57)
            ) {
                $("#errmsg").html("Number Only").stop().show().fadeOut("slow");
                return false;
            }
        });
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        notBeforeToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        storeDate() {
            if (this.req.customer == null) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Customers Target Is Required",
                });
                return;
            } else {
                if (this.req.amount != 0) {
                    if (
                        parseInt(this.req.target_sales) +
                            parseInt(this.req.target_telemarketer) +
                            parseInt(this.req.target_cs) !=
                        100
                    ) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: "Division Target Must Be 100",
                        });
                        return;
                    } else {
                        this.is_save = true;
                        var data = {
                            customer: this.req.customer,
                            amount: this.req.amount,
                            branch_id: this.branch?.id,
                            date:
                                this.moment()
                                    .month(this.req.month)
                                    .format("MM") +
                                "-" +
                                this.req.year,
                            target_sales: this.req.target_sales,
                            target_telemarketer: this.req.target_telemarketer,
                            target_cs: this.req.target_cs,
                        };
                        Api.post(
                            `${process.env.VUE_APP_SERVICE_URL}/crm/target/post`,
                            data,
                            {
                                headers: {
                                    Accept: "application/json",
                                    Authorization:
                                        "Bearer" +
                                        localStorage.getItem("token"),
                                },
                            }
                        )
                            .then((res) => {
                                var data = res.data.data;
                                this.data = data;
                                this.is_save = false;
                                this.modalSuccess = true;
                            })
                            .catch((err) => {
                                this.is_save = false;
                                this.$notify({
                                    group: "foo",
                                    type: "error",
                                    title: "Error",
                                    text: err.response.data.message,
                                });
                            });
                    }
                } else {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Nominal Target Can't Be Rp0",
                    });
                    return;
                }
            }
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.bg-blue-thin {
    background: #daecf6;
}
.p-head-title {
    padding: 8px 18px 8px 18px !important;
}
.persen-position {
    position: absolute;
    top: 5px;
    right: 12px;
}
</style>

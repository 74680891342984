<template>
    <div>
        <router-view :token="$attrs.token" />
    </div>
</template>

<script>
    export default {
        props: '',
        data() {
            return{
            }
        },
        created() {
            console.log(this.$attrs.token)
        }
    }
</script>
<template>
    <section class="bc-login">
        <notifications group="foo" position="top right" />
        <div class="container text-center mt-login">
            <img src="../assets/logo-with-name.webp" width="350" alt="" />
            <!-- <h2 class="text-center fw-bold mt-3">Log In</h2> -->
            <div class="row justify-content-center mt-5">
                <div class="col-md-5">
                    <form @submit.prevent="loginAction()">
                        <input
                            type="text"
                            class="form-control form-login"
                            id="username"
                            placeholder="Username"
                            v-model="username"
                            autofocus
                        />
                        <div class="mt-2">
                            <div class="position-relative">
                                <input
                                    type="text"
                                    class="form-control form-login"
                                    id="password"
                                    placeholder="Password"
                                    v-model="password"
                                    @keyup.enter="loginAction()"
                                    v-if="showPassword"
                                />
                                <input
                                    type="password"
                                    class="form-control form-login"
                                    id="password"
                                    placeholder="Password"
                                    v-model="password"
                                    @keyup.enter="loginAction()"
                                    v-else
                                />
                                <span
                                    class="search-icon cursor-pointer"
                                    @click="toggleShow"
                                >
                                    <i
                                        class="fa"
                                        :class="{
                                            'fa-eye': showPassword,
                                            'fa-eye-slash': !showPassword,
                                        }"
                                    ></i>
                                </span>
                            </div>
                        </div>
                    </form>
                    <div class="pull-right-login mt-3 mb-3">
                        <u>
                            <a href="/forgot/form" class="text-dark size-16">
                                Lupa Password
                            </a>
                        </u>
                    </div>
                    <div class="mt-4">
                        <button
                            class="btn btn-primary btn-lg w-100"
                            type="button"
                            disabled
                            v-if="is_login"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Log In
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary btn-lg w-100"
                            :disabled="username == '' || password == ''"
                            @click="loginAction()"
                            v-else
                        >
                            Log In
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Api from "../api/Api";

export default {
    props: "",
    name: "Login",
    data() {
        return {
            guard: "user",
            username: "",
            password: "",
            device_name: "CRM-Klik",
            showPassword: false,
            is_login: false,
            roles_go: [],
            jwtToken: null,
            parsedJwt: null,
        };
    },
    computed: {
        buttonLabel() {
            return this.showPassword ? "Hide" : "Show";
        },
    },
    created() {
        let jwtToken = localStorage.getItem("token");
        if (jwtToken != null && jwtToken != "") {
            this.jwtToken = jwtToken;
            this.parseSavedJwt();
        }
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        parseSavedJwt() {
            let token = this.jwtToken;
            const [header, payload, signature] = token?.split(".");

            try {
                const decodedHeader = atob(header);
                const decodedPayload = atob(payload);

                const parsedHeader = JSON.parse(decodedHeader);
                const parsedPayload = JSON.parse(decodedPayload);

                const expirationTime = parsedPayload.exp;

                this.parsedJwt = {
                    header: parsedHeader,
                    payload: parsedPayload,
                    signature: signature,
                    expirationTime: expirationTime,
                };

                var currentTimestamp = Math.floor(Date.now() / 1000);
                if (this.parsedJwt.payload.exp > currentTimestamp) {
                    // jwt still usable
                    this.getUserLogin();
                } else {
                    // ignore no action
                    this.parsedJwt = null;
                    this.jwtToken = null;
                }
            } catch (error) {
                console.error("Error parsing JWT:", error);
                this.parsedJwt = null;
                this.jwtToken = null;
            }
        },
        loginAction() {
            if (this.username == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Username is required",
                });
            } else if (this.password == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Password is required",
                });
            } else {
                var data = {
                    guard: this.guard,
                    email: this.username,
                    password: this.password,
                    device_name: "CRM-Klik",
                };
                this.is_login = true;
                Api.post(
                    `${process.env.VUE_APP_SERVICE_URL}/auth/login-dev`,
                    data
                )
                    .then((res) => {
                        console.log(res);

                        this.is_login = false;
                        this.jwtToken = res.data.data.token;
                        this.getUserLogin();
                    })
                    .catch((err) => {
                        this.is_login = false;
                        let msg = err.message;

                        if (err.response) {
                            // Request made and server responded
                            msg = err.response.data.message;
                            console.log(err.response.data);
                            console.log(err.response.status);
                            console.log(err.response.headers);
                        } else if (err.request) {
                            // The request was made but no response was received
                            console.log(err.request);
                            msg = "no response was received";
                        } else {
                            // Something happened in setting up the request that triggered an err
                            console.log("err", err.message);
                            msg = "err: " + err.message;
                        }

                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: msg,
                        });
                    });
            }
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + this.jwtToken,
                },
            })
                .then((res) => {
                        this.$notify({
                            group: "foo",
                            type: "success",
                            title: "Success",
                            text: "Login success",
                        });
                        this.roles_go = res.data.data.roles;
                        localStorage.setItem("token", this.jwtToken);
                        localStorage.setItem("roles", this.roles_go);

                        setTimeout(() => {
                            this.checkRedirectLogin();
                        }, 500);
                })
                .catch((err) => {
                    console.log('fail-get-profile', err);

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Fail get Profile",
                    });
                });
        },
        checkRedirectLogin() {
            if (this.roles_go.length == 0) return null;

            if (this.roles_go.includes("crm-manager")) {
                this.$router.push("/crm/dashboard");
                return;
            }
            if (this.roles_go.includes("crm-specialist")) {
                this.$router.push("/crm/dashboard/specialist");
                return;
            }
            if (
                this.roles_go.includes("admin-super") ||
                this.roles_go.includes("admin-crm")
            ) {
                this.$router.push("/dashboard");
                return;
            }
        },
    },
};
</script>

<style scoped>
.mt-login {
    margin-top: 160px;
}
.form-login {
    padding: 0.6rem 0.9rem;
    font-size: 15px;
    border-radius: 6px;
    border: 2px solid #f7f7f7;
}
.search-icon {
    top: 8px;
    font-size: 17px;
    right: 16px;
}
.pull-right-login {
    float: right;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import helpers from "./helpers";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import * as moment from "moment/moment";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.esm.min.js";
import firebase from "firebase/app";
import "firebase/messaging";
import "./assets/scss/app.scss";

import BlankLayout from "./layouts/BlankLayout.vue";
import AdminLayout from "./layouts/AdminLayout.vue";
import LoginLayout from "./layouts/LoginLayout.vue";
import ChatLayout from "./layouts/ChatLayout.vue";
import Notifications from "vue-notification";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_KEY_GMAPS,
        libraries: "places",
    },
});

firebase.initializeApp({
    apiKey: "AIzaSyBcQAHU7NDH90vHM7RycPu8t2PNsMHrpUc",
    authDomain: "trawlbens-fb.firebaseapp.com",
    projectId: "trawlbens-fb",
    storageBucket: "trawlbens-fb.appspot.com",
    messagingSenderId: "1019509689561",
    appId: "1:1019509689561:web:282dc33243d6f1c61a02c8",
    measurementId: "G-Z8Y4ZP0791",
});

// navigator.serviceWorker
//     .register("firebase-messaging-sw.js")
//     .then((registration) => {
//         const messaging = firebase.messaging();
//         messaging.useServiceWorker(registration);
//     })
//     .catch((err) => {
//         console.log(err);
//     });

Vue.component("blank-layout", BlankLayout);
Vue.component("admin-layout", AdminLayout);
Vue.component("login-layout", LoginLayout);
Vue.component("chat-layout", ChatLayout);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);

Vue.config.productionTip = false;

Vue.mixin({
    methods: {
        // date(date, format){
        //     return moment(date).format(format)
        // },
    },
});

Object.keys(helpers).forEach((key) => {
    Vue.prototype[key] = helpers[key];
});

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");

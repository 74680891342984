<template>
    <section class="bc-login">
        <notifications group="foo" position="top right" />
        <div class="container mt-login">
            <center>
                <img src="../assets/logo.png" width="60" alt="">
                <h2 class="fw-bold mt-3">Atur Ulang Kata Sandi</h2>
            </center>
            <div class="row justify-content-center mt-5">
                <div class="col-md-5">
                    <form @submit.prevent="sendPassword()">
                        <div class="position-relative">
                            <input type="text" class="form-control form-login" id="password" placeholder="Kata Sandi Baru" autofocus v-model="req.password" @keyup.enter="sendPassword()" v-if="showPassword" />
                            <input type="password" class="form-control form-login" id="password" placeholder="Kata Sandi Baru" autofocus v-model="req.password" @keyup.enter="sendPassword()" v-else />
                            <span class="search-icon cursor-pointer" @click="togglePassword">
                                <i class="fa" :class="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                            </span>
                        </div>
                        <div class="position-relative mt-2">
                            <input type="text" class="form-control form-login" id="confirm_password" placeholder="Konfirmasi Kata Sandi" v-model="req.confirm_password" @keyup.enter="sendPassword()" v-if="showConfirmPassword" />
                            <input type="password" class="form-control form-login" id="confirm_password" placeholder="Konfirmasi Kata Sandi" v-model="req.confirm_password" @keyup.enter="sendPassword()" v-else />
                            <span class="search-icon cursor-pointer" @click="toggleConfirmPassword">
                                <i class="fa" :class="{ 'fa-eye': showConfirmPassword, 'fa-eye-slash': !showConfirmPassword }"></i>
                            </span>
                        </div>
                        <template v-if="req.password != '' || req.confirm_password != ''">
                            <div class="mt-2 text-danger" v-if="req.password != req.confirm_password">
                                <i class="fa fa-close me-1"></i>
                                Kata Sandi Tidak Sesuai
                            </div>
                            <div class="mt-2 text-green" v-else>
                                <i class="fa fa-check me-1"></i>
                                Kata Sandi Sesuai
                            </div>
                        </template>
                        <template v-if="req.password != ''">
                            <div class="mt-2 text-danger" v-if="req.password.length <= 6">
                                <i class="fa fa-close me-1"></i>
                                Kata Sandi Baru Minimal 6 Karakter
                            </div>
                        </template>
                        <template v-if="req.confirm_password != ''">
                            <div class="mt-2 text-danger" v-if="req.confirm_password.length <= 6">
                                <i class="fa fa-close me-1"></i>
                                Konfirmasi Kata Sandi Minimal 6 Karakter
                            </div>
                        </template>
                    </form>
                    <div class="mt-4">
                        <button class="btn btn-primary btn-lg w-100" type="button" disabled v-if="is_save">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Simpan
                        </button>
                        <button type="submit" class="btn btn-primary btn-lg w-100" :disabled="req.password == '' || req.confirm_password == '' || req.password != req.confirm_password || req.password.length <= 6 || req.confirm_password.length <= 6" @click="sendPassword()" v-else>Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Api from "../api/Api";

export default {
    props: "",
    name: "NewPassword",
    data() {
        return {
            is_save: false,
            req: {
                password: '',
                confirm_password: '',
            },
            showPassword: false,
            showConfirmPassword: false,
        };
    },
    computed: {
        buttonLabel() {
            return (
                this.showPassword ? "Hide" : "Show",
                this.showConfirmPassword ? "Hide" : "Show"
            )
        },
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirmPassword() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        sendPassword(){
            this.is_save = true
            var data = {
                otp_id: this.$route.params.id,
                new_password: this.req.password,
                new_password_confirmation: this.req.confirm_password,
            };
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/user/forget/email/submit`, data)
            .then((res) => {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Perubahan Password Berhasil'
                });
                this.is_save = false
                setTimeout(() => {
                    window.location.href = '/'
                }, 2000);
                console.log(res)
            })
            .catch((err) => {
                this.is_save = false;
                if(err.response.data.data == null){
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                }else{
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.data.message
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
    .mt-login {
        margin-top: 160px;
    }
    .form-login{
        padding: 0.6rem 0.9rem;
        font-size: 15px;
        border-radius: 6px;
        border: 2px solid #f7f7f7;
    }
    .search-icon{
        top: 8px;
        font-size: 17px;
        right: 16px;
    }
</style>

<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="mb-3">
                <h3 class="fw-bold capitalize">Daftar Lost Order</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-3 m-mx-0">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari Nama Customer"
                            v-model="keySearch"
                            @input="changePage()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="me-auto m-mt-1">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <multiselect
                            v-model="service"
                            :options="services"
                            label="name"
                            track-by="id"
                            placeholder="Select Service"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mb-2">
                        <multiselect
                            v-model="category_filter"
                            :options="categories"
                            label="label"
                            track-by="type"
                            placeholder="Select Category"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="range == ''"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="
                                service == null && category_filter == null
                            "
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_data">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Tanggal Lost Order
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Telepon Customer
                                </th>
                                <th class="text-center" scope="col">Layanan</th>
                                <th class="text-center" scope="col">
                                    Kategori
                                </th>
                                <th class="text-center" scope="col">Owner</th>
                                <th class="text-center" scope="col">
                                    Keterangan
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data" :key="index">
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{ item.lost_order_date }}
                                </td>
                                <td class="">
                                    <router-link
                                        class="text-link"
                                        :to="{
                                            name: 'crm-order-lost-detail',
                                            params: {
                                                id: item.prospect_id,
                                            },
                                        }"
                                    >
                                        {{ item.name }}
                                    </router-link>
                                </td>
                                <td class="capitalize">
                                    {{ item.phone_number }}
                                </td>
                                <td class="capitalize text-center">
                                    {{ item.service }}
                                </td>
                                <td class="capitalize text-center">
                                    <div
                                        class="tag radius w-100 size-12"
                                        v-bind:class="{
                                            'green-cro':
                                                item.category == 'New Order',
                                            'blue-cro':
                                                item.category == 'Repeat Order',
                                            'yellow-cro':
                                                item.category == 'New Customer',
                                        }"
                                    >
                                        {{ item.category }}
                                    </div>
                                </td>
                                <td>{{ item.owner }}</td>
                                <td class="text-center">
                                    {{ item.information }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="data.length == 0" class="text-center mt-section">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="data.length > 0"
                    v-model="currentPage"
                    :number-of-pages="totalPage"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    props: {
        items: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            moment: moment,
            data: [],
            keySearch: "",
            is_load_data: false,
            currentPage: 0,
            totalPage: 0,
            limit: 10,
            isFilter: false,
            service: null,
            services: [
                {
                    name: "Kirim Barang",
                    id: "kirim-barang",
                },
                {
                    name: "Kirim Motor",
                    id: "kirim-motor",
                },
                {
                    name: "Via Udara",
                    id: "via-udara",
                },
                {
                    name: "Sewa Truk",
                    id: "sewa-truck",
                },
            ],
            category_filter: null,
            categories: [
                {
                    type: "new_order",
                    label: "New Order",
                },
                {
                    type: "new_customer",
                    label: "New Customer",
                },
                {
                    type: "repeat_order",
                    label: "Repeat Order",
                },
            ],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        changePage() {
            this.getData();
        },
        getData() {
            this.is_load_data = true;
            var params = {
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
                limit: this.limit,
                start_date: "",
                end_date: "",
                search: this.keySearch,
                category: this.category_filter?.type,
                service: this.service?.id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/lost-order/list`,
                {
                    params: params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res, "dres");
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        clearFilter() {
            this.service = null;
            this.category_filter = null;
            this.getData();
        },
        filter() {
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>

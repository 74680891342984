<script>
//   import { ContentLoader } from "vue-content-loader"

  export default {
    // components: { ContentLoader }
  }
</script>

<template>
    <svg viewBox="0 0 400 95" version="1.1" preserveAspectRatio="xMidYMid meet">
        <rect clip-path="url(#zu0d94f091)" x="0" y="0" width="400" height="130" style="fill: url(&quot;#nb2hni6koc&quot;);"></rect>
        <defs>
        <clipPath id="zu0d94f091">
            <rect x="0" y="0" rx="2" ry="2" width="100%" height="20"></rect>
            <rect x="0" y="24" rx="2" ry="2" width="100%" height="20">
            </rect><rect x="0" y="48" rx="2" ry="2" width="100%" height="20"></rect>
            <rect x="0" y="72" rx="2" ry="2" width="100%" height="20"></rect>
        </clipPath>
        <linearGradient id="nb2hni6koc">
            <stop offset="0%" stop-color="#f8f9fa" stop-opacity="1">
                <animate attributeName="offset" values="-2; 1" dur="3s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="50%" stop-color="#e2e3e5" stop-opacity="1">
                <animate attributeName="offset" values="-1.5; 1.5" dur="3s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="100%" stop-color="#f8f9fa" stop-opacity="1">
                <animate attributeName="offset" values="-1; 2" dur="3s" repeatCount="indefinite"></animate>
            </stop>
        </linearGradient></defs>
    </svg>
    <!-- <content-loader
        :speed="3"
        primaryColor="#f8f9fa"
        secondaryColor="#e2e3e5"
    >
        <rect x="0" y="0" rx="2" ry="2" width="100%" height="20" />
        <rect x="0" y="24" rx="2" ry="2" width="100%" height="20" />
        <rect x="0" y="48" rx="2" ry="2" width="100%" height="20" />
        <rect x="0" y="72" rx="2" ry="2" width="100%" height="20" />
    </content-loader> -->
</template>
<template>
    <div class="col-md-8 mt-4">
        <div class="box-dashboard w-100">
            <div class="d-flex align-items-center">
                <h5 class="fw-semibold mb-0">
                    {{ title }}
                </h5>
                <div class="ms-auto">
                    <a href="/crm/target" class="text-green">
                        <u>Lihat Detail</u>
                    </a>
                </div>
            </div>
            <div v-for="(leaderboard, index) in leaderboards" :key="index">
                <div
                    class="leaderboard w-100 first position-relative mt-4"
                    v-bind:class="{
                        green: leaderboard.rank == 1,
                    }"
                    v-show="leaderboard.rank == 1"
                >
                    <div class="score-position first">
                        <img src="../../assets/first.png" alt="" />
                    </div>
                    <div class="mt-3">
                        <center>
                            <div class="size-22 text-green fw-bold mb-2">
                                Penjualan Terbanyak!
                            </div>
                            <div
                                class="leaderboard-logo"
                                :style="{
                                    'background-image':
                                        'url(' + leaderboard.avatar + ')',
                                }"
                            ></div>
                            <div class="size-18 fw-reguler mt-2 capitalize">
                                {{ leaderboard.name }}
                            </div>
                            <div class="size-24 fw-bold text-green">
                                {{ currency(leaderboard.deal) }}
                            </div>
                        </center>
                    </div>
                </div>
                <div
                    class="box-dashboard custom mt-3 p-2"
                    v-show="leaderboard.rank > 1"
                >
                    <div class="d-flex bd-highlight align-items-center">
                        <div class="p-2 bd-highlight">
                            <div class="text-gray-second fw-semibold">
                                {{ leaderboard.rank
                                }}<template v-if="leaderboard.rank == 2"
                                    >nd</template
                                >
                                <template v-else-if="leaderboard.rank == 3"
                                    >rd</template
                                >
                                <template v-else>th</template>
                            </div>
                        </div>
                        <div class="p-2 bd-highlight flex-grow-1">
                            <div class="d-flex align-items-center">
                                <div
                                    class="leaderboard-logo small me-2"
                                    :style="{
                                        'background-image':
                                            'url(' + leaderboard.avatar + ')',
                                    }"
                                ></div>
                                <div class="size-18 capitalize">
                                    {{ leaderboard.name }}
                                </div>
                            </div>
                        </div>
                        <div class="p-2 bd-highlight">
                            <div class="size-24 fw-bold text-green">
                                {{ currency(leaderboard.deal) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {},
    name: "Leaderboard",
    props: ["leaderboards", "title"],
    data() {
        return {
            moment: moment,
        };
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.leaderboard {
    padding: 20px 25px;
    border-radius: 1rem;
    box-shadow: 0 1px 5px -2px rgba(141, 145, 162, 0.25);
    background-color: #f5faff;
}
.leaderboard.green {
    background-color: #ebf6ff;
    box-shadow: rgb(41 127 78 / 23%) 0px 4px 5px -1px;
}
.score-position {
    position: absolute;
    right: -10px;
    top: -10px;
}
.score-position.first {
    position: absolute;
    right: -14px;
    top: -14px;
}
.score-position img {
    width: 40px;
}
.score-position.first img {
    width: 50px;
}
.mt-leaderboard {
    margin-top: 80px;
}
.leaderboard-logo {
    width: 80px;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    // border: 2px solid #f7f7f7;
}
.leaderboard-logo.small {
    width: 60px !important;
    height: 60px !important;
}
</style>

<template>
    <div class="padding-container">
        <div class="d-flex align-items-center">
            <h1>
                <span class="fw-bold size-32"
                    >{{ default_dashboard.user_name }},
                </span>
                <small class="size-14 text-gray-second">selamat datang!</small>
            </h1>
            <div class="ms-auto">
                <div class="ms-3 mt-3 dropdown">
                    <button
                        class="btn btn-outline-green-second px-4 btn-lg"
                        @click="isCompare = !isCompare"
                    >
                        <img
                            src="../../assets/compare.png"
                            width="18"
                            class="me-2"
                            alt=""
                        />
                        Compare Data
                    </button>
                    <div
                        class="dropdown-menu compare-data dropdown-menu-end mt-3 show"
                        v-if="isCompare"
                    >
                        <div class="row">
                            <div class="col-md-12 border-right">
                                <div>
                                    <p class="text-center text-gray-second">
                                        Pembanding Pertama
                                    </p>
                                    <date-range-picker
                                        ref="picker1"
                                        opens="left"
                                        :locale-data="{
                                            direction: 'ltr',
                                            format: 'mm/dd/yyyy',
                                            separator: ' - ',
                                            applyLabel: 'Apply',
                                            cancelLabel: 'Reset',
                                            weekLabel: 'W',
                                            customRangeLabel: 'Custom Range',
                                            daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                            firstDay: 1
                                        }"
                                        :show-dropdowns=false
                                        :auto-apply=false
                                        :linked-calendars=true
                                        v-model="dateRangeFirst"
                                        @update="updateValuesFirst"
                                        @cancel="resetValueFirst"
                                    >
                                        <template v-slot:input>
                                            <span v-if="date_first_start == '' || date_first_end == ''">Belum memilih Pembanding</span>
                                            <span v-else>{{ date_first_start }} - {{ date_first_end }}</span>
                                        </template>
                                        <div slot="footer" slot-scope="data" class="slot drp-buttons">
                                            <p>Tanggal terpilih : <b class="text-black">{{ data.rangeText }}</b></p>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="data.clickCancel"
                                                >Tutup
                                                </button>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="resetValueFirst(data)"
                                                >Reset
                                                </button>
                                            <button
                                                class="applyBtn btn btn-sm btn-success"
                                                :disabled="data.in_selection"
                                                type="button"
                                                @click="data.clickApply"
                                                >Apply
                                            </button>
                                        </div>
                                    </date-range-picker>

                                    <p class="text-center text-gray-second">
                                        Pembanding Kedua
                                    </p>
                                    <date-range-picker
                                        ref="picker2"
                                        opens="left"
                                        :locale-data="{
                                            direction: 'ltr',
                                            format: 'mm/dd/yyyy',
                                            separator: ' - ',
                                            applyLabel: 'Apply',
                                            cancelLabel: 'Reset',
                                            weekLabel: 'W',
                                            customRangeLabel: 'Custom Range',
                                            daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                            firstDay: 1
                                        }"
                                        :show-dropdowns=false
                                        :auto-apply=false
                                        :linked-calendars=true
                                        v-model="dateRangeSecond"
                                        @update="updateValuesSecond"
                                        @cancel="resetValueSecond"
                                    >
                                        <template v-slot:input>
                                            <span v-if="date_second_start == '' || date_second_end == ''">Belum memilih Pembanding</span>
                                            <span v-else>{{ date_second_start }} - {{ date_second_end }}</span>
                                        </template>
                                        <div slot="footer" slot-scope="data" class="slot drp-buttons">
                                            <p>Tanggal terpilih : <b class="text-black">{{ data.rangeText }}</b></p>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="data.clickCancel"
                                                >Tutup
                                                </button>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="resetValueFirst(data)"
                                                >Reset
                                                </button>
                                            <button
                                                class="applyBtn btn btn-sm btn-success"
                                                :disabled="data.in_selection"
                                                type="button"
                                                @click="data.clickApply"
                                                >Apply
                                            </button>
                                        </div>
                                    </date-range-picker>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>
                                <button
                                    class="btn btn-outline-red px-4"
                                    @click="isCompare = false"
                                >
                                    Tutup
                                </button>
                            </div>
                            <div class="ms-auto">
                                <div class="d-flex">
                                    <div class="me-2">
                                        <button
                                            class="btn btn-outline-gray px-4"
                                            @click="resetCompare()"
                                            :disabled="
                                                compare_data_first == '' ||
                                                compare_data_second == ''
                                            "
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            class="btn btn-green-second px-4"
                                            :disabled="
                                                compare_data_first == '' ||
                                                compare_data_second == ''
                                            "
                                            @click="compareData()"
                                        >
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <follow-up-activities :default_dashboard="default_dashboard">
        </follow-up-activities>
        <div class="row">
            <div class="col-md-4 mt-4">
                <div class="box-dashboard w-100">
                    <h5 class="fw-semibold mb-0">Target Penjualan</h5>
                    <div class="mt-3">
                        <apexchart
                            type="radialBar"
                            ref="targetPenjualan"
                            height="350"
                            :options="target"
                            :series="targets"
                        ></apexchart>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-5">
                            <div
                                class="d-flex align-items-center justify-content-center"
                            >
                                <img
                                    src="../../assets/point-green.png"
                                    width="14"
                                    alt=""
                                />
                                <div class="ms-2">Bulanan</div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div
                                class="d-flex align-items-center justify-content-center"
                            >
                                <img
                                    src="../../assets/point-green-soft.png"
                                    width="14"
                                    alt=""
                                />
                                <div class="ms-2">Harian</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 mt-4">
                <div class="box-dashboard w-100">
                    <h5 class="fw-semibold mb-0">Grafik Harian Penjualan</h5>
                    <div class="mt-3">
                        <apexchart
                            type="area"
                            ref="areaChart"
                            height="350"
                            :options="saleGrafik"
                            :series="saleGrafiks"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <deal-predic-lost
            :default_dashboard="default_dashboard"
            :first_dashboard="first_dashboard"
            :second_dashboard="second_dashboard"
            :compare_data_first="compare_data_first"
            :compare_data_second="compare_data_second"
        >
        </deal-predic-lost>
        <div class="box-dashboard mt-4">
            <div class="d-flex align-items-center">
                <h5 class="fw-semibold mb-0">Leaderboards</h5>
                <div class="ms-auto">
                    <a href="/crm/target" class="text-green">
                        <u>Lihat Detail</u>
                    </a>
                </div>
            </div>
            <div class="row justify-content-center mb-4">
                <div class="col-md-10">
                    <div class="row justify-content-center">
                        <div
                            class="col-md-4 d-flex align-items-stretch"
                            v-bind:class="{
                                'mt-leaderboard': leaderboardTopThree.rank != 1,
                                'mt-4': leaderboardTopThree.rank == 1,
                                'order-first': leaderboardTopThree.rank == 2,
                                'order-last': leaderboardTopThree.rank == 3,
                            }"
                            v-for="(
                                leaderboardTopThree, index
                            ) in default_dashboard?.leaderboard?.slice(0, 3)"
                            :key="index"
                        >
                            <div
                                class="leaderboard w-100 position-relative"
                                v-bind:class="{
                                    green:
                                        leaderboardTopThree.rank ==
                                        default_dashboard.leaderboard_current
                                            .rank,
                                }"
                            >
                                <div
                                    class="congrats-position"
                                    v-if="
                                        leaderboardTopThree.rank ==
                                        default_dashboard.leaderboard_current
                                            .rank
                                    "
                                >
                                    <img
                                        src="../../assets/congrats.png"
                                        alt=""
                                    />
                                </div>
                                <div class="score-position">
                                    <img
                                        src="../../assets/second.png"
                                        alt=""
                                        v-if="leaderboardTopThree.rank == 2"
                                    />
                                    <img
                                        src="../../assets/first.png"
                                        alt=""
                                        v-if="leaderboardTopThree.rank == 1"
                                    />
                                    <img
                                        src="../../assets/third.png"
                                        alt=""
                                        v-if="leaderboardTopThree.rank == 3"
                                    />
                                </div>
                                <div class="mt-3">
                                    <center>
                                        <div
                                            class="size-22 text-green fw-bold mb-2"
                                            v-if="leaderboardTopThree.rank == 1"
                                        >
                                            Penjualan Terbanyak!
                                        </div>
                                        <div
                                            class="leaderboard-logo"
                                            :style="{
                                                'background-image':
                                                    'url(' +
                                                    leaderboardTopThree.avatar +
                                                    ')',
                                            }"
                                        ></div>
                                        <div
                                            class="size-18 fw-reguler mt-2 capitalize"
                                        >
                                            {{ leaderboardTopThree.name }}
                                        </div>
                                        <div class="size-24 fw-bold text-green">
                                            {{
                                                currency(
                                                    leaderboardTopThree.deal
                                                )
                                            }}
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-relative z-9">
                <div
                    class="leaderboard custom mt-3 p-2 position-relative"
                    v-bind:class="{
                        green:
                            leaderboardAfterTopThree.rank ==
                            default_dashboard.leaderboard_current.rank,
                    }"
                    v-for="(
                        leaderboardAfterTopThree, index
                    ) in default_dashboard.leaderboard"
                    :key="index"
                    v-show="leaderboardAfterTopThree.rank >= 4"
                >
                    <div
                        class="congrats-position"
                        v-if="
                            leaderboardAfterTopThree.rank ==
                            default_dashboard.leaderboard_current.rank
                        "
                    >
                        <img src="../../assets/congrats.png" alt="" />
                    </div>
                    <div class="d-flex bd-highlight align-items-center">
                        <div class="p-2 bd-highlight">
                            <div class="text-gray-second fw-semibold me-3">
                                {{ leaderboardAfterTopThree.rank }}th
                            </div>
                        </div>
                        <div class="p-2 bd-highlight flex-grow-1">
                            <div class="d-flex align-items-center">
                                <div
                                    class="leaderboard-logo small me-2"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            leaderboardAfterTopThree.avatar +
                                            ')',
                                    }"
                                ></div>
                                <div class="size-18 capitalize">
                                    {{ leaderboardAfterTopThree.name }}
                                </div>
                            </div>
                        </div>
                        <div class="p-2 bd-highlight">
                            <div class="size-24 fw-bold text-green">
                                {{ currency(leaderboardAfterTopThree.deal) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="shadow-position"
                    v-if="default_dashboard.leaderboard_current.rank > 5"
                >
                    <img src="../../assets/box-shadow.png" width="95%" alt="" />
                </div>
            </div>
            <div v-if="default_dashboard.leaderboard_current.rank > 5">
                <div
                    class="leaderboard green p-2 position-relative mt-congrats"
                >
                    <div class="congrats-position">
                        <img src="../../assets/congrats.png" alt="" />
                    </div>
                    <div class="d-flex bd-highlight align-items-center">
                        <div class="p-2 bd-highlight">
                            <div class="text-gray-second fw-semibold me-3">
                                {{
                                    default_dashboard.leaderboard_current.rank
                                }}th
                            </div>
                        </div>
                        <div class="p-2 bd-highlight flex-grow-1">
                            <div class="d-flex align-items-center">
                                <div
                                    class="leaderboard-logo small me-2"
                                    :style="{
                                        'background-image':
                                            'url(' +
                                            default_dashboard
                                                .leaderboard_current.avatar +
                                            ')',
                                    }"
                                ></div>
                                <div class="size-18">
                                    {{
                                        default_dashboard.leaderboard_current
                                            .name
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="p-2 bd-highlight">
                            <div class="size-24 fw-bold text-green">
                                {{
                                    currency(
                                        default_dashboard.leaderboard_current
                                            .deal
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import $ from "jquery";
import Api from "../../api/Api";
import FollowUpActivities from "../../components/dashboard/FollowUpActivities.vue";
import DealPredicLost from "../../components/dashboard/DealPredicLost.vue";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

function currencyRp(num) {
    return (
        "Rp" +
        parseInt(num)
            .toFixed(0)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
    );
}
function formatNumber(num) {
    if (num >= 1000000000) {
        return parseFloat((num / 1000000000).toFixed(1)) + "m";
    } else if (num >= 1000000) {
        return parseFloat((num / 1000000).toFixed(1)) + "jt";
    } else if (num >= 1000) {
        return parseFloat((num / 1000).toFixed(1)) + "rb";
    } else {
        return num.toString();
    }
}

export default {
    name: "dashboardCRMStaff",
    components: {
        apexchart: VueApexCharts,
        DateRangePicker,
        FollowUpActivities,
        DealPredicLost,
    },
    data() {
        return {
            moment: moment,
            targets: [],
            target: {
                chart: {
                    height: 350,
                    type: "radialBar",
                },
                grid: {
                    show: false,
                    padding: {
                        left: -200,
                        right: -200,
                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: "none",
                        },
                    },
                },
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                show: true,
                                offsetY: 20,
                                color: "#000",
                            },
                            value: {
                                show: true,
                                offsetY: -16,
                                color: "#4EC681",
                                formatter: function (val) {
                                    return val + "%";
                                },
                            },
                            total: {
                                show: true,
                                label: "Default",
                                color: "#000",
                                formatter: function () {
                                    return "0%";
                                },
                            },
                        },
                    },
                },
                colors: ["#4EC681", "#BED515"],
                labels: ["Bulanan", "Harian"],
                stroke: {
                    lineCap: "round",
                },
            },
            saleGrafiks: [
                {
                    data: [],
                },
            ],
            saleGrafik: {},
            dateRangeFirst: {
                startDate: '',
                endDate: '',
            },
            dateRangeSecond: {
                startDate: '',
                endDate: '',
            },
            date_first_start: "",
            date_second_start: "",
            date_first_end: "",
            date_second_end: "",
            default_dashboard: {
                company: null,
                customer: null,
                follow_up: null,
                journal: {
                    percentage: [],
                },
                leaderboard: null,
                leaderboard_branch: null,
                leaderboard_current: {},
                result: null,
                specialist: null,
                specialist_sales: null,
                specialist_target: null,
                team: null,
            },
            first_dashboard: {
                company: null,
                customer: null,
                follow_up: null,
                journal: {
                    percentage: [],
                },
                leaderboard: null,
                leaderboard_branch: null,
                leaderboard_current: null,
                result: null,
                specialist: null,
                specialist_sales: null,
                specialist_target: null,
                team: null,
            },
            second_dashboard: {
                company: null,
                customer: null,
                follow_up: null,
                journal: {
                    percentage: [],
                },
                leaderboard: null,
                leaderboard_branch: null,
                leaderboard_current: null,
                result: null,
                specialist: null,
                specialist_sales: null,
                specialist_target: {
                    monthly: {},
                    daily: {},
                },
                team: null,
            },
            isCompare: false,
            tooltips: [],
        };
    },
    created() {
        this.getDataDashboard();
        this.swapElements();
    },
    computed: {
        compare_data_first() {
            if (this.date_first_start == '' && this.date_first_end == '') return ''
            return this.date_first_start + ' - ' + this.date_first_end
        },
        compare_data_second() {
            if (this.date_second_start == '' && this.date_second_end == '') return ''
            return this.date_second_start + ' - ' + this.date_second_end
        },
        hasCompareDate() {
            return (this.date_first_start != '' && this.date_first_end != '') &&
                (this.date_second_start != '' && this.date_second_start != '')
        },
    },
    methods: {
        updateValuesFirst (values) {
            this.date_first_start = moment(values.startDate).format('YYYY-MM-DD')
            this.date_first_end = moment(values.endDate).format('YYYY-MM-DD')
        },
        updateValuesSecond (values) {
            this.date_second_start = moment(values.startDate).format('YYYY-MM-DD')
            this.date_second_end = moment(values.endDate).format('YYYY-MM-DD')
        },
        resetValueFirst () {
            this.date_first_start = ''
            this.date_first_end = ''
        },
        resetValueSecond () {
            this.date_second_start = ''
            this.date_second_end = ''
        },
        resetCompare() {
            this.date_first_start = ''
            this.date_first_end = ''
            this.date_second_start = ''
            this.date_second_end = ''
            this.first_dashboard = null;
            this.second_dashboard = null;
            this.isCompare = false;
        },
        compareData() {
            this.getFirstMonth();
            this.isCompare = false;
        },
        getDataDashboard() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v3`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.default_dashboard = data;
                    this.targets = data.specialist_target.percentage;

                    let maxChart = 2000000;
                    if (data.specialist_sales.sale.length) {
                        maxChart = Math.max(...data.specialist_sales.sale);
                    }

                    this.$refs.areaChart.updateSeries([
                        {
                            name: "Penjualan",
                            data: data.specialist_sales.sale,
                        },
                    ]);

                    let data_tooltips = [];

                    data_tooltips.push(data.specialist_target.daily);
                    data_tooltips.push(data.specialist_target.monthly);

                    let temp = data_tooltips[0];
                    data_tooltips[0] = data_tooltips[1];
                    data_tooltips[1] = temp;

                    this.$refs.areaChart.updateOptions({
                        chart: {
                            height: 350,
                            type: "area",
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        colors: ["#4CC580"],
                        stroke: {
                            curve: "smooth",
                        },
                        xaxis: {
                            categories: data.specialist_sales.date,
                        },
                        yaxis: {
                            title: {
                                text: "Total Penjualan",
                            },
                            min: 0,
                            max: maxChart,
                            tickAmount: 4,
                            labels: {
                                formatter: (value) => {
                                    return `${formatNumber(value)}`;
                                },
                            },
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return `${currencyRp(val)}`;
                                },
                            },
                            // x: false,
                        },
                    });
                    this.$refs.targetPenjualan.updateOptions({
                        tooltip: {
                            enabled: true,
                            custom: function (index) {
                                return (
                                    '<div class="px-3 py-1">' +
                                    "<span class='size-18 fw-medium text-green'>" +
                                    currencyRp(
                                        data_tooltips[index.seriesIndex]
                                            .real_deal
                                    ) +
                                    "</span>" +
                                    " / " +
                                    "<span class='size-18 fw-medium text-gray'>" +
                                    currencyRp(
                                        data_tooltips[index.seriesIndex]
                                            .target_deal
                                    ) +
                                    "</span>" +
                                    "</div>"
                                );
                            },
                        },
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFirstMonth() {
            if (this.date_first_start == '' || this.date_first_end == '') {
                return
            }
            let payload = {
                date_start : this.date_first_start,
                date_end : this.date_first_end
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v3`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.first_dashboard = data;
                    this.getSecondMonth();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSecondMonth() {
            if (this.date_second_start == '' || this.date_second_end == '') {
                return
            }
            let payload = {
                date_start : this.date_second_start,
                date_end : this.date_second_end
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v3`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.second_dashboard = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        $("document").ready(function () {
            $(".dropdown-menu").on("click", function (e) {
                if ($(this).hasClass("compare-data")) {
                    e.stopPropagation();
                }
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.progress {
    background-color: #e6e6e6;
    border-radius: 30px;
    height: 10px;
}
.progress-bar.blue {
    background-color: #1291ff;
    border-radius: 30px;
}
.progress-bar.green {
    background-color: #4ec681;
    border-radius: 30px;
}
.progress-bar.yellow {
    background-color: #bed515;
    border-radius: 30px;
}
.w-tim-target {
    width: 120px;
}
.leaderboard {
    padding: 20px 25px;
    border-radius: 1rem;
    box-shadow: 0 1px 5px -2px rgba(141, 145, 162, 0.25);
    background-color: #f5faff;
}
.score-position {
    position: absolute;
    right: -10px;
    top: -10px;
}
.score-position.first {
    position: absolute;
    right: -14px;
    top: -14px;
}
.score-position img {
    width: 40px;
}
.score-position.first img {
    width: 50px;
}
.mt-leaderboard {
    margin-top: 80px;
}
.leaderboard.green {
    background-color: #e8f8ef !important;
}
.leaderboard-logo {
    width: 80px;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f7f7f7;
}
.leaderboard-logo.small {
    width: 60px !important;
    height: 60px !important;
}
.box-dashboard.custom {
    background-color: #f5faff !important;
}
.box-dashboard.red {
    background-color: #feece6 !important;
}
.box-dashboard.gray {
    background-color: #f5faff !important;
}
.dropdown-menu {
    padding: 10px;
    box-shadow: none !important;
    border: 1px solid #d2d2d5 !important;
    border-radius: 10px !important;
    margin-left: 10px !important;
    z-index: 9;
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(0px, 43.7037px, 0px);
    min-width: 550px;
}
.congrats-position {
    position: absolute;
    left: -4px;
    top: 10px;
}
.congrats-position img {
    width: 60px;
}
.shadow-position {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 0;
}
.mt-congrats {
    margin-top: 60px;
}
.z-9 {
    z-index: 99;
}
</style>

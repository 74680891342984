<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/order" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">Detail Deal Order</h3>
            </div>
            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                <tr class="">
                    <td class="ps-0 w-200">Nomor Resi</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.receipt }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Tgl. Deal Order</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.date }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Layanan</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        {{ detail.service.split("-").join(" ") }}
                    </td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Kategori Customer</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.category_customer }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Nama Customer</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.customer_name }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Owner</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.owner }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Cabang</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.branch_name }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Harga</td>
                    <td class="w-30">:</td>
                    <td>{{ currency(detail.price) }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Rute</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.route }}</td>
                </tr>
                <tr class="">
                    <td class="ps-0 w-200">Berat Barang</td>
                    <td class="w-30">:</td>
                    <td>{{ detail.pack_weight }} Kg</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";

export default {
    name: "CrmOrderDetail",
    components: {},
    data() {
        return {
            moment: moment,
            detail: {},
        };
    },
    created() {
        this.getDetail();
    },
    computed: {},
    methods: {
        getDetail() {
            var payload = {
                id: this.$route.query.id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/orders/detail`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.w-30 {
    width: 30px;
}
.w-200 {
    width: 200px;
}
</style>

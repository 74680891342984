<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="mb-3">
                <h3 class="fw-bold capitalize">
                    Follow Up List -
                    <template v-if="prospect_type == ''">All</template
                    ><template v-else>{{
                        prospect_type.split("_").join(" ")
                    }}</template>
                </h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-3 m-mx-0">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Search"
                            v-model="keySearch"
                            @input="changePage()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="me-auto m-mt-1">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <multiselect
                            v-model="service"
                            :options="services"
                            label="name"
                            track-by="id"
                            placeholder="Select Service"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mb-2">
                        <multiselect
                            v-model="type_filter"
                            :options="types"
                            label="label"
                            track-by="type"
                            placeholder="Select Type"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mb-2" v-show="isRole(branchRoles)">
                        <multiselect
                            v-model="branch"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Branch"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mb-2" v-show="isRole(crmRoles)">
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            label="name"
                            track-by="id"
                            placeholder="Select CRM Specialist"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="range == ''"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="
                                branch == null &&
                                service == null &&
                                specialist == null &&
                                type_filter == null
                            "
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_data">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Follow Up
                                </th>
                                <th class="text-center" scope="col">Name</th>
                                <th class="text-center" scope="col">
                                    Last Activity
                                </th>
                                <th class="text-center" scope="col">Service</th>
                                <th class="text-center" scope="col">
                                    Data Type
                                </th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">
                                    Prediction
                                </th>
                                <th class="text-center" scope="col">Result</th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(crmRoles)"
                                >
                                    CRM Specialist
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(branchRoles)"
                                >
                                    Branch Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data" :key="index">
                                <td class="text-center">
                                    {{ itemNumber(index) }}
                                </td>
                                <td class="text-center">{{ item.due_date }}</td>
                                <td class="">
                                    <router-link
                                        class="text-link"
                                        :to="{
                                            name: 'crm-prospect-detail',
                                            params: { id: item.id },
                                        }"
                                        >{{ item.name }}</router-link
                                    >
                                </td>
                                <td class="capitalize">
                                    {{
                                        (item.activity_type
                                            ? item.activity_type
                                            : "-"
                                        )
                                            .split("_")
                                            .join(" ")
                                    }}
                                </td>
                                <td class="capitalize text-center">
                                    {{ item.service ? item.service : "-" }}
                                </td>
                                <td class="capitalize text-center">
                                    {{ item.type ? item.type : "-" }}
                                </td>
                                <td class="text-center">
                                    <div class="dropdown">
                                        <div
                                            :class="checkResult(item.result)"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                        >
                                            {{ titleCase(item.result) }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a
                                        :href="
                                            '/crm/prospect/prediction/' +
                                            item.id
                                        "
                                        class="prediction-color"
                                    >
                                        <u>
                                            <template
                                                v-if="item.prediction != null"
                                            >
                                                {{
                                                    currencyFormat(
                                                        item.prediction
                                                    )
                                                }}
                                            </template>
                                            <template v-else>
                                                Not Filled Yet
                                            </template>
                                            <img
                                                src="../../assets/dashboard/edit.png"
                                                alt=""
                                                class="ms-2"
                                                width="19"
                                            />
                                        </u>
                                    </a>
                                </td>
                                <td class="text-center">
                                    <div class="dropdown">
                                        <a
                                            href="javascript:void(0)"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                        >
                                            <img
                                                src="../../assets/dashboard/result.png"
                                                width="19"
                                                alt=""
                                            />
                                        </a>
                                        <ul
                                            class="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <li>
                                                <router-link
                                                    class="dropdown-item"
                                                    :to="{
                                                        name: 'crm-prospect-deal',
                                                        params: {
                                                            id: item.id,
                                                            isRole: checkRole(
                                                                crmRoles
                                                            ),
                                                        },
                                                    }"
                                                    >Deal</router-link
                                                >
                                            </li>
                                            <li>
                                                <router-link
                                                    class="dropdown-item"
                                                    :to="{
                                                        name: 'crm-prospect-lost',
                                                        params: {
                                                            id: item.id,
                                                            isRole: checkRole(
                                                                crmRoles
                                                            ),
                                                            type: 'lost',
                                                        },
                                                    }"
                                                    >Lost</router-link
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td
                                    class="capitalize"
                                    v-show="isRole(crmRoles)"
                                >
                                    {{ item.specialist_name }}
                                </td>
                                <td
                                    class="capitalize"
                                    v-show="isRole(branchRoles)"
                                >
                                    {{ item.branch_name }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="data.length == 0" class="text-center mt-section">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex mt-3">
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getData()"
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-if="data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    props: {
        items: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            crmRoles: ["admin-crm", "admin-super", "crm-manager"],
            branchRoles: ["admin-crm"],
            roles: [],
            data: "",
            keySearch: "",
            is_load_data: false,
            currentPage: 0,
            totalPage: 0,
            limit: 5,
            prospect_type: "",
            isFilter: false,
            branch: null,
            branchs: [],
            service: null,
            services: [
                {
                    name: "Kirim Barang",
                    id: "kirim-barang",
                },
                {
                    name: "Sewa Truk",
                    id: "sewa-truck",
                },
                {
                    name: "Kirim Mobil",
                    id: "kirim-mobil",
                },
                {
                    name: "Kirim Motor",
                    id: "kirim-motor",
                },
            ],
            specialist: null,
            specialists: [],
            type_filter: null,
            types: [
                {
                    type: "prospect",
                    label: "Prospect",
                },
                {
                    type: "leads",
                    label: "Leads",
                },
            ],
        };
    },
    created() {
        this.prospect_type = this.items.prospect_type;
        this.getData();
        this.getUserLogin();
        this.getBranchs();
        this.getSpecialists();
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        changePage() {
            this.getData();
        },
        getData() {
            this.is_load_data = true;
            var params = {
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
                limit: this.limit,
                search: this.keySearch,
                start_date: "",
                end_date: "",
                user_id: this.specialist?.id,
                type: this.type_filter?.type,
                filter: this.prospect_type,
                service: this.service?.id,
                branch_id: this.branch?.id,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospects`, {
                params: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.data = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        checkResult(value) {
            if (value == "deal") return "pill green";
            if (value == "pending") return "pill yellow";
            if (value == "lost") return "pill red";
            if (value == "none") return "pill gray";
        },
        itemNumber(index) {
            let number = index + this.startIndex + 1;
            return (number < 10 ? "" : "") + number;
        },
        clearFilter() {
            this.branch = null;
            this.service = null;
            this.specialist = null;
            this.type_filter = null;
            this.getData();
        },
        filter() {
            this.getData();
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSpecialists() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.limit;
        },
    },
};
</script>
<style lang="scss" scoped>
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px !important;
    height: 44px !important;
    border-radius: 8px;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.excel {
    width: 20px !important;
    height: 19.05px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    &-save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &-save:is(:hover, :focus) {
        background: #176f9e;
    }
    &-delete {
        background: #e60013;
        border: 1px solid #e60013;
        color: white;
    }
    &-delete:is(:hover, :focus) {
        background: #e94b58;
    }
    &-cancel {
        background: #ffffff;
        border: 1px solid #e94b58;
        color: #e94b58;
    }
    &-cancel:is(:hover, :focus) {
        background: #e94b58;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
}
.text-link {
    text-decoration: underline;
    color: #24516a;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white !important;
    &.gray {
        color: #000 !important;
        background-color: #f4f6f8;
        border: 1px solid #f4f6f8;
        outline: 1px solid #f4f6f8;
    }
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.prediction-color {
    color: #24516a;
}
</style>

<template>
    <!-- <div>
        <template v-if="isloadingScreen">
            <div class="loading-screen-content">
                <center>
                    <div class="cell">
                        <div class="logo-content bounce2">
                            <img src="https://i.ibb.co/XsKVXWh/logo-white.png" width="100%" alt="">
                        </div>
                        <div class="loading-title">
                            Loading
                            <span class="dot-one"> .</span>
                            <span class="dot-two"> .</span>
                            <span class="dot-three"> .</span>
                        </div>
                    </div>
                </center>
            </div>
        </template> -->
    <component :is="layout" />
    <!-- </div> -->
</template>

<script>
import firebase from "firebase/app";
import "firebase/messaging";

export default {
    name: "App",
    computed: {
        layout() {
            let layoutVar = this.$route?.meta
                ? this.$route?.meta.layout
                : "default";
            if (layoutVar === undefined || layoutVar == "undefined") {
                layoutVar = "default";
            }
            return layoutVar + "-layout";
        },
    },
    data() {
        return {
            jwtToken: localStorage.getItem("token"),
        };
    },

    created() {
        if (this.jwtToken == null) {
            this.$router.push("/");
        }
        // var Notification =
        //     window.Notification ||
        //     window.mozNotification ||
        //     window.webkitNotification;
        // var was_questioned = false;
        // if (Notification.permission == "default") {
        //     was_questioned = true;
        // }
        // Notification.requestPermission(function (permission) {
        //     if (was_questioned) {
        //         console.log("User was asked. New permission is: " + permission);
        //     }
        //     if ("permissions" in navigator) {
        //         navigator.permissions
        //             .query({ name: "notifications" })
        //             .then(function (notificationPerm) {
        //                 notificationPerm.onchange = function () {
        //                     console.log(
        //                         "User decided to change his seettings. New permission: " +
        //                             notificationPerm.state
        //                     );
        //                 };
        //             });
        //     }
        // });
        // try {
        //     firebase
        //         .messaging()
        //         .requestPermission()
        //         .then(() => {
        //             console.log("Notification permission granted");
        //             firebase
        //                 .messaging()
        //                 .getToken()
        //                 .then((token) => {
        //                     this.token = token;
        //                     this.receiveMessage();
        //                 });
        //         })
        //         .catch((err) => {
        //             console.log("Unable to get token ", err);
        //         });
        // } catch (e) {
        //     console.log(e);
        // }
    },
    filters: {
        formatExpTime(expTime) {
            const date = new Date(expTime * 1000);
            return date.toLocaleString();
        },
    },
    methods: {
        receiveMessage() {
            try {
                firebase.messaging().onMessage((payload) => {
                    console.log(payload);
                    this.message = payload.notification.body;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Pesan Baru",
                        text: payload.notification.body,
                    });
                });
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style scoped>
.loading-screen-content {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-title {
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
}
.logo-content {
    width: 80px;
    height: 80px;
    padding: 15px;
    border-radius: 50px;
    text-align: center;
    background: #e60013;
}
.logo-content img {
    margin-top: 10px;
}
span[class^="dot-"] {
    opacity: 0;
}
.dot-one {
    animation: dot-one 2s infinite linear;
    font-size: 25px;
}
.dot-two {
    animation: dot-two 2s infinite linear;
    font-size: 25px;
}
.dot-three {
    animation: dot-three 2s infinite linear;
    font-size: 25px;
}
@keyframes dot-one {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dot-two {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes dot-three {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
.bounce2 {
    animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
</style>

<template>
    <div class="padding-container">
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Deal Order List</h3>
                <div class="fw-bold ms-auto size-24 m-size-18">
                    Total Income
                    <span class="text-green size-24 m-size-18">{{
                        currency(order.total ? order.total : 0)
                    }}</span>
                </div>
            </div>
            <div class="row mt-4 m-mt-1">
                <div class="col-md-5">
                    <div class="d-flex align-items-center">
                        <div class="search-form w-search">
                            <input
                                type="text"
                                class="form-control w-search-leads"
                                @keyup.enter="changeSearch"
                                v-model="req.order_search"
                                placeholder="Search Resi"
                            />
                            <button
                                class="btn btn-primary"
                                @click="changeSearch()"
                            >
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                        <div class="ms-4 m-mt-1">
                            <button
                                class="btn btn-outline-black btn-filter px-5 d-flex"
                                @click="isFilter = !isFilter"
                            >
                                <img
                                    src="../../assets/dashboard/filter.png"
                                    class="me-1"
                                    width="18"
                                    alt=""
                                />
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 m-mt-2" v-show="isRole(createOrderDeal)">
                    <a
                        href="/crm/deal-order/create"
                        class="btn btn-primary pull-right px-4"
                    >
                        Tambah Deal Order
                    </a>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <label class="fw-bold mb-1">Service</label>
                        <multiselect
                            v-model="service"
                            :options="services"
                            label="name"
                            track-by="id"
                            placeholder="Select Service"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label class="fw-bold mb-1">Category</label>
                        <multiselect
                            v-model="category_filter"
                            :options="categories"
                            label="label"
                            track-by="type"
                            placeholder="Select Category"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label class="fw-bold mb-1">Branch</label>
                        <multiselect
                            v-model="branch"
                            :options="branchs"
                            label="name"
                            track-by="id"
                            placeholder="Select Bracnh"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label class="fw-bold">Date</label>
                        <date-picker
                            v-model="req.start"
                            type="date"
                            range
                            value-type="format"
                            class="w-100 mt-1"
                            format="DD MMMM YYYY"
                            :editable="false"
                            placeholder="Tanggal Last Order"
                            @change="getStartDate(req.start)"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="
                                service == null &&
                                category_filter == null &&
                                branch == null &&
                                req.start == ''
                            "
                            @click="filterData()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_order">
                <ListLoader />
            </div>
            <template v-else>
                <template v-if="order.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Date
                                    </th>
                                    <th class="text-center" scope="col">
                                        Service
                                    </th>
                                    <th class="text-center" scope="col">
                                        Resi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Category
                                    </th>
                                    <th class="text-center" scope="col">
                                        Cust Name
                                    </th>
                                    <th class="text-center" scope="col">
                                        Owner
                                    </th>
                                    <th class="text-center" scope="col">
                                        Cabang
                                    </th>
                                    <th class="text-center" scope="col">
                                        Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(order, index) in order.list_data"
                                >
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                moment(order.date).format(
                                                    "DD/MM/YYYY"
                                                )
                                            }}
                                        </td>
                                        <td class="text-center capitalize">
                                            <small
                                                class="tag red-solid w-100 radius"
                                                v-bind:class="{
                                                    'red-solid':
                                                        order.service ==
                                                        'kirim-barang',
                                                    'red-solid':
                                                        order.service ==
                                                        'kirim-motor',
                                                    'blue-solid':
                                                        order.service ==
                                                        'sewa-truck',
                                                    'yellow-solid':
                                                        order.service ==
                                                        'kirim-mobil',
                                                }"
                                            >
                                                {{
                                                    order.service
                                                        ? order.service
                                                        : "-"
                                                }}
                                            </small>
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    `/crm/order/detail?id=` +
                                                    order.id +
                                                    `&service=` +
                                                    order.service
                                                "
                                                class="text-link"
                                            >
                                                {{
                                                    order.receipt_code
                                                        ? order.receipt_code
                                                        : "-"
                                                }}
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="
                                                    order.category == 'repeat'
                                                "
                                            >
                                                Repeat Order
                                            </template>
                                            <template v-else>
                                                New Order
                                            </template>
                                        </td>
                                        <td>
                                            {{
                                                order.customer_name
                                                    ? order.customer_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                order.crm_specialist_name
                                                    ? order.crm_specialist_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                order.branch_name
                                                    ? order.branch_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                currency(
                                                    order.amount
                                                        ? order.amount
                                                        : "0"
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </template>
            <div class="d-flex mt-3">
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page_order"
                                @change="getOrderList()"
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-if="order.list_data.length > 0"
                            :link-gen="linkGenOrder"
                            v-model="currentPageOrder"
                            :number-of-pages="order.total_page"
                            base-url="#"
                            first-number
                            align="right"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
        DatePicker,
    },
    name: "CrmOrder",
    data() {
        return {
            moment: moment,
            is_load_order: false,
            order: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page_order: 5,
            currentPageOrder: 0,
            req: {
                order_search: "",
                start: "",
            },
            branchs: [],
            isFilter: false,
            service: null,
            services: [
                {
                    name: "Kirim Barang",
                    id: "kirim-barang",
                },
                {
                    name: "Kirim Motor",
                    id: "kirim-motor",
                },
                {
                    name: "Sewa Truk",
                    id: "sewa-truck",
                },
                {
                    name: "Kirim Mobil",
                    id: "kirim mobil",
                },
            ],
            category_filter: null,
            categories: [
                {
                    type: "repeat",
                    label: "Repeat Order",
                },
                {
                    type: "new",
                    label: "New Order",
                },
            ],
            branch: null,
            createOrderDeal: [
                "crm-specialist-cs",
                "crm-specialist-sales",
                "crm-specialist-telemarketer",
            ],
            roles: [],
            start_date: "",
        };
    },
    created() {},
    mounted() {
        this.getOrderList();
        this.getBranchs();
        this.getUserLogin();
    },
    methods: {
        getStartDate(value) {
            this.start_date = value;
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        changeSearch() {
            this.currentPageOrder = 0;
            this.getOrderList();
        },
        linkGenOrder(pageNumOrder) {
            return pageNumOrder === 1 ? `?` : `?page=${pageNumOrder}`;
        },
        getOrderList() {
            this.is_load_order = true;
            let payload = {
                search: this.req.order_search,
                service: this.service?.id,
                category: this.category_filter?.label,
                branch_id: this.branch?.id,
                per_page: this.per_page_order,
                page: this.$route.query.page,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date[0]).format(
                    "DD-MM-YYYY"
                );
                payload.end_date = moment(this.start_date[1]).format(
                    "DD-MM-YYYY"
                );
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/orders`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.order = data;
                    this.is_load_order = false;
                })
                .catch((err) => {
                    this.is_load_order = false;
                    console.log(err);
                });
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clearFilter() {
            this.service == null;
            this.category_filter == null;
            this.branch == null;
            this.req.start = "";
            this.start_date = "";
            this.getOrderList();
        },
        filterData() {
            this.start_date = this.req.start;
            this.end_date = this.req.end;
            this.getOrderList();
            this.currentPage = 0;
        },
    },
};
</script>

<style scoped>
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #3782e6 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #3782e6;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border: 1px solid #f7f7f7;
    border-radius: 8px;
}
.dropdown-item {
    font-weight: 500 !important;
}
.dropdown-item.no-hover:hover {
    background-color: #fff !important;
}
</style>

<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/customers/leads" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Add New Prospect / Leads</h3>
            </div>
            <div class="row mt-5" v-for="(form, index) in forms" :key="index">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Full Name</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Full Name"
                        v-model="form.name"
                    />
                </div>
                <div class="col-md-6 m-mt-1">
                    <label class="fw-bold mb-2">Phone Number</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Phone Number"
                        v-model="form.phone"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2">Email</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        v-model="form.email"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Company
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Company"
                        v-model="form.company"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Position
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Position"
                        v-model="form.position"
                    />
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Office Number
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Office Number"
                        v-model="form.office_number"
                    />
                </div>
                <div class="col-md-12 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Address
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <textarea
                        cols="30"
                        rows="4"
                        class="form-control"
                        placeholder="Address"
                        v-model="form.address"
                    ></textarea>
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"> Source Data </label>
                    <select
                        class="form-control form-select"
                        v-model="form.source"
                    >
                        <option value="web">Web</option>
                        <option value="proposal">Proposal</option>
                        <option value="flyer">Flyer</option>
                        <option value="rekomendasi">Rekomendasi</option>
                        <option value="telepon">Telepon</option>
                        <option value="instagram">Instagram</option>
                        <option value="facebook">Facebook</option>
                        <option value="iklan">Iklan</option>
                        <option value="">Other</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"> Data Type </label>
                    <select
                        class="form-control form-select"
                        v-model="form.type"
                    >
                        <option value="">Select Data Type</option>
                        <option value="prospect">Prospect</option>
                        <option value="leads">Leads</option>
                        <option value="repeat">Customer Repeat</option>
                    </select>
                </div>
                <div
                    class="col-md-12 mt-3 m-mt-1"
                    v-if="
                        form.source != 'web' &&
                        form.source != 'proposal' &&
                        form.source != 'flyer' &&
                        form.source != 'rekomendasi' &&
                        form.source != 'telepon' &&
                        form.source != 'instagram' &&
                        form.source != 'facebook' &&
                        form.source != 'iklan'
                    "
                >
                    <label class="fw-bold mb-2">Description</label>
                    <textarea
                        cols="30"
                        rows="4"
                        class="form-control"
                        placeholder="Type Description"
                        v-model="form.source"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-3 m-mt-1">
                    <label class="fw-bold mb-2"
                        >Tags
                        <span style="color: #8e8e94">(Optional)</span></label
                    >
                    <input-tag
                        v-model="form.tags"
                        class="input-tag mb-3"
                        placeholder="Enter Your Tags"
                    ></input-tag>
                </div>
                <div class="col-md-12">
                    <button
                        class="btn btn-sm px-3 btn-green me-2"
                        :disabled="form.tags.includes(`Kirim Barang`)"
                        @click="addTrawlpackTags((trawlpack_tag_index = index))"
                    >
                        Kirim Barang
                    </button>
                    <button
                        class="btn btn-sm px-3 btn-purple me-2"
                        :disabled="form.tags.includes(`Kirim Motor`)"
                        @click="
                            addTrawlpackBikeTags(
                                (trawlpack_bike_tag_index = index)
                            )
                        "
                    >
                        Kirim Motor
                    </button>
                    <button
                        class="btn btn-sm px-3 btn-yellow me-2"
                        :disabled="form.tags.includes(`Sewa Truk`)"
                        @click="
                            addTrawltruckTags((trawltruck_tag_index = index))
                        "
                    >
                        Sewa Truk
                    </button>
                    <button
                        class="btn btn-sm px-3 btn-blue me-2"
                        :disabled="form.tags.includes(`Kirim Mobil`)"
                        @click="
                            addTrawlcarrierTags(
                                (trawlcarrier_tag_index = index)
                            )
                        "
                    >
                        Kirim Mobil
                    </button>
                    <button
                        class="btn btn-sm px-3 btn-green me-2"
                        :disabled="form.tags.includes(`Via Udara`)"
                        @click="addViaUdaraTags((viaudara_tag_index = index))"
                    >
                        Via Udara
                    </button>
                    <button
                        class="btn btn-sm px-3 btn-green"
                        :disabled="form.tags.includes(`Sewa Container`)"
                        @click="addSewaContainerTags((sewa_container_tag_index = index))"
                    >
                        Sewa Container
                    </button>
                </div>
            </div>
            <div class="row mt-4">
                <div v-if="is_add" class="col">
                    <div class="d-flex justify-content-center">
                        <a href="javascript:void(0)" @click="addForm">
                            <img src="../../assets/buttons-plus.png" />
                        </a>
                    </div>
                </div>
                <div class="col-md-12 m-mt-3">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <button
                                class="btn btn-green btn-lg px-5 me-3"
                                type="button"
                                @click="storeData()"
                                :disabled="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    v-if="is_save"
                                ></span>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <div v-if="is_add">
                        <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                    </div>
                    <div v-if="!is_add">
                        <h4 class="mb-0 fw-bold mt-4">Data Updated!!</h4>
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        :href="`/crm/customers/leads/detail/${new_id_prospect}`"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessProspect"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessProspect">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <div v-if="is_add">
                        <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                    </div>
                    <div v-if="!is_add">
                        <h4 class="mb-0 fw-bold mt-4">Data Updated!!</h4>
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        :href="'/crm/customers/leads/prospect/' + id"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import InputTag from "vue-input-tag";

export default {
    components: {
        InputTag,
    },
    name: "leadsCreate",
    data() {
        return {
            modalSuccess: false,
            modalSuccessProspect: false,
            crmSpecialist: [],
            is_load: false,
            is_add: true,
            forms: [
                {
                    name: "",
                    phone: "",
                    email: "",
                    company: "",
                    position: "",
                    office_number: "",
                    address: "",
                    source: "iklan",
                    source_option: "",
                    type: "",
                    tags: [],
                },
            ],
            data: "",
            id: null,
            is_save: false,
            is_save_prospect: false,
            trawlpack_tag_index: 0,
            trawlpack_bike_tag_index: 0,
            trawltruck_tag_index: 0,
            trawlcarrier_tag_index: 0,
            viaudara_tag_index: 0,
            sewa_container_tag_index: 0,
            new_id_prospect: null,
        };
    },
    created() {
        this.data = this.$route.params.item;
    },
    methods: {
        addForm() {
            this.forms.push({
                name: "",
                phone: "",
                email: "",
                company: "",
                position: "",
                office_number: "",
                address: "",
                source: "iklan",
                source_option: "",
                type: "",
                tags: [],
            });
        },
        addTrawlpackTags() {
            this.forms[this.trawlpack_tag_index].tags.push("Kirim Barang");
        },
        addViaUdaraTags() {
            this.forms[this.viaudara_tag_index].tags.push("Via Udara");
        },
        addSewaContainerTags() {
            this.forms[this.sewa_container_tag_index].tags.push("Sewa Container");
        },
        addTrawlpackBikeTags() {
            this.forms[this.trawlpack_bike_tag_index].tags.push("Kirim Motor");
        },
        addTrawltruckTags() {
            this.forms[this.trawltruck_tag_index].tags.push("Sewa Truk");
        },
        addTrawlcarrierTags() {
            this.forms[this.trawlcarrier_tag_index].tags.push("Kirim Mobil");
        },
        storeData() {
            this.is_save = true;
            let data;
            if (this.is_add) {
                data = this.forms;
            }

            if (!this.is_add) {
                data = {
                    name: this.forms[0].name,
                    phone: this.forms[0].phone,
                    email: this.forms[0].email,
                    company: this.forms[0].company,
                    position: this.forms[0].position,
                    office_number: this.forms[0].office_number,
                    address: this.forms[0].address,
                    source: this.forms[0].source,
                    type: this.forms[0].type,
                    tags: this.forms[0].tags,
                };
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/lead/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.new_id_prospect = res.data.data.id;
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        /*
        storeDataWithProspect() {
            this.is_save_prospect = true;
            var data = [
                {
                    name: this.forms[0].name,
                    phone: this.forms[0].phone,
                    email: this.forms[0].email,
                    company: this.forms[0].company,
                    position: this.forms[0].position,
                    office_number: this.forms[0].office_number,
                    address: this.forms[0].address,
                    source: this.forms[0].source,
                    type: this.forms[0].type,
                    tags: this.forms[0].tags,
                },
            ];

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/lead/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save_prospect = false;
                    this.modalSuccessProspect = true;
                    this.id = res.data.data.id;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save_prospect = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        */
    },
};
</script>

<style>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.money-custom {
    height: 42px;
    font-size: 16px;
    padding-left: 15px;
}
.vue-input-tag-wrapper {
    padding-left: 10px !important;
    border-radius: 3px !important;
    border: 1px solid #e9ecef !important;
}
</style>

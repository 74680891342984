<template>
    <div class="padding-container">
        <template v-if="page_show == 'main_page'">
            <div class="fw-bold">
                <a
                    href="javascript:void(0)"
                    @click="$router.go(-1)"
                    class="text-gray-dashboard size-16"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </a>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3">Follow Up Detail</h3>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                            <tr>
                                <td class="ps-0 w-200">Follow Up</td>
                                <td class="w-30">:</td>
                                <td>
                                    <div class="d-flex">
                                        <date-picker
                                            v-model="dataDetail.due_date"
                                            type="date"
                                            value-type="format"
                                            format="YYYY-MM-DD"
                                            :clearable="false"
                                            placeholder="Masukkan tanggal"
                                            style="width: 250px"
                                            @change="changeDate()"
                                        >
                                        </date-picker>
                                        <!-- <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                class="text-blue-edit"
                                                @click="
                                                    is_edit_follow_up =
                                                        !is_edit_follow_up
                                                "
                                                v-if="!is_edit_follow_up"
                                            >
                                                <i class="fa fa-pencil me-2"></i>
                                                Edit
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                class="text-green"
                                                v-if="is_edit_follow_up"
                                                @click="updateFollowUp()"
                                            >
                                                <i class="fa fa-save me-2"></i>
                                                Save
                                            </a>
                                        </div> -->
                                    </div>
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Tgl. LastOrder</td>
                                <td class="w-30">:</td>
                                <td class="capitalize">
                                    {{
                                        (dataDetail.last_order
                                            ? dataDetail.last_order
                                            : "-"
                                        )
                                            .split("_")
                                            .join(" ")
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Last Activity</td>
                                <td class="w-30">:</td>
                                <td class="capitalize">
                                    {{
                                        (dataDetail.prospect_type
                                            ? dataDetail.prospect_type
                                            : "-"
                                        )
                                            .split("_")
                                            .join(" ")
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Marketing</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.specialist_name
                                            ? dataDetail.specialist_name
                                            : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Nama Customer</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.name ? dataDetail.name : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Email</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.email
                                            ? dataDetail.email
                                            : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Phone</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.phone
                                            ? dataDetail.phone
                                            : "-"
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-6 mt-table-second">
                        <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                            <tr>
                                <td class="ps-0 w-200">Position</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.position
                                            ? dataDetail.position
                                            : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Office Number</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.office_number
                                            ? dataDetail.office_number
                                            : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Address</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.address
                                            ? dataDetail.address
                                            : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Priority</td>
                                <td class="w-30">:</td>
                                <td class="capitalize">
                                    {{
                                        dataDetail.priority
                                            ? dataDetail.priority == "cold"
                                                ? "low priority"
                                                : dataDetail.priority == "hot"
                                                ? "high priority"
                                                : dataDetail.priority
                                            : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Customer Category</td>
                                <td class="w-30">:</td>
                                <td class="capitalize">
                                    {{
                                        dataDetail.type
                                            ? dataDetail.type == "lead"
                                                ? "prospect"
                                                : ""
                                            : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Data Type</td>
                                <td class="w-30">:</td>
                                <td>
                                    {{
                                        dataDetail.type ? dataDetail.type : "-"
                                    }}
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td class="ps-0 w-200">Status</td>
                                <td class="w-30">:</td>
                                <td>
                                    <div class="dropdown">
                                        <div
                                            :class="
                                                checkResult(dataDetail.result)
                                            "
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                        >
                                            {{ titleCase(dataDetail.result) }}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3 me-auto">Predictions History</h3>
                </div>

                <div class="d-flex align-items-center no-flex">
                    <div class="mt-4">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search"
                                placeholder="Search"
                                v-model="req.search_predictions_history"
                                @input="searchPredictionHistory()"
                            />
                            <span class="search-icon left">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="mt-4 ms-4">
                        <a
                            :href="
                                '/crm/prospect/prediction/' +
                                this.$route.params.id
                            "
                            class="btn btn-blue btn-with-search px-4"
                        >
                            <img
                                src="../../assets/dashboard/pencil.png"
                                width="17"
                                class="me-1"
                                alt=""
                            />
                            Edit Prediction
                        </a>
                    </div>
                </div>

                <div class="mt-4" v-if="load_predictions_history">
                    <ListLoader />
                </div>

                <div v-else>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Date
                                    </th>
                                    <th class="text-center" scope="col">
                                        Services
                                    </th>
                                    <th class="text-center" scope="col">
                                        Route
                                    </th>
                                    <th class="text-center" scope="col">
                                        Price
                                    </th>
                                    <th class="text-center" scope="col">
                                        Note
                                    </th>
                                    <th class="text-center" scope="col">
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in dataPredict"
                                    :key="index"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="text-center">
                                        {{
                                            moment(item.date).format(
                                                "MM/DD/YYYY"
                                            )
                                        }}
                                    </td>
                                    <td class="text-center capitalize">
                                        {{ item.service ? item.service : "-" }}
                                    </td>
                                    <td class="">
                                        {{ item.origin_regency }} -
                                        {{ item.destination_regency }}
                                    </td>
                                    <td class="text-center">
                                        {{ currency(item.price) }}
                                    </td>
                                    <td class="text-center capitalize">
                                        <template
                                            v-if="
                                                item.service == 'kirim-barang'
                                            "
                                        >
                                            {{ item.weight }} Kg
                                        </template>
                                        <template
                                            v-else-if="
                                                item.service == 'kirim-motor'
                                            "
                                        >
                                            <template
                                                v-if="item.bike_cc == 'low'"
                                            >
                                                100-149 CC
                                            </template>
                                            <template
                                                v-else-if="
                                                    item.bike_cc == 'middle'
                                                "
                                            >
                                                150-249 CC
                                            </template>
                                            <template v-else> >250 CC</template>
                                        </template>
                                        <template
                                            v-else-if="
                                                item.service == 'sewa-truck'
                                            "
                                        >
                                            {{
                                                (item.fleet_type??'')
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </template>
                                        <template v-else>
                                            {{
                                                (item.service_option??'')
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </template>
                                    </td>
                                    <td class="">{{ item.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        v-if="dataPredict.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>

                <div class="overflow-auto mt-3">
                    <b-pagination-nav
                        v-if="dataPredict.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePagePrediction"
                    ></b-pagination-nav>
                </div>
            </div>

            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3 me-auto">Follow Up history</h3>
                </div>

                <div class="d-flex align-items-center no-flex">
                    <div class="mt-4">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search"
                                placeholder="Search"
                                v-model="req.search_prospect_history"
                                @input="searchProspectHistory()"
                            />
                            <span class="search-icon left">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <div class="mt-4 ms-4">
                        <a
                            href="javascript:void(0)"
                            @click="openModalActivity()"
                            class="btn btn-primary btn-with-search px-4"
                        >
                            <img
                                src="../../assets/dashboard/plus.png"
                                width="12"
                                class="me-1 vertical-align-unset"
                                alt=""
                            />
                            Add Activity
                        </a>
                    </div>
                </div>

                <div class="mt-4" v-if="laod_prospect_istory">
                    <ListLoader />
                </div>

                <div v-else>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <!-- <th class="text-center" scope="col">
                                        Leads Count
                                    </th> -->
                                    <th class="text-center" scope="col">
                                        Created At
                                    </th>
                                    <th class="text-center" scope="col">
                                        Activity
                                    </th>
                                    <th class="text-center" scope="col">
                                        CRM Specialist
                                    </th>
                                    <th class="text-center" scope="col">
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(
                                        item, index
                                    ) in dataProsepectHistory"
                                    :key="index"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <!-- <td class="text-center">
                                        {{ item.prospect_count }}
                                    </td> -->
                                    <td class="text-center">
                                        {{
                                            moment(item.created_at).format(
                                                "DD/MM/YYYY"
                                            )
                                        }}
                                    </td>
                                    <td>
                                        <template
                                            v-for="(
                                                activiti, index
                                            ) in item.activities"
                                        >
                                            <small
                                                class="tag green px-2 py-0 capitalize"
                                                :key="index"
                                            >
                                                {{
                                                    activiti
                                                        .split("_")
                                                        .join(" ")
                                                }}
                                            </small>
                                        </template>
                                    </td>
                                    <td>
                                        {{
                                            item.specialist_name
                                                ? item.specialist_name
                                                : "-"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            item.description
                                                ? item.description
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        v-if="dataProsepectHistory.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>

                <div class="overflow-auto mt-3">
                    <b-pagination-nav
                        v-if="dataProsepectHistory.length > 0"
                        v-model="currentPageHistory"
                        :number-of-pages="totalPageHistory"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePageProspectHistory"
                    ></b-pagination-nav>
                </div>
            </div>

            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold me-auto">Follow Up Result</h3>
                </div>

                <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
                    <li class="nav-item w-50" role="presentation">
                        <button
                            class="nav-link dashboard left w-100 active"
                            id="pills-dealt-tab"
                            @click="
                                getResult('deal'),
                                    (req.search_prospect_result = ''),
                                    (currentPageResult = 0)
                            "
                            data-bs-toggle="pill"
                            data-bs-target="#pills-dealt"
                            type="button"
                            role="tab"
                            aria-controls="pills-dealt"
                            aria-selected="true"
                        >
                            Deal
                        </button>
                    </li>
                    <li class="nav-item w-50 m-mt-1" role="presentation">
                        <button
                            class="nav-link dashboard right w-100"
                            id="pills-lost-tab"
                            @click="
                                getResult('lost'),
                                    (req.search_prospect_result = ''),
                                    (currentPageResult = 0)
                            "
                            data-bs-toggle="pill"
                            data-bs-target="#pills-lost"
                            type="button"
                            role="tab"
                            aria-controls="pills-lost"
                            aria-selected="false"
                        >
                            Lost
                        </button>
                    </li>
                </ul>

                <div
                    class="d-flex align-items-center no-flex mt-4"
                    v-if="typeResult == 'deal'"
                >
                    <div class="me-4">
                        <div class="position-relative">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search"
                                placeholder="Search"
                                v-model="req.search_prospect_result"
                                @input="searchProspectResult()"
                            />
                            <span class="search-icon left">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="ms-auto me-2 m-mt-1">Rows per page</div>
                    <div>
                        <select
                            class="form-control form-select pr-28 m-mt-1"
                            v-model="limitResult"
                            @change="searchProspectResult"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </div> -->
                </div>

                <div class="mt-4" v-if="load_prospect_result">
                    <ListLoader />
                </div>

                <div v-else>
                    <div class="tab-content" id="pills-tabContent">
                        <div
                            class="tab-pane fade show active"
                            id="pills-dealt"
                            role="tabpanel"
                            aria-labelledby="pills-dealt-tab"
                        >
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th
                                                v-if="typeResult == 'deal'"
                                                class="text-center"
                                                scope="col"
                                            >
                                                Resi
                                            </th>
                                            <th
                                                class="text-center"
                                                scope="col"
                                                v-if="typeResult == 'deal'"
                                            >
                                                Route
                                            </th>
                                            <th
                                                class="text-center"
                                                scope="col"
                                                v-if="typeResult == 'deal'"
                                            >
                                                Price
                                            </th>
                                            <th class="text-center" scope="col">
                                                Description
                                            </th>
                                            <th class="text-center" scope="col">
                                                Marketing
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in dataResult"
                                            :key="index"
                                        >
                                            <td class="text-center">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center">
                                                {{
                                                    moment(item.date).format(
                                                        "MM/DD/YYYY"
                                                    )
                                                }}
                                            </td>
                                            <td
                                                v-if="typeResult == 'deal'"
                                                class="text-center"
                                            >
                                                {{ item.receipt_code }}
                                            </td>
                                            <td
                                                class="text-center"
                                                v-if="typeResult == 'deal'"
                                            >
                                                {{ item.origin_regency }} -
                                                {{ item.destination_regency }}
                                            </td>
                                            <td
                                                class="text-center"
                                                v-if="typeResult == 'deal'"
                                            >
                                                {{ currency(item.price) }}
                                            </td>
                                            <td>
                                                {{ item.description }}
                                            </td>
                                            <td>
                                                {{ item.crm_specialist }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="dataResult.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </div>

                <div class="overflow-auto mt-3">
                    <b-pagination-nav
                        v-if="dataResult.length > 0"
                        v-model="currentPageResult"
                        :number-of-pages="totalPageResult"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePageProspectResult"
                    ></b-pagination-nav>
                </div>
            </div>

            <!-- MODAL -->
            <div class="modal-vue">
                <div class="overlay" v-if="modalAddActivity"></div>
                <div class="modal-body-chat md" v-if="modalAddActivity">
                    <div class="mt-3">
                        <h4 class="mb-0 fw-bold">Add New Activity</h4>
                        <div class="mt-4">
                            <label class="fw-bold mb-2">Activity</label>
                            <select
                                class="form-control form-select"
                                v-model="req.subject"
                            >
                                <option value="">Choose Activities</option>
                                <option value="call">Call</option>
                                <option value="mail">Send Mail</option>
                                <option value="meeting">Meeting</option>
                                <option value="whatsapp">Chat WhatsApp</option>
                                <option value="follow_up">Follow Up</option>
                                <option value="canvasing">Canvasing</option>
                                <option value="respond_customer">
                                    Respond Customers
                                </option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div class="mt-3" v-if="req.subject == 'mail'">
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="mail"
                                    id="mail1"
                                    value="application_email"
                                    v-model="req.mail_type"
                                />
                                <label class="fw-medium" for="mail1"
                                    >Via Aplikasi</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="mail"
                                    id="mail2"
                                    value="email"
                                    v-model="req.mail_type"
                                />
                                <label class="fw-medium" for="mail2"
                                    >Via Email</label
                                >
                            </div>
                        </div>
                        <div
                            class="mt-3"
                            v-if="req.subject == 'respond_customer'"
                        >
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="respond_customer"
                                    id="package_delivery"
                                    value="package_delivery"
                                    v-model="req.respond_customer"
                                />
                                <label class="fw-medium" for="package_delivery"
                                    >Package Delivery</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="respond_customer"
                                    id="car_delivery"
                                    value="car_delivery"
                                    v-model="req.respond_customer"
                                />
                                <label class="fw-medium" for="car_delivery"
                                    >Car Delivery</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="respond_customer"
                                    id="truck_rental"
                                    value="truck_rental"
                                    v-model="req.respond_customer"
                                />
                                <label class="fw-medium" for="truck_rental"
                                    >Truck Rental</label
                                >
                            </div>
                        </div>
                        <div class="mt-3" v-if="req.subject == 'meeting'">
                            <label class="fw-bold mb-2">Title</label>
                            <input
                                class="form-control"
                                placeholder="Title"
                                v-model="req.meeting_title"
                            />
                            <div class="mt-3">
                                <label class="fw-bold mb-2">Date Time</label>
                                <date-picker
                                    v-model="req.meeting_date"
                                    type="datetime"
                                    value-type="format"
                                    class="w-100 mt-1"
                                    format="DD-MM-YYYY hh:mm"
                                    :clearable="false"
                                    placeholder="Start"
                                >
                                </date-picker>
                            </div>
                            <div class="form-check form-check-inline mt-3">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="meeting_type"
                                    id="online"
                                    value="online"
                                    v-model="req.meeting_type"
                                />
                                <label class="fw-medium" for="online"
                                    >Online</label
                                >
                            </div>
                            <div class="form-check form-check-inline mt-3">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="meeting_type"
                                    id="offline"
                                    value="offline"
                                    v-model="req.meeting_type"
                                />
                                <label class="fw-medium" for="offline"
                                    >Offline</label
                                >
                            </div>
                            <div class="mt-3">
                                <label class="fw-bold mb-2">Description</label>
                                <textarea
                                    cols="30"
                                    rows="4"
                                    class="form-control"
                                    placeholder="Description"
                                    v-model="req.meeting_desc"
                                ></textarea>
                            </div>
                        </div>
                        <div class="mt-3" v-if="req.subject == 'canvasing'">
                            <label class="fw-bold">Photo</label>
                            <div class="text-gray mb-2">Photo Max 5MB</div>
                            <label
                                type="file"
                                :class="!attachment ? 'upload__btn' : ''"
                            >
                                <img
                                    v-if="attachment"
                                    class="photo"
                                    :src="attachmentPictureUrl"
                                />
                                <p v-else class="mb-0">
                                    <i class="fa fa-plus"></i>
                                </p>
                                <input
                                    class="cursor-pointer"
                                    id="upload"
                                    type="file"
                                    name="file"
                                    ref="file"
                                    @change="uploadPhoto"
                                />
                            </label>
                        </div>
                        <div
                            class="mt-3"
                            v-if="req.subject != 'meeting' && req.subject != ''"
                        >
                            <label class="fw-bold mb-2">Description</label>
                            <textarea
                                cols="30"
                                rows="4"
                                class="form-control"
                                placeholder="Description"
                                v-model="req.subject_description"
                            ></textarea>
                        </div>
                    </div>
                    <div class="d-flex mt-4">
                        <div class="ms-auto">
                            <button
                                class="btn btn-outline-black px-5 btn-lg me-3"
                                @click="clearAddActifity()"
                            >
                                Cancel
                            </button>
                            <button
                                class="btn btn-green px-5 btn-lg"
                                type="button"
                                disabled
                                v-if="is_add_activity"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                class="btn btn-green px-5 btn-lg"
                                :disabled="req.subject == ''"
                                @click="addNewActivity()"
                                v-else
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-vue">
                <div class="overlay" v-if="modalSuccess"></div>
                <div class="modal-body-chat vsm" v-if="modalSuccess">
                    <div class="mt-4 text-center">
                        <img
                            src="../../assets/dashboard/checklis.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">Data Saved!</h4>
                    </div>
                    <div class="mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="okModalSuccess()"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="page_show == 'mail_page'">
            <div class="fw-bold">
                <a
                    href="javascript:void(0)"
                    @click="page_show = 'main_page'"
                    class="text-gray-dashboard size-16"
                >
                    <i class="fa fa-angle-left me-2"></i>
                    Back
                </a>
            </div>
            <div class="box mt-4">
                <div class="d-flex align-items-center">
                    <h3 class="fw-bold mb-3">Email</h3>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            From
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input
                            type="text"
                            class="form-control w-100"
                            placeholder="From"
                            v-model="req.mail_from"
                        />
                    </div>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            To
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input
                            type="text"
                            class="form-control w-100"
                            placeholder="To"
                            v-model="req.mail_to"
                        />
                    </div>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            CC
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input-tag
                            v-model="req.mail_cc"
                            class="input-tag"
                            placeholder="Type and Enter Email CC"
                        ></input-tag>
                    </div>
                </div>
                <div class="mt-3 d-flex bd-highligh align-items-center">
                    <div class="bd-highlight w-mail-title">
                        <label class="fw-medium text-gray-dashboard">
                            Subject
                        </label>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <input
                            type="text"
                            class="form-control w-100"
                            placeholder="Subject"
                            v-model="req.mail_subject"
                        />
                    </div>
                </div>
                <div class="mt-3">
                    <textarea
                        rows="7"
                        cols="30"
                        class="form-control"
                        placeholder="Type Here"
                        v-model="req.mail_content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <button
                        class="btn btn-green btn-lg px-5"
                        type="button"
                        disabled
                        v-if="is_save_mail"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Save
                    </button>
                    <button
                        type="submit"
                        v-else
                        class="btn btn-green btn-lg px-5"
                        @click="saveMail()"
                    >
                        Save
                    </button>
                </div>
            </div>

            <!-- MODAL -->
            <div class="modal-vue">
                <div class="overlay" v-if="modalSuccessEmail"></div>
                <div class="modal-body-chat vsm" v-if="modalSuccessEmail">
                    <div class="mt-4 text-center">
                        <img
                            src="../../assets/dashboard/checklis.png"
                            width="80"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4">Send Mail Succeeded!</h4>
                    </div>
                    <div class="mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="okModalSuccessEmail()"
                            class="btn btn-green w-100 btn-lg"
                        >
                            Ok
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import noImage from "../../assets/no-photo.png";
import InputTag from "vue-input-tag";

export default {
    components: {
        ListLoader,
        DatePicker,
        InputTag,
    },
    name: "ProspectDetail",
    data() {
        return {
            today: new Date(),
            moment: moment,
            dataDetail: "",
            dataPredict: "",
            dataResult: "",
            typeResult: "deal",
            load_predictions_history: false,
            laod_prospect_istory: false,
            load_prospect_result: false,
            currentPage: 0,
            currentPageResult: 0,
            currentPageHistory: 0,
            totalPage: 0,
            totalPageResult: 0,
            totalPageHistory: 0,
            limit: 10,
            limitResult: 10,
            limitHistory: 10,
            req: {
                search_predictions_history: "",
                search_prospect_history: "",
                search_prospect_result: "",
                subject: "",
                subject_description: null,
                mail_type: "application_email",
                meeting_date: moment(this.today).format("DD-MM-YYYY hh:mm"),
                meeting_type: "online",
                attachment: "",
                meeting_title: "",
                meeting_desc: "",
                respond_customer: "package_delivery",
                mail_from: "cs@trawlbens.id",
                mail_to: "zulaika@gmail.com",
                mail_cc: [],
                mail_subject: "",
                mail_content: "",
            },
            dataProsepectHistory: [],
            modalAddActivity: false,
            attachment: "",
            is_add_activity: false,
            modalSuccess: false,
            page_show: "main_page",
            is_save_mail: false,
            modalSuccessEmail: false,
            is_edit_follow_up: false,
        };
    },
    created() {
        this.getDataDetail();
        this.getDataPrediction();
        this.getDataResult();
        this.getDataProspectHistory();
    },
    methods: {
        checkResult(value) {
            if (value == "deal") return "pill green";
            if (value == "pending") return "pill yellow";
            if (value == "lost") return "pill red";
            if (value == "none") return "pill gray";
        },
        changePagePrediction() {
            this.getDataPrediction();
        },
        changePageProspectResult() {
            this.getDataResult();
        },
        changePageProspectHistory() {
            this.getDataProspectHistory();
        },
        okModalSuccess() {
            if (this.req.subject == "whatsapp") {
                this.modalSuccess = false;
                let phone = this.dataDetail.phone;
                if (phone.startsWith("0")) phone = "62" + phone.slice(1);
                this.getDataProspectHistory();
                window.open("https://wa.me/" + phone, "_blank");
            } else if (
                this.req.subject == "mail" &&
                this.req.mail_type == "email"
            ) {
                this.modalSuccess = false;
                this.getDataProspectHistory();
            } else {
                this.modalSuccess = false;
                this.getDataProspectHistory();
            }
        },
        openModalActivity() {
            this.clearAddActifity();
            this.modalAddActivity = true;
        },
        searchProspectResult() {
            this.getDataResult();
        },
        searchProspectHistory() {
            this.getDataProspectHistory();
        },
        searchPredictionHistory() {
            this.getDataPrediction();
        },
        getResult(value) {
            this.typeResult = value;
            this.getDataResult();
        },
        getDataDetail() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospect/detail`, {
                params: { prospect_id: this.$route.params.id },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.dataDetail = res.data.data;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                });
        },
        getDataPrediction() {
            this.load_predictions_history = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/predictions`,
                {
                    params: {
                        prospect_id: this.$route.params.id,
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 0,
                        limit: this.limit,
                        search: this.req.search_predictions_history,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataPredict = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.load_predictions_history = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.load_predictions_history = false;
                });
        },
        getDataProspectHistory() {
            this.laod_prospect_istory = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospect/history`, {
                params: {
                    prospect_id: this.$route.params.id,
                    page:
                        this.currentPageHistory == 0 ||
                        this.currentPageHistory == null
                            ? 0
                            : this.currentPageHistory - 1,
                    limit: this.limitHistory,
                    search: this.req.search_prospect_history,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.dataProsepectHistory = res.data.data.list_data;
                    this.totalPageHistory = res.data.data.total_page;
                    this.laod_prospect_istory = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.laod_prospect_istory = false;
                });
        },
        getDataResult() {
            this.load_prospect_result = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/prospect/results`, {
                params: {
                    id: this.$route.params.id,
                    type: this.typeResult,
                    page:
                        this.currentPageResult == 0 ||
                        this.currentPageResult == null
                            ? 0
                            : this.currentPageResult - 1,
                    limit: this.limitResult,
                    search: this.req.search_prospect_result,
                },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.dataResult = res.data.data.list_data;
                    this.totalPageResult = res.data.data.total_page;
                    this.load_prospect_result = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.load_prospect_result = false;
                });
        },
        setStatus(value) {
            if (value == "not_started") return "Not Started";
            if (value == "in_progress") return "In Progress";
            if (value == "deferes") return "Deferes";
            if (value == "completed") return "Completed";
        },
        checkStat(value) {
            if (value == "not_started") return "pill red";
            if (value == "in_progress") return "pill blue";
            if (value == "deferes") return "pill yellow";
            if (value == "completed") return "pill green";
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.attachment = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.attachment = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        clearAddActifity() {
            this.req.search_predictions_history = "";
            this.req.search_prospect_history = "";
            this.req.search_prospect_result = "";
            this.req.subject = "";
            this.req.subject_description = null;
            this.req.mail_type = "application_email";
            this.req.meeting_date = moment(this.today).format(
                "DD-MM-YYYY hh:mm"
            );
            this.req.meeting_type = "online";
            this.req.attachment = "";
            this.req.meeting_title = "";
            this.req.meeting_desc = "";
            this.req.respond_customer = "package_delivery";
            this.modalAddActivity = false;
        },
        addNewActivity() {
            this.is_add_activity = true;
            var data = new FormData();
            if (
                this.req.subject == "call" ||
                this.req.subject == "whatsapp" ||
                this.req.subject == "follow_up" ||
                this.req.subject == "mail"
            ) {
                data.append("prospect_id", this.$route.params.id);
                data.append("subject", this.req.subject);
            }
            if (this.req.subject == "meeting") {
                data.append("prospect_id", this.$route.params.id);
                data.append("meeting_type", this.req.meeting_type);
                data.append("meeting_title", this.req.meeting_title);
                data.append("meeting_desc", this.req.meeting_desc);
                data.append("meeting_date", this.req.meeting_date);
            }
            if (this.req.subject == "respond_customer") {
                data.append("prospect_id", this.$route.params.id);
                data.append("subject", this.req.respond_customer);
            }
            if (this.req.subject == "other") {
                data.append("prospect_id", this.$route.params.id);
                data.append("subject", this.req.subject);
            }
            if (this.req.subject != "meeting") {
                data.append(
                    "subject_description",
                    this.req.subject_description
                );
            }
            if (this.req.subject == "canvasing") {
                data.append("prospect_id", this.$route.params.id);
                data.append("subject", this.req.subject);
                data.append("attachment", this.req.attachment);
            }
            let api_url = "";
            if (this.req.subject == "meeting") {
                api_url = "/crm/customer/meet/create";
            } else {
                api_url = "/crm/prospect/activity";
            }
            Api.post(`${process.env.VUE_APP_SERVICE_URL}${api_url}`, data, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    this.is_add_activity = false;
                    if (
                        this.req.subject == "mail" &&
                        this.req.mail_type == "application_email"
                    ) {
                        this.page_show = "mail_page";
                    } else {
                        this.modalSuccess = true;
                        this.modalAddActivity = false;
                    }
                    this.updateFollowUp();
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_add_activity = false;
                });
        },
        saveMail() {
            this.is_save_mail = true;
            var data = {
                from: this.req.mail_from,
                to: this.req.mail_to,
                cc: this.req.mail_cc,
                subject: this.req.mail_subject,
                content: this.req.mail_content,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/customer/email/send`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_save_mail = false;
                    this.modalSuccessEmail = true;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_save_mail = false;
                });
        },
        okModalSuccessEmail() {
            this.modalSuccessEmail = false;
            this.page_show = "main_page";
            this.modalAddActivity = false;
            this.getDataProspectHistory();
        },
        updateFollowUp() {
            var data = {
                id: this.$route.params.id,
                follow_up: this.dataDetail.due_date,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeDate() {
            this.modalAddActivity = true;
        },
    },
    computed: {
        attachmentPictureUrl() {
            return this.attachment.length > 0 ? this.attachment : noImage;
        },
    },
};
</script>
<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.left-50 {
    position: absolute;
    left: 50px;
}
.left-80 {
    position: relative;
    left: 80px;
}
.w-200 {
    width: 200px;
}
.w-30 {
    width: 30px;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }
    &.gray {
        color: #000 !important;
        background-color: #f4f6f8;
        border: 1px solid #f4f6f8;
        outline: 1px solid #f4f6f8;
    }
    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px !important;
    height: 44px !important;
    border-radius: 8px;
}
.text-gray-dashboard {
    color: #61616a;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.nav-link.dashboard.mid {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.nav-link.dashboard.active {
    background-color: #3782e6 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.w-33 {
    width: 33%;
}
.w-34 {
    width: 34%;
}
@media (max-width: 576px) {
    .w-33 {
        width: 100%;
    }
    .w-34 {
        width: 100%;
    }
    .nav-link.dashboard.left {
        border-radius: 5px;
    }
    .nav-link.dashboard.right {
        border-radius: 5px;
    }
}
.vertical-align-unset {
    vertical-align: unset;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
.w-mail-title {
    width: 80px;
}
.view {
    border-color: transparent;
    background-color: initial;
    color: initial;
    padding: 0px;
}
.mt-table-second {
    margin-top: 13px;
}
</style>

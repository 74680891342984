<template>
    <section class="bc-login">
        <notifications group="foo" position="top right" />
        <div class="container mt-login">
            <center>
                <img src="../assets/logo.png" width="60" alt="">
                <h2 class="fw-bold mt-3">Atur Ulang Kata Sandi</h2>
            </center>
            <div class="row justify-content-center mt-5">
                <div class="col-md-5">
                    <form @submit.prevent="sendEmail()">
                        <input type="email" class="form-control form-login" id="email" placeholder="Masukkan Email Anda" v-model="req.email" autofocus />
                    </form>
                    <div class="mt-4">
                        <button class="btn btn-primary btn-lg w-100" type="button" disabled v-if="is_confirm">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Selanjutnya
                        </button>
                        <button type="submit" class="btn btn-primary btn-lg w-100" :disabled="req.email == ''" @click="sendEmail()" v-else>Selanjutnya</button>
                        <div class="text-center mt-4">
                            <a href="/" class="text-gray-dashboard size-16">
                                <i class="fa fa-angle-left me-2"></i>
                                Kembali Ke Halaman Sebelumnya
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Api from "../api/Api";

export default {
    props: "",
    name: "ForgotOtp",
    data() {
        return {
            is_confirm: false,
            req: {
                email: '',
            }
        };
    },
    computed: {
    },
    created() {
    },
    methods: {
        sendEmail(){
            this.is_confirm = true
            var data = {
                type: 'email',
                account: this.req.email,
            };
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/user/forget/email`, data)
            .then((res) => {
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Success',
                    text: 'Berhasil, Silahkan Lanjut Ke Halaman OTP'
                });
                this.is_confirm = false
                setTimeout(() => {
                    window.location.href = '/forgot/otp?otp_id=' + res.data.data.id + '&otp_code=' + '&expired_at=' + res.data.data.expired_at;
                }, 2000);
            })
            .catch((err) => {
                this.is_confirm = false;
                if(err.response.data.data == null){
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                    });
                }else{
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.data.message
                    });
                }
            })
        },
    }
};
</script>

<style scoped>
    .mt-login {
        margin-top: 160px;
    }
    .form-login{
        padding: 0.6rem 0.9rem;
        font-size: 15px;
        border-radius: 6px;
        border: 2px solid #f7f7f7;
    }
    .search-icon{
        top: 8px;
        font-size: 17px;
        right: 16px;
    }
</style>

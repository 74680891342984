<template>
    <div class="col-md-8 mt-4 d-flex align-items-stretch">
        <div
            class="box-dashboard w-100"
            v-bind:class="{
                'box-active': first_dashboard?.company != null,
            }"
        >
            <div class="d-flex align-items-center">
                <h5 class="fw-semibold mb-0">Target Tim</h5>
                <div class="ms-auto">
                    <a href="/crm/target" class="text-green">
                        <u>Lihat Detail</u>
                    </a>
                </div>
            </div>
            <!-- Default Data -->
            <template v-if="first_dashboard?.company == null">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight mt-4 me-3 w-tim-target">
                        <center>
                            <img
                                src="../../assets/cs-icon.png"
                                width="60"
                                alt=""
                            />
                            <div class="text-gray-second mt-1 size-14">
                                Customer Service
                            </div>
                        </center>
                    </div>
                    <div class="flex-grow-1 bd-highlight mt-3">
                        <div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        default_dashboard?.team?.cs?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        default_dashboard?.team?.cs?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div class="progress mt-2">
                            <div
                                class="progress-bar blue"
                                role="progressbar"
                                :style="{
                                    width:
                                        (default_dashboard?.team?.cs
                                            ?.real_deal /
                                            default_dashboard?.team?.cs
                                                ?.target_deal) *
                                            100 +
                                        '%',
                                }"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div class="mt-3">
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        default_dashboard?.team?.cs
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        default_dashboard?.team?.cs
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight mt-4 me-3 w-tim-target">
                        <center>
                            <img
                                src="../../assets/sales-icon.png"
                                width="60"
                                alt=""
                            />
                            <div class="text-gray-second mt-1 size-14">
                                Sales
                            </div>
                        </center>
                    </div>
                    <div class="flex-grow-1 bd-highlight mt-3">
                        <div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        default_dashboard?.team?.sales
                                            ?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        default_dashboard?.team?.sales
                                            ?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div class="progress mt-2">
                            <div
                                class="progress-bar green"
                                role="progressbar"
                                :style="{
                                    width:
                                        (default_dashboard?.team?.sales
                                            ?.real_deal /
                                            default_dashboard?.team?.sales
                                                ?.target_deal) *
                                            100 +
                                        '%',
                                }"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div class="mt-3">
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        default_dashboard?.team?.sales
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        default_dashboard?.team?.sales
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight mt-4 me-3 w-tim-target">
                        <center>
                            <img
                                src="../../assets/telemarketer-icon.png"
                                width="60"
                                alt=""
                            />
                            <div class="text-gray-second mt-1 size-14">
                                Telemarketer
                            </div>
                        </center>
                    </div>
                    <div class="flex-grow-1 bd-highlight mt-3">
                        <div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        default_dashboard?.team?.telemarketer
                                            ?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        default_dashboard?.team?.telemarketer
                                            ?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div class="progress mt-2">
                            <div
                                class="progress-bar yellow"
                                role="progressbar"
                                :style="{
                                    width:
                                        (default_dashboard?.team?.telemarketer
                                            ?.real_deal /
                                            default_dashboard?.team
                                                ?.telemarketer?.target_deal) *
                                            100 +
                                        '%',
                                }"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        <div class="mt-3">
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        default_dashboard?.team?.telemarketer
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        default_dashboard?.team?.telemarketer
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                    </div>
                </div>
            </template>
            <!-- End Default Data -->

            <!-- Compare Data -->
            <template v-if="first_dashboard?.company != null">
                <div class="d-flex bd-highlight align-items-center">
                    <div class="bd-highlight mt-4 me-3 w-tim-target">
                        <center>
                            <img
                                src="../../assets/cs-icon.png"
                                width="60"
                                alt=""
                            />
                            <div class="text-gray-second mt-1 size-14">
                                Customer Service
                            </div>
                        </center>
                    </div>
                    <div class="flex-grow-1 bd-highlight mt-3">
                        <div>
                            <div class="text-gray-second">
                                {{
                                    compare_data_first
                                }}
                            </div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        first_dashboard?.team?.cs?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        first_dashboard?.team?.cs?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div>
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        first_dashboard?.team?.cs
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        first_dashboard?.team?.cs
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                        <hr />
                        <div>
                            <div class="text-gray-second">
                                {{
                                    compare_data_second
                                }}
                            </div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        second_dashboard?.team?.cs?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        second_dashboard?.team?.cs?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div>
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        second_dashboard?.team?.cs
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        second_dashboard?.team?.cs
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="d-flex bd-highlight align-items-center">
                    <div class="bd-highlight mt-4 me-3 w-tim-target">
                        <center>
                            <img
                                src="../../assets/sales-icon.png"
                                width="60"
                                alt=""
                            />
                            <div class="text-gray-second mt-1 size-14">
                                Sales
                            </div>
                        </center>
                    </div>
                    <div class="flex-grow-1 bd-highlight mt-3">
                        <div>
                            <div class="text-gray-second">
                                {{
                                    compare_data_first
                                }}
                            </div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        first_dashboard?.team?.sales?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        first_dashboard?.team?.sales
                                            ?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div>
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        first_dashboard?.team?.sales
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        first_dashboard?.team?.sales
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                        <hr />
                        <div>
                            <div class="text-gray-second">
                                {{
                                    compare_data_second
                                }}
                            </div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        second_dashboard?.team?.sales?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        second_dashboard?.team?.sales
                                            ?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div>
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        second_dashboard?.team?.sales
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        second_dashboard?.team?.sales
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="d-flex bd-highlight align-items-center">
                    <div class="bd-highlight mt-4 me-3 w-tim-target">
                        <center>
                            <img
                                src="../../assets/telemarketer-icon.png"
                                width="60"
                                alt=""
                            />
                            <div class="text-gray-second mt-1 size-14">
                                Telemarketer
                            </div>
                        </center>
                    </div>
                    <div class="flex-grow-1 bd-highlight mt-3">
                        <div>
                            <div class="text-gray-second">
                                {{
                                    compare_data_first
                                }}
                            </div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        first_dashboard?.team?.telemarketer
                                            ?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        first_dashboard?.team?.telemarketer
                                            ?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div>
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        first_dashboard?.team?.telemarketer
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        first_dashboard?.team?.telemarketer
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                        <hr />
                        <div>
                            <div class="text-gray-second">
                                {{
                                    compare_data_second
                                }}
                            </div>
                            <span class="fw-bold size-24">
                                {{
                                    currency(
                                        second_dashboard?.team?.telemarketer
                                            ?.real_deal
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    currency(
                                        second_dashboard?.team?.telemarketer
                                            ?.target_deal
                                    )
                                }}
                            </small>
                        </div>
                        <div>
                            <span class="fw-bold size-18">
                                {{
                                    decimalFormat(
                                        second_dashboard?.team?.telemarketer
                                            ?.real_customer ?? 0
                                    )
                                }}
                            </span>
                            <small class="size-14 text-gray-second">
                                /
                                {{
                                    decimalFormat(
                                        second_dashboard?.team?.telemarketer
                                            ?.target_customer ?? 0
                                    )
                                }}
                                Pelanggan
                            </small>
                        </div>
                    </div>
                </div>
            </template>
            <!-- End Compare Data -->
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {},
    name: "TeamTarget",
    props: [
        "default_dashboard",
        "first_dashboard",
        "second_dashboard",
        "compare_data_first",
        "compare_data_second",
    ],
    data() {
        return {
            moment: moment,
        };
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.progress {
    background-color: #e6e6e6;
    border-radius: 30px;
    height: 10px;
}
.progress-bar.blue {
    background-color: #1291ff;
    border-radius: 30px;
}
.progress-bar.green {
    background-color: #4ec681;
    border-radius: 30px;
}
.progress-bar.yellow {
    background-color: #bed515;
    border-radius: 30px;
}
.w-tim-target {
    width: 120px;
}
.box-dashboard.custom {
    background-color: #f5faff !important;
}
.box-dashboard.red {
    background-color: #feece6 !important;
}
.box-dashboard.gray {
    background-color: #f5faff !important;
}
.text-red-smooth {
    color: #f87d53;
}
.text-red-bold {
    color: #7b2205;
}
.text-gray-bold {
    color: #494950;
}
@keyframes color-mix {
    0% {
        box-shadow: #c1ebd3 0px 1px 3px 0px;
    }
    100% {
        box-shadow: #37a968 0px 1px 3px 0px;
    }
}
.box-active {
    animation: color-mix 1s linear infinite alternate;
    box-shadow: 0 1px 12px -1px currentColor;
}
</style>

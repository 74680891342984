<template>
    <div class="box-dashboard w-100 mt-4">
        <div class="row">
            <div class="col-md-6">
                <h5 class="fw-semibold">Follow Up Activities CS Specialist</h5>
            </div>
            <div class="col-md-6">
                <select
                    class="form-select form-control mt-2"
                    v-model="branch"
                    v-if="branches.length"
                    @change="changeBranch()"
                    >
                    <option value="">Filter by Branch</option>
                    <option :value="b.id" v-for="(b, i) in branches" :key="i">{{b.name}}</option>
                </select>
            </div>
            <div class="col-md-4">
                <div class="box-dashboard red mt-3">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="text-red-smooth size-16">
                                Follow Up Hari Ini
                            </div>
                            <div class="size-24 fw-bold text-red-bold">
                                {{
                                    data_follow_up?.today ? decimalFormat(data_follow_up?.today ?? 0) : '-'
                                }}
                                Pelanggan
                            </div>
                        </div>
                        <div class="ms-auto">
                            <img
                                src="../../assets/fire.png"
                                width="60"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box-dashboard red mt-3">
                    <div class="d-flex align-items-center">
                        <div>
                            <div class="text-red-smooth size-16">
                                Butuh Follow Up
                            </div>
                            <div class="size-24 fw-bold text-red-bold">
                                {{
                                    data_follow_up?.need_follow_up ? decimalFormat(data_follow_up?.need_follow_up ?? 0) : '-'
                                }}
                                Pelanggan
                            </div>
                        </div>
                        <div class="ms-auto">
                            <img
                                src="../../assets/fire.png"
                                width="60"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box-dashboard gray mt-3">
                    <div class="text-gray-second size-16">Besok</div>
                    <div class="size-24 fw-bold text-gray-bold">
                        {{
                            data_follow_up?.tomorrow ? decimalFormat(data_follow_up?.tomorrow ?? 0) : '-'
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box-dashboard gray mt-3">
                    <div class="text-gray-second size-16">2 Hari Kedepan</div>
                    <div class="size-24 fw-bold text-gray-bold">
                        {{
                            data_follow_up?.next_2_days ? decimalFormat(data_follow_up?.next_2_days ?? 0) : '-'
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box-dashboard gray mt-3">
                    <div class="text-gray-second size-16">Minggu Depan</div>
                    <div class="size-24 fw-bold text-gray-bold">
                        {{
                            data_follow_up?.next_week ? decimalFormat(data_follow_up?.next_week ?? 0) : '-'
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box-dashboard gray mt-3">
                    <div class="text-gray-second size-16">Bulan Depan</div>
                    <div class="size-24 fw-bold text-gray-bold">
                        {{
                            data_follow_up?.next_month ? decimalFormat(data_follow_up?.next_month ?? 0) : '-'
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Api from "../../api/Api";

export default {
    components: {},
    name: "FollowUpActivities",
    props: ["default_dashboard", 'branches'],
    data() {
        return {
            moment: moment,
            branch: '',
            data_follow_up: null
        };
    },
    created() {
        this.data_follow_up = this.default_dashboard?.follow_up
    },
    methods: {
        changeBranch() {
            this.data_follow_up = null
            if (this.branch == '') {
                this.data_follow_up = this.default_dashboard
            } else {
                this.getDataFollowUp()
            }
        },
        getDataFollowUp() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v3/followup?branch_id=`+this.branch, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    let data = res.data.data
                    console.log('fu1', data.follow_up)
                    this.data_follow_up = data.follow_up
                    console.log('fu2', this.data_follow_up)
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
@keyframes color-mix {
    0% {
        box-shadow: #c1ebd3 0px 1px 3px 0px;
    }
    100% {
        box-shadow: #37a968 0px 1px 3px 0px;
    }
}
.box-active {
    animation: color-mix 1s linear infinite alternate;
    box-shadow: 0 1px 12px -1px currentColor;
}
.text-red-smooth {
    color: #f87d53;
}
.text-red-bold {
    color: #7b2205;
}
.box-dashboard.custom {
    background-color: #f5faff !important;
}
.box-dashboard.red {
    background-color: #feece6 !important;
}
.box-dashboard.gray {
    background-color: #f5faff !important;
}
</style>

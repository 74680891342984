<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/branchs" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Branch Detail</h3>
            </div>
            <div class="mt-4">
                <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                    <tr class="">
                        <td class="ps-0 w-200">Branch Code</td>
                        <td class="w-30">:</td>
                        <td>{{ detail.code ? detail.code : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Brand Name</td>
                        <td class="w-30">:</td>
                        <td>{{ detail.name ? detail.name : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Phone</td>
                        <td class="w-30">:</td>
                        <td>{{ detail.phone ? detail.phone : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Email</td>
                        <td class="w-30">:</td>
                        <td>{{ detail.email ? detail.email : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Employee</td>
                        <td class="w-30">:</td>
                        <td>{{ detail.employee ? detail.employee : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Total Income</td>
                        <td class="w-30">:</td>
                        <td>
                            {{
                                currencyFormat(
                                    detail.total_income
                                        ? detail.total_income
                                        : 0
                                )
                            }}
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Status</td>
                        <td class="w-30">:</td>
                        <td>
                            <span
                                class="tag px-5 radius capitalize"
                                v-bind:class="{
                                    'green-solid': detail.status == 'active',
                                    'red-solid': detail.status == 'not_active',
                                }"
                            >
                                {{ detail.status.split("_").join(" ") }}
                            </span>
                        </td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Region</td>
                        <td class="w-30">:</td>
                        <td>{{ detail.regency ? detail.regency : "-" }}</td>
                    </tr>
                    <tr class="border-top">
                        <td class="ps-0 w-200">Alamat</td>
                        <td class="w-30">:</td>
                        <td>{{ detail.address ? detail.address : "-" }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">CRM Specialist</h3>
            </div>
            <div>
                <div class="position-relative">
                    <input
                        type="text"
                        class="form-control form-control-lg pl-search w-search"
                        placeholder="Search"
                        v-model="req.search"
                        @input="changeSearch()"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search"></i>
                    </span>
                </div>
            </div>
            <div class="mt-4" v-if="is_crm_specialist">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">Name</th>
                                <th class="text-center" scope="col">Phone</th>
                                <th class="text-center" scope="col">Gender</th>
                                <th class="text-center" scope="col">Role</th>
                                <th class="text-center" scope="col">Email</th>
                                <th class="text-center" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in specialists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="">
                                    <router-link
                                        class="text-link"
                                        :to="{
                                            name: 'crm-specialist-detail',
                                            params: { id: item.id },
                                        }"
                                    >
                                        {{ item.name }}
                                    </router-link>
                                </td>
                                <td class="text-center">{{ item.phone }}</td>
                                <td class="text-center capitalize">
                                    {{ item.gender }}
                                </td>
                                <td class="text-center">{{ item.role }}</td>
                                <td class="">{{ item.email }}</td>
                                <td class="text-center">
                                    <span
                                        class="tag green-solid px-5 radius capitalize"
                                        v-if="item.is_active == true"
                                    >
                                        Active
                                    </span>
                                    <span
                                        class="tag red-solid px-5 radius capitalize"
                                        v-else
                                    >
                                        Non Active
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    v-if="specialists.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="specialists.list_data.length > 0"
                    v-model="currentPageSpecialist"
                    :number-of-pages="specialists.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePageSpecialist"
                ></b-pagination-nav>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">Target History</h3>
            </div>
            <div class="mt-4" v-if="is_target_history">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Month, Year
                                </th>
                                <th class="text-center" scope="col">
                                    Branch Name
                                </th>
                                <th class="text-center" scope="col">
                                    Customer Target
                                </th>
                                <th class="text-center" scope="col">
                                    Nominal Target
                                </th>
                                <th class="text-center" scope="col">
                                    Nominal Achieved
                                </th>
                                <th class="text-center" scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in histories.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="">
                                    {{ moment(item.date).format("MMMM, YYYY") }}
                                </td>
                                <td class="">
                                    {{ item.branch_name }}
                                </td>
                                <td class="">
                                    {{ formatDecimal(item.customer_target) }}
                                </td>
                                <td class="">
                                    {{ currencyFormat(item.amount_target) }}
                                </td>
                                <td class="">
                                    {{ currencyFormat(item.amount_acieved) }}
                                </td>
                                <td class="text-center">
                                    <template v-if="item.is_achieved == true">
                                        <div
                                            class="w-100 tag green-solid radius"
                                        >
                                            Achieved
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="w-100 tag red-solid radius">
                                            Not Achieved
                                        </div>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    v-if="histories.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    name: "crmBrnchsDetail",
    components: {
        ListLoader,
    },
    data() {
        return {
            moment: moment,
            detail: {},
            is_crm_specialist: false,
            specialists: {
                list_data: [],
            },
            per_page_specialist: 10,
            currentPageSpecialist: 0,
            req: {
                search: "",
            },
            histories: {
                list_data: [],
            },
            is_target_history: false,
        };
    },
    created() {
        this.getDetail();
        this.getCrmSpecialists();
        this.getTargetHistories();
    },
    computed: {},
    methods: {
        formatDecimal(num) {
            return (
                "" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changePageSpecialist() {
            this.getCrmSpecialists();
        },
        changeSearch() {
            this.currentPageSpecialist = 0;
            this.getCrmSpecialists();
        },
        getCrmSpecialists() {
            let payload = {
                search: this.req.search,
                branch_id: this.$route.params.id,
                limit: this.per_page_specialist,
                page:
                    this.currentPageSpecialist == 0 ||
                    this.currentPageSpecialist == null
                        ? 0
                        : this.currentPageSpecialist - 1,
            };
            this.is_crm_specialist = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist`,
                {
                    params: payload,
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.specialists = data;
                    this.is_crm_specialist = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_crm_specialist = false;
                });
        },
        getTargetHistories() {
            let payload = {
                branch_id: this.$route.params.id,
            };
            this.is_target_history = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/targets`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.histories = data;
                    this.is_target_history = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_target_history = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.w-200 {
    width: 200px;
}
</style>

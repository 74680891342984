<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">Detail Lost Order</h3>
            <div class="row mt-4">
                <div class="col-md-6 mt-3">
                    <label class="mb-2 fw-bold">
                        Nama Customer <span class="text-danger">*</span>
                    </label>
                    <div class="form-box">
                        {{ profile.name ? profile.name : "-" }}
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="mb-2 fw-bold">
                        Nomor Telepon Customer
                        <span class="text-danger">*</span>
                    </label>
                    <div class="form-box">
                        {{ profile.phone ? profile.phone : "-" }}
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">Riwayat Prediksi</h3>
            <div class="mt-4" v-if="is_load_prediction">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">Layanan</th>
                                <th class="text-center" scope="col">Rute</th>
                                <th class="text-center" scope="col">Harga</th>
                                <th class="text-center" scope="col">Berat</th>
                                <th class="text-center" scope="col">
                                    Deskripsi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in predictions"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>{{ item.service }}</td>
                                <td>
                                    {{ item.origin }} - {{ item.destination }}
                                </td>
                                <td>{{ currency(item.price) }}</td>
                                <td>{{ item.weight }}Kg</td>
                                <td>
                                    {{ item.description }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="predictions.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="predictions.length > 0"
                    v-model="currentPagePrediction"
                    :number-of-pages="totalPagePrediction"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePagePrediction"
                ></b-pagination-nav>
            </div>
        </div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">Follow Up History</h3>
            <div class="mt-4" v-if="is_load_history">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Tanggal Follow Up
                                </th>
                                <th class="text-center" scope="col">
                                    Aktivitas
                                </th>
                                <th class="text-center" scope="col">
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">
                                    Deskripsi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in histories" :key="index">
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    {{ item.follow_up_date }}
                                </td>
                                <td>{{ item.last_activity }}</td>
                                <td>{{ item.specialist }}</td>
                                <td>
                                    {{
                                        item.description
                                            ? item.description
                                            : "-"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="histories.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="histories.length > 0"
                    v-model="currentPageHistory"
                    :number-of-pages="totalPageHistory"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePageHistory"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    props: {},
    data() {
        return {
            moment: moment,
            profile: {},
            is_load_prediction: false,
            predictions: [],
            totalPagePrediction: 0,
            currentPagePrediction: 0,
            limitPrediction: 5,
            is_load_history: false,
            histories: [],
            totalPageHistory: 0,
            currentPageHistory: 0,
            limitHistory: 5,
        };
    },
    created() {
        this.getProfileData();
        this.getPrediction();
        this.getFollowUp();
    },
    methods: {
        getProfileData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/lost-order/list/detail?prospect_id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.profile = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPrediction() {
            this.is_load_prediction = true;
            var params = {
                page:
                    this.currentPagePrediction == 0 ||
                    this.currentPagePrediction == null
                        ? 0
                        : this.currentPagePrediction - 0,
                limit: this.limitPrediction,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/lost-order/list/detail/prediction?prospect_id=${this.$route.params.id}`,
                {
                    params: params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.predictions = res.data.data.list_data;
                    this.is_load_prediction = false;
                    this.totalPagePrediction = res.data.data.total_page;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_prediction = false;
                });
        },
        getFollowUp() {
            this.is_load_history = true;
            var params = {
                page:
                    this.currentPageHistory == 0 ||
                    this.currentPageHistory == null
                        ? 0
                        : this.currentPageHistory - 0,
                limit: this.limitHistory,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/lost-order/list/detail/history?prospect_id=${this.$route.params.id}`,
                {
                    params: params,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.histories = res.data.data.list_data;
                    this.is_load_history = false;
                    this.totalPageHistory = res.data.data.total_page;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_history = false;
                });
        },
        changePagePrediction() {
            this.getPrediction();
        },
        changePageHistory() {
            this.getFollowUp();
        },
    },
};
</script>
<style lang="scss" scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>

<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/customers/leads" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">
                Add New Tags
            </h3>
            <div class="mt-3">
                <label class="mb-2 fw-bold">Tags Name</label>
                <input-tag v-model="tags" class="input-tag mb-3" placeholder="Enter Your Tags"></input-tag>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <button class="btn btn-outline-black btn-lg px-5 me-2">Cancel</button>
                    <button class="btn btn-green btn-lg px-5">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputTag from 'vue-input-tag'

    export default {
        components: {
            InputTag
        },
        data() {
            return {
                tags: []
            };
        },
    };
</script>

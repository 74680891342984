<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a href="/crm/branchs" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box p-0 mt-4">
            <div class="box no-shadow bc-gray p-4 no-radius-bottom">
                <h5 class="mb-0 fw-bold">Edit Branch</h5>
            </div>
            <div class="p-4">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold">Brand Name</label>
                        <input
                            v-model="req.name"
                            type="text"
                            class="form-control mt-2 number"
                            placeholder="Brand Name"
                        />
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold">Phone</label>
                        <input
                            v-model="req.phone"
                            type="number"
                            class="form-control mt-2 number"
                            placeholder="Phone Number"
                        />
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold">Email</label>
                        <input
                            v-model="req.email"
                            type="email"
                            class="form-control mt-2 number"
                            placeholder="Branch Email"
                        />
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold">Province</label>
                        <multiselect
                            v-model="province"
                            :options="provinces"
                            label="name"
                            track-by="id"
                            placeholder="Select Province"
                            class="multi-form-custom mt-2"
                            @input="getRegency"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold">Regency</label>
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Select Regency"
                            class="multi-form-custom mt-2"
                            @input="getDistrict"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold">District</label>
                        <multiselect
                            v-model="district"
                            :options="districts"
                            label="name"
                            track-by="id"
                            placeholder="Select District"
                            class="multi-form-custom mt-2"
                            @input="getSubDistrict"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label class="fw-bold">Sub District</label>
                        <multiselect
                            v-model="subdistrict"
                            :options="subdistricts"
                            label="name"
                            track-by="id"
                            placeholder="Select Sub District"
                            class="multi-form-custom mt-2"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label class="fw-bold">Post Code</label>
                        <input
                            v-model="req.zipcode"
                            type="number"
                            class="form-control mt-2"
                            placeholder="Post Code"
                        />
                    </div>
                    <div class="col-md-12 mt-3">
                        <label class="fw-bold">Detail Address</label>
                        <textarea
                            cols="30"
                            rows="6"
                            class="form-control mt-2"
                            placeholder="Detail Address"
                            v-model="req.address"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="/crm/branchs"
                            class="btn btn-outline-black btn-lg w-100"
                            >Cancel</a
                        >
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            :disabled="is_store"
                            @click="updateDate()"
                        >
                            <span
                                v-if="is_store"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Update Data Succeeded!</h4>
                </div>
                <div class="mt-4">
                    <a href="/crm/branchs" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    name: "crmBranchsCreate",
    data() {
        return {
            req: {
                name: "",
                phone: "",
                email: "",
                address: "",
                zipcode: "",
            },
            province: {
                id: "",
                name: "",
            },
            provinces: [],
            regency: {
                id: "",
                name: "",
            },
            regencies: [],
            district: {
                id: "",
                name: "",
            },
            districts: [],
            subdistrict: {
                id: "",
                name: "",
            },
            subdistricts: [],
            modalSuccess: false,
            is_store: false,
        };
    },
    created() {
        this.getProvince();
        this.getDetail();
    },
    mounted() {},
    methods: {
        getProvince() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.provinces = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getRegency() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency?province_id=${this.province.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.regencies = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/district?regency_id=${this.regency.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.districts = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSubDistrict() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/subdistrict?district_id=${this.district.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.subdistricts = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.req.name = res.data.data.name;
                    this.req.phone = res.data.data.phone;
                    this.req.email = res.data.data.email;
                    this.req.address = res.data.data.address;
                    this.province.name = res.data.data.province_name;
                    this.province.id = res.data.data.province_id;
                    this.regency.name = res.data.data.regency_name;
                    this.regency.id = res.data.data.regency_id;
                    this.district.name = res.data.data.district_name;
                    this.district.id = res.data.data.district_id;
                    this.subdistrict.name = res.data.data.subdistrict_name;
                    this.subdistrict.id = res.data.data.subdistrict_id;
                    this.req.zipcode = res.data.data.post_code;
                    this.getRegency();
                    this.getDistrict();
                    this.getSubDistrict();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updateDate() {
            this.is_store = true;
            var data = {
                id: this.$route.params.id,
                name: this.req.name,
                phone: this.req.phone,
                email: this.req.email,
                address: this.req.address,
                province_id: this.province?.id,
                regency_id: this.regency?.id,
                district_id: this.district?.id,
                subdistrict_id: this.subdistrict?.id,
                zipcode: this.req.zipcode,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/edit`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_store = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_store = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped></style>

<template>
    <div class="padding-container">
        <a
            href="javascript:void(0)"
            @click="$router.go(-1)"
            class="text-gray-dashboard size-16"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </a>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">Tambah Deal Order</h3>
            <div class="row mt-5">
                <div class="col-md-6">
                    <label class="fw-bold mb-2">
                        Nomor Resi <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nomor resi"
                        v-model="req.receipt_code"
                    />
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2">
                        Nama Customer <span class="text-danger">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama customer"
                        v-model="req.customer_name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Nomor Telepon <span class="text-danger">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            @keypress="inputNumber($event)"
                            class="form-control"
                            placeholder="Masukkan nomor telepon"
                            v-model="req.phone"
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Jenis Layanan <span class="text-danger">*</span>
                    </label>
                    <multiselect
                        v-model="service"
                        :options="services"
                        label="name"
                        track-by="id"
                        placeholder="Pilih jenis layanan"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Kota/Kab. Asal <span class="text-danger">*</span>
                    </label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Kota/Kab. Tujuan <span class="text-danger">*</span>
                    </label>
                    <multiselect
                        v-model="destination"
                        :options="destinations"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten tujuan"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        <template v-if="service?.id == 'kirim-motor'"
                            >Kapasitas Motor</template
                        >
                        <template v-else> Berat Barang </template>
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="inputNumber($event)"
                            :placeholder="
                                service?.id == 'kirim-motor'
                                    ? 'Masukkan kapasitas motor'
                                    : 'Masukkan berat barang'
                            "
                            min="0"
                            v-model="req.weight"
                        />
                        <span class="input-group-text">
                            <template v-if="service?.id == 'kirim-motor'"
                                >CC</template
                            >
                            <template v-else>Kg</template>
                        </span>
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        Harga <span class="text-danger">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">Rp</span>
                        <money
                            v-model="price"
                            v-bind="price_format"
                            class="form-control"
                        >
                        </money>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">
                        Deskripsi <span class="text-danger">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control"
                        placeholder="Maksukkan deskripsi"
                        v-model="req.description"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex">
                <div class="ms-auto mt-4">
                    <a
                        href="/crm/order"
                        class="btn btn-outline-black px-5 btn-lg me-3"
                    >
                        Batal
                    </a>
                    <button
                        class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                        type="button"
                        disabled
                        v-if="is_save"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Simpan
                    </button>
                    <button
                        class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                        :disabled="
                            req.receipt_code == '' ||
                            req.customer_name == '' ||
                            req.phone == '' ||
                            service == null ||
                            regency == null ||
                            destination == null ||
                            req.weight == '' ||
                            price == null ||
                            req.description == ''
                        "
                        @click="storeData()"
                        v-else
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data berhasil disimpan!!</h4>
                </div>
                <div class="mt-4">
                    <a href="/crm/order" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "DealOrderCreate",
    data() {
        return {
            service: null,
            services: [
                {
                    id: "kirim-barang",
                    name: "Kirim Barang",
                },
                {
                    id: "kirim-motor",
                    name: "Kirim Motor",
                },
                {
                    id: "sewa-truck",
                    name: "Sewa Truck",
                },
                {
                    id: "kirim-mobil",
                    name: "Kirim Mobil",
                },
                {
                    id: "via-udara",
                    name: "Via Udara",
                },
                {
                    id: "sewa-container",
                    name: "Sewa Container",
                },
            ],
            regency: null,
            regencies: [],
            destination: null,
            destinations: [],
            price: "",
            price_format: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
            },
            req: {
                receipt_code: "",
                description: "",
                phone: "",
                customer_name: "",
                weight: "",
            },
            is_save: false,
            modalSuccess: false,
        };
    },
    created() {
        this.getRegency();
    },
    methods: {
        inputNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                    this.destinations = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        storeData() {
            this.is_save = true;
            var data = {
                customer_name: this.req.customer_name,
                receipt_code: this.req.receipt_code,
                phone: this.req.phone,
                weight: this.req.weight,
                amount: this.price,
                description: this.req.description,
                origin_regency_id: this.regency?.id,
                destination_regency_id: this.destination?.id,
                service_type: this.service?.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/result/deal/quick`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
    },
};
</script>
<style></style>

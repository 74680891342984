<template>
    <div class="mt-4">
        <customers :items="items" />
    </div>
</template>

<script>
import Customers from "./Customers.vue";

export default {
    components: {
        Customers,
    },
    data() {
        return {
            items: {
                name: "Leads Data",
                distribType: "lead",
            },
        };
    },
};
</script>

<template>
    <section class="bc-login">
        <notifications group="foo" position="top right" />
        <div class="container mt-login">
            <center>
                <img src="../assets/logo.png" width="60" alt="" />
                <h2 class="fw-bold mt-3">Verifikasi</h2>
                <div class="text-gray-dashboard size-16">
                    Masukkan kode verifikasi yang telah kami kirimkan pada email
                    Anda.
                </div>
            </center>
            <div class="row justify-content-center mt-5">
                <div class="col-md-5">
                    <form @submit.prevent="sendOTP()">
                        <div class="code-inputs">
                            <input
                                type="text"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                                class="form-control form-otp number me-2"
                                v-model="req.otp_1"
                                autofocus
                                @keyup.enter="sendOTP()"
                            />
                            <input
                                type="text"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                                class="form-control form-otp number me-2"
                                v-model="req.otp_2"
                                @keyup.enter="sendOTP()"
                            />
                            <input
                                type="text"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                                class="form-control form-otp number me-2"
                                v-model="req.otp_3"
                                @keyup.enter="sendOTP()"
                            />
                            <input
                                type="text"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                                class="form-control form-otp number me-2"
                                v-model="req.otp_4"
                                @keyup.enter="sendOTP()"
                            />
                            <input
                                type="text"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                                class="form-control form-otp number me-2"
                                v-model="req.otp_5"
                                @keyup.enter="sendOTP()"
                            />
                            <input
                                type="text"
                                maxlength="1"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                                class="form-control form-otp number"
                                v-model="req.otp_6"
                                @keyup.enter="sendOTP()"
                            />
                        </div>
                    </form>
                    <div class="mt-4">
                        <button
                            class="btn btn-primary btn-lg w-100"
                            type="button"
                            disabled
                            v-if="is_confirm"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Verifikasi
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary btn-lg w-100"
                            :disabled="
                                req.otp_1 == '' ||
                                req.otp_2 == '' ||
                                req.otp_3 == '' ||
                                req.otp_4 == '' ||
                                req.otp_5 == '' ||
                                req.otp_6 == ''
                            "
                            @click="sendOTP()"
                            v-else
                        >
                            Verifikasi
                        </button>
                        <div class="text-center mt-4">
                            <div
                                class="text-gray-dashboard d-flex align-items-center justify-content-center size-16"
                            >
                                <div class="me-1 size-16">
                                    Tidak dapat kode verifikasi?
                                </div>
                                <template
                                    v-if="
                                        moment(expired_at).format(
                                            'YYYY-MM-DD HH:mm:ss'
                                        ) <
                                        moment().format('YYYY-MM-DD HH:mm:ss')
                                    "
                                >
                                    <a
                                        href="javascript:void(0)"
                                        @click="resendEmail()"
                                        class="text-danger size-16 fw-bold"
                                        >Kirim Ulang</a
                                    >
                                </template>
                                <template v-else>
                                    <Countdown
                                        class="otp ms-2"
                                        :deadline="expired_at"
                                    ></Countdown>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Api from "../api/Api";
import $ from "jquery";
import moment from "moment";
import Countdown from "vuejs-countdown";

export default {
    components: {
        Countdown,
    },
    props: "",
    name: "ForgotOtp",
    data() {
        return {
            moment: moment,
            is_confirm: false,
            req: {
                otp_1: this.$route.query.otp_code.substring(0, 1),
                otp_2: this.$route.query.otp_code.substring(1, 2),
                otp_3: this.$route.query.otp_code.substring(2, 3),
                otp_4: this.$route.query.otp_code.substring(3, 4),
                otp_5: this.$route.query.otp_code.substring(4, 5),
                otp_6: this.$route.query.otp_code.substring(5, 6),
            },
            expired_at: this.$route.query.expired_at,
            today: new Date(new Date()),
        };
    },
    computed: {},
    created() {
        console.log(this.$route.query.otp_code.substring(5, 6));
    },
    mounted() {
        $(".number").keypress(function (e) {
            if (
                e.which != 8 &&
                e.which != 0 &&
                (e.which < 48 || e.which > 57)
            ) {
                $("#errmsg").html("Number Only").stop().show().fadeOut("slow");
                return false;
            }
        });
        $(".code-inputs .form-control").keyup(function () {
            if (this.value.length == this.maxLength) {
                $(this)
                    .nextAll(".code-inputs .form-control:enabled:first")
                    .focus();
            }
        });
        $(".code-inputs .form-control").keyup(function (e) {
            if (e.keyCode == 8) {
                if ($(this).prev().length > 0) {
                    $(this).prev("input").focus();
                }
            }
        });

        setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);
            this.expired = this.modifiedDate <= this.now;
        }, 1000);
    },
    methods: {
        sendOTP() {
            this.is_confirm = true;
            var data = {
                otp_id: this.$route.query.otp_id,
                otp_code:
                    this.req.otp_1 +
                    this.req.otp_2 +
                    this.req.otp_3 +
                    this.req.otp_4 +
                    this.req.otp_5 +
                    this.req.otp_6,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user/forget/email/verify`,
                data
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Verifikasi Berhasil",
                    });
                    this.is_confirm = false;
                    setTimeout(() => {
                        window.location.href =
                            "/forgot/new-password/" + this.$route.query.otp_id;
                    }, 2000);
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err.response.data.message);
                    this.is_confirm = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        resendEmail() {
            var data = {
                otp_id: this.$route.query.otp_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/user/forget/email/resend`,
                data
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Berhasil Mengirim Ulang OTP",
                    });
                    setTimeout(() => {
                        window.location.href =
                            "/forgot/otp?otp_id=" +
                            res.data.data.id +
                            "&otp_code=" +
                            "&expired_at=" +
                            res.data.data.expired_at;
                    }, 2000);
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                });
        },
    },
};
</script>

<style>
.mt-login {
    margin-top: 160px !important;
}
.form-login {
    padding: 0.6rem 0.9rem !important;
    font-size: 15px !important;
    border-radius: 6px !important;
    border: 2px solid #f7f7f7 !important;
}
.form-otp {
    padding: 0.6rem 0.9rem !important;
    font-size: 20px !important;
    border-radius: 6px !important;
    font-weight: bold !important;
    border: 2px solid #e9ecef !important;
    text-align: center !important;
}
.code-inputs {
    display: flex !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
}
.vuejs-countdown.otp .digit {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #3782e6 !important;
}
.vuejs-countdown.otp li:after {
    font-weight: 600 !important;
    color: #3782e6 !important;
}
</style>

<template>
    <div class="padding-container">
        <div class="d-flex align-items-center">
            <h1>
                <span class="fw-bold size-32"
                    >{{ default_dashboard.user_name }},
                </span>
                <small class="size-14 text-gray-second"
                    >selamat datang kembali!111</small
                >
            </h1>
            <div class="ms-auto">
                <div class="ms-3 mt-3 dropdown">
                    <button
                        class="btn btn-outline-green-second px-4 btn-lg"
                        @click="isCompare = !isCompare"
                    >
                        <img
                            src="../../assets/compare.png"
                            width="18"
                            class="me-2"
                            alt=""
                        />
                        Compare Data
                    </button>
                    <div
                        class="dropdown-menu compare-data dropdown-menu-end mt-3 show"
                        v-if="isCompare"
                    >
                        <div class="row">
                            <div class="col-md-12 border-right">
                                <div>
                                    <p class="text-center text-gray-second">
                                        Pembanding Pertama
                                    </p>
                                    <date-range-picker
                                        ref="picker1"
                                        opens="left"
                                        :locale-data="{
                                            direction: 'ltr',
                                            format: 'mm/dd/yyyy',
                                            separator: ' - ',
                                            applyLabel: 'Terapkan',
                                            cancelLabel: 'Reset',
                                            weekLabel: 'W',
                                            customRangeLabel: 'Custom Range',
                                            daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                            firstDay: 1
                                        }"
                                        :show-dropdowns=false
                                        :auto-apply=false
                                        :linked-calendars=true
                                        v-model="dateRangeFirst"
                                        @update="updateValuesFirst"
                                        @cancel="resetValueFirst"
                                    >
                                        <template v-slot:input>
                                            <span v-if="date_first_start == '' || date_first_end == ''">Belum memilih Pembanding</span>
                                            <span v-else>{{ date_first_start }} - {{ date_first_end }}</span>
                                        </template>
                                        <div slot="footer" slot-scope="data" class="slot drp-buttons">
                                            <p>Tanggal terpilih : <b class="text-black">{{ data.rangeText }}</b></p>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="data.clickCancel"
                                                >Tutup
                                                </button>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="resetValueFirst(data)"
                                                >Reset
                                                </button>
                                            <button
                                                class="applyBtn btn btn-sm btn-success"
                                                :disabled="data.in_selection"
                                                type="button"
                                                @click="data.clickApply"
                                                >Apply
                                            </button>
                                        </div>
                                    </date-range-picker>

                                    <p class="text-center text-gray-second">
                                        Pembanding Kedua
                                    </p>
                                    <date-range-picker
                                        ref="picker2"
                                        opens="left"
                                        :locale-data="{
                                            direction: 'ltr',
                                            format: 'mm/dd/yyyy',
                                            separator: ' - ',
                                            applyLabel: 'Apply',
                                            cancelLabel: 'Reset',
                                            weekLabel: 'W',
                                            customRangeLabel: 'Custom Range',
                                            daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                            firstDay: 1
                                        }"
                                        :show-dropdowns=false
                                        :auto-apply=false
                                        :linked-calendars=true
                                        v-model="dateRangeSecond"
                                        @update="updateValuesSecond"
                                        @cancel="resetValueSecond"
                                    >
                                        <template v-slot:input>
                                            <span v-if="date_second_start == '' || date_second_end == ''">Belum memilih Pembanding</span>
                                            <span v-else>{{ date_second_start }} - {{ date_second_end }}</span>
                                        </template>
                                        <div slot="footer" slot-scope="data" class="slot drp-buttons">
                                            <p>Tanggal terpilih : <b class="text-black">{{ data.rangeText }}</b></p>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="data.clickCancel"
                                                >Tutup
                                                </button>
                                            <button
                                                class="cancelBtn btn btn-sm btn-secondary"
                                                type="button"
                                                @click="resetValueFirst(data)"
                                                >Reset
                                                </button>
                                            <button
                                                class="applyBtn btn btn-sm btn-success"
                                                :disabled="data.in_selection"
                                                type="button"
                                                @click="data.clickApply"
                                                >Apply
                                            </button>
                                        </div>
                                    </date-range-picker>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>
                                <button
                                    class="btn btn-outline-red px-4"
                                    @click="isCompare = false"
                                >
                                    Tutup
                                </button>
                            </div>
                            <div class="ms-auto">
                                <div class="d-flex">
                                    <div class="me-2">
                                        <button
                                            class="btn btn-outline-gray px-4"
                                            @click="resetCompare()"
                                            :disabled="!hasCompareDate"
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    <div>
                                        <button
                                            class="btn btn-green-second px-4"
                                            :disabled="!hasCompareDate"
                                            @click="compareData()"
                                        >
                                            Simpan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <team-target
                :default_dashboard="default_dashboard"
                :first_dashboard="first_dashboard"
                :second_dashboard="second_dashboard"
                :compare_data_first="compare_data_first"
                :compare_data_second="compare_data_second"
            >
            </team-target>
            <company-target
                :default_dashboard="default_dashboard"
                :first_dashboard="first_dashboard"
                :second_dashboard="second_dashboard"
                :compare_data_first="compare_data_first"
                :compare_data_second="compare_data_second"
                :companyTarget="companyTarget"
                :companyTargets="companyTargets"
                :companyTargetsFirst="companyTargetsFirst"
                :companyTargetsSecond="companyTargetsSecond"
            >
            </company-target>
        </div>

        <div class="box-dashboard mt-4">
            <div class="d-flex align-items-center">
                <h5 class="fw-semibold mb-0">Leaderboards Cabang</h5>
            </div>
            <div class="row justify-content-center mb-4">
                <div class="col-md-10">
                    <div class="row justify-content-center">
                        <div
                            class="col-md-4 d-flex align-items-stretch"
                            v-bind:class="{
                                'mt-leaderboard': leaderboardTopThree.rank != 1,
                                'mt-4': leaderboardTopThree.rank == 1,
                                'order-first': leaderboardTopThree.rank == 2,
                                'order-last': leaderboardTopThree.rank == 3,
                            }"
                            v-for="(
                                leaderboardTopThree, index
                            ) in default_dashboard?.leaderboard_branch?.slice(
                                0,
                                3
                            )"
                            :key="index"
                        >
                            <div
                                class="leaderboard w-100 position-relative"
                                v-bind:class="{
                                    green: leaderboardTopThree.rank == 1,
                                }"
                            >
                                <div class="score-position">
                                    <img
                                        src="../../assets/second.png"
                                        alt=""
                                        v-if="leaderboardTopThree.rank == 2"
                                    />
                                    <img
                                        src="../../assets/first.png"
                                        alt=""
                                        v-if="leaderboardTopThree.rank == 1"
                                    />
                                    <img
                                        src="../../assets/third.png"
                                        alt=""
                                        v-if="leaderboardTopThree.rank == 3"
                                    />
                                </div>
                                <div class="mt-3">
                                    <center>
                                        <div
                                            class="size-22 text-green fw-bold mb-2"
                                            v-if="leaderboardTopThree.rank == 1"
                                        >
                                            Penjualan Terbanyak!
                                        </div>
                                        <div
                                            class="leaderboard-logo"
                                            :style="{
                                                'background-image':
                                                    'url(' +
                                                    leaderboardTopThree.avatar +
                                                    ')',
                                            }"
                                        ></div>
                                        <div
                                            class="size-18 fw-reguler mt-2 capitalize"
                                        >
                                            {{ leaderboardTopThree.name }}
                                        </div>
                                        <div class="size-24 fw-bold text-green">
                                            {{
                                                currency(
                                                    leaderboardTopThree.deal
                                                )
                                            }}
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="box-dashboard custom mt-3 p-2"
                v-for="(
                    leaderboardAfterTopThree, index
                ) in default_dashboard.leaderboard_branch"
                :key="index"
                v-show="leaderboardAfterTopThree.rank >= 4"
            >
                <div class="d-flex bd-highlight align-items-center">
                    <div class="p-2 bd-highlight">
                        <div class="text-gray-second fw-semibold">
                            {{ leaderboardAfterTopThree.rank }}th
                        </div>
                    </div>
                    <div class="p-2 bd-highlight flex-grow-1">
                        <div class="d-flex align-items-center">
                            <div
                                class="leaderboard-logo small me-2"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        leaderboardAfterTopThree.avatar +
                                        ')',
                                }"
                            ></div>
                            <div class="size-18 capitalize">
                                {{ leaderboardAfterTopThree.name }}
                            </div>
                        </div>
                    </div>
                    <div class="p-2 bd-highlight">
                        <div class="size-24 fw-bold text-green">
                            {{ currency(leaderboardAfterTopThree.deal) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <customer-data
            :default_dashboard="default_dashboard"
            :first_dashboard="first_dashboard"
            :second_dashboard="second_dashboard"
            :compare_data_first="compare_data_first"
            :compare_data_second="compare_data_second"
        >
        </customer-data>

        <deal-predic-lost
            :default_dashboard="default_dashboard"
            :first_dashboard="first_dashboard"
            :second_dashboard="second_dashboard"
            :compare_data_first="compare_data_first"
            :compare_data_second="compare_data_second"
        >
        </deal-predic-lost>
        <follow-up-activities :default_dashboard="default_dashboard" :branches="branches">
        </follow-up-activities>
        <follow-up-activities-cro :default_dashboard="default_dashboard" :branches="branches">
        </follow-up-activities-cro>
        <div class="row">
            <leaderboard
                :leaderboards="default_dashboard.leaderboard"
                :title="title"
            ></leaderboard>
            <crm-specialist
                :default_dashboard="default_dashboard"
                :crmSpecialist="crmSpecialist"
                :crmSpecialists="crmSpecialists"
            >
            </crm-specialist>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import $ from "jquery";
import Api from "../../api/Api";
import moment from "moment";
import CustomerData from "../../components/dashboard/CustomerData.vue";
import TeamTarget from "../../components/dashboard/TeamTarget.vue";
import CompanyTarget from "../../components/dashboard/CompanyTarget.vue";
import DealPredicLost from "../../components/dashboard/DealPredicLost.vue";
import FollowUpActivities from "../../components/dashboard/FollowUpActivities.vue";
import FollowUpActivitiesCro from "../../components/dashboard/FollowUpActivitiesCRO.vue";
import Leaderboard from "../../components/dashboard/Leaderboard.vue";
import CrmSpecialist from "../../components/dashboard/CrmSpecialist.vue";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    components: {
        DateRangePicker,
        CustomerData,
        TeamTarget,
        CompanyTarget,
        DealPredicLost,
        FollowUpActivities,
        FollowUpActivitiesCro,
        Leaderboard,
        CrmSpecialist,
    },
    name: "crmDashboard",
    data() {
        return {
            branches: [],
            companyTargets: [],
            companyTargetsFirst: [],
            companyTargetsSecond: [],
            companyTarget: {
                chart: {
                    height: 350,
                    type: "radialBar",
                },
                grid: {
                    show: false,
                    padding: {
                        left: -100,
                        right: -100,
                    },
                },
                colors: ["#4CC580"],
                plotOptions: {
                    radialBar: {
                        dataLabels: {
                            name: {
                                fontSize: "200px",
                                color: "#494950",
                                offsetY: 20,
                                show: true,
                            },
                            value: {
                                offsetY: -20,
                                fontSize: "16px",
                                color: "#4CC580",
                                fontWeight: "100",
                                show: true,
                            },
                        },
                    },
                },
                labels: ["Target Tercapai"],
                states: {
                    hover: {
                        filter: {
                            type: "none",
                        },
                    },
                },
            },
            crmSpecialists: [],
            crmSpecialistsFirst: [],
            crmSpecialistsSecond: [],
            crmSpecialist: {
                chart: {
                    type: "donut",
                },
                grid: {
                    show: false,
                    padding: {
                        left: -200,
                        right: -200,
                    },
                },
                colors: ["#4EC681", "#BED515"],
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                labels: ["Perempuan", "Laki-laki"],
                states: {
                    hover: {
                        filter: {
                            type: "none",
                        },
                    },
                },
                tooltip: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    offsetY: 20,
                                },
                                value: {
                                    show: true,
                                    offsetY: -16,
                                    color: "#4EC681",
                                    formatter: function (val) {
                                        return val;
                                    },
                                },
                                total: {
                                    show: true,
                                    label: "Total",
                                    color: "#002647",
                                    formatter: function (w) {
                                        return w.globals.seriesTotals.reduce(
                                            (a, b) => {
                                                return a + b;
                                            },
                                            0
                                        );
                                    },
                                },
                            },
                        },
                    },
                },
            },
            dateRangeFirst: {
                startDate: '',
                endDate: '',
            },
            dateRangeSecond: {
                startDate: '',
                endDate: '',
            },
            date_first_start: "",
            date_second_start: "",
            date_first_end: "",
            date_second_end: "",
            default_dashboard: {
                company: null,
                customer: null,
                follow_up: null,
                journal: {
                    percentage: [],
                },
                leaderboard: null,
                leaderboard_branch: null,
                result: null,
                specialist: null,
                specialist_sales: null,
                specialist_target: null,
                team: null,
            },
            first_dashboard: {
                company: null,
                customer: null,
                follow_up: null,
                journal: {
                    percentage: [],
                },
                leaderboard: null,
                leaderboard_branch: null,
                result: null,
                specialist: null,
                specialist_sales: null,
                specialist_target: null,
                team: null,
            },
            second_dashboard: {
                company: null,
                customer: null,
                follow_up: null,
                journal: {
                    percentage: [],
                },
                leaderboard: null,
                leaderboard_branch: null,
                result: null,
                specialist: null,
                specialist_sales: null,
                specialist_target: null,
                team: null,
            },
            isCompare: false,
            moment: moment,
            title: "Leaderboards",
        };
    },
    created() {
        this.getDataDashboard();
    },
    computed: {
        compare_data_first() {
            if (this.date_first_start == '' && this.date_first_end == '') return ''
            return this.date_first_start + ' - ' + this.date_first_end
        },
        compare_data_second() {
            if (this.date_second_start == '' && this.date_second_end == '') return ''
            return this.date_second_start + ' - ' + this.date_second_end
        },
        hasCompareDate() {
            return (this.date_first_start != '' && this.date_first_end != '') &&
                (this.date_second_start != '' && this.date_second_start != '')
        },
    },
    methods: {
        updateValuesFirst (values) {
            this.date_first_start = moment(values.startDate).format('YYYY-MM-DD')
            this.date_first_end = moment(values.endDate).format('YYYY-MM-DD')
        },
        updateValuesSecond (values) {
            this.date_second_start = moment(values.startDate).format('YYYY-MM-DD')
            this.date_second_end = moment(values.endDate).format('YYYY-MM-DD')
        },
        resetValueFirst () {
            this.date_first_start = ''
            this.date_first_end = ''
        },
        resetValueSecond () {
            this.date_second_start = ''
            this.date_second_end = ''
        },
        resetCompare() {
            this.date_first_start = ''
            this.date_first_end = ''
            this.date_second_start = ''
            this.date_second_end = ''
            this.first_dashboard = null;
            this.second_dashboard = null;
            this.isCompare = false;
        },
        compareData() {
            this.getFirstMonth();
            this.isCompare = false;
        },
        getDataDashboard() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v3`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.branches = data.branches
                    this.default_dashboard = data;
                    this.companyTargets = data.company.percentage;
                    this.crmSpecialists.push(data.specialist.female);
                    this.crmSpecialists.push(data.specialist.male);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFirstMonth() {
            if (this.date_first_start == '' || this.date_first_end == '') {
                return
            }
            let payload = {
                date_start : this.date_first_start,
                date_end : this.date_first_end
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v3`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.first_dashboard = data;
                    this.companyTargetsFirst = data.company.percentage;
                    this.crmSpecialistsFirst.push(data.specialist.female);
                    this.crmSpecialistsFirst.push(data.specialist.male);
                    this.getSecondMonth();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSecondMonth() {
            if (this.date_second_start == '' || this.date_second_end == '') {
                return
            }
            let payload = {
                date_start : this.date_second_start,
                date_end : this.date_second_end
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/dashboard-v3`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.second_dashboard = data;
                    this.companyTargetsSecond = data.company.percentage;
                    this.crmSpecialistsSecond.push(data.specialist.female);
                    this.crmSpecialistsSecond.push(data.specialist.male);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mounted() {
        $("document").ready(function () {
            $(".dropdown-menu").on("click", function (e) {
                if ($(this).hasClass("compare-data")) {
                    e.stopPropagation();
                }
            });
        });
    },
};
</script>

<style lang="scss" scoped>
.leaderboard {
    padding: 20px 25px;
    border-radius: 1rem;
    box-shadow: 0 1px 5px -2px rgba(141, 145, 162, 0.25);
    background-color: #f5faff;
}
.leaderboard.green {
    background-color: #ebf6ff;
    box-shadow: rgb(41 127 78 / 23%) 0px 4px 5px -1px;
}
.score-position {
    position: absolute;
    right: -10px;
    top: -10px;
}
.score-position.first {
    position: absolute;
    right: -14px;
    top: -14px;
}
.score-position img {
    width: 40px;
}
.score-position.first img {
    width: 50px;
}
.mt-leaderboard {
    margin-top: 80px;
}
.leaderboard-logo {
    width: 80px;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
}
.leaderboard-logo.small {
    width: 60px !important;
    height: 60px !important;
}
.box-dashboard.custom {
    background-color: #f5faff !important;
}
.box-dashboard.red {
    background-color: #feece6 !important;
}
.box-dashboard.gray {
    background-color: #f5faff !important;
}
.dropdown-menu {
    padding: 10px;
    box-shadow: none !important;
    border: 1px solid #d2d2d5 !important;
    border-radius: 10px !important;
    margin-left: 10px !important;
    z-index: 9;
    position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(0px, 43.7037px, 0px);
    min-width: 550px;
}
</style>

<template>
    <div class="mt-4">
        <prospect :items="items" />
    </div>
</template>

<script>
import Prospect from "./Prospect.vue";

export default {
    components: {
        Prospect,
    },
    data() {
        return {
            items: {
                name: "Prospect Next 2 Days",
                prospect_type: "next_2_days",
            },
        };
    },
};
</script>

<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/tags" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4 m-mt-0">
            <h3 class="fw-bold mb-0">Add New Tags</h3>
            <div class="mt-4">
                <label class="mb-2 fw-bold">Tags Name</label>
                <input
                    type="text"
                    class="form-control"
                    placeholder="Tag Name"
                    v-model="req.tag_name"
                />
            </div>
            <div class="mt-4">
                <label class="mb-2 fw-bold">Add Customer Data</label>
                <div class="row">
                    <div class="col-md-4">
                        <button
                            class="btn btn-green w-100"
                            @click="leadsModal = true"
                            :disabled="req.tag_name == ''"
                        >
                            <img
                                src="../../assets/dashboard/plus.png"
                                class="vertical-align-unset me-1"
                                width="10"
                                alt=""
                            />
                            Add Leads
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button
                            class="btn btn-yellow w-100"
                            @click="registeredModal = true"
                            :disabled="req.tag_name == ''"
                        >
                            <img
                                src="../../assets/dashboard/plus.png"
                                class="vertical-align-unset me-1"
                                width="10"
                                alt=""
                            />
                            Add Registered
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button
                            class="btn btn-blue w-100"
                            @click="orderedModal = true"
                            :disabled="req.tag_name == ''"
                        >
                            <img
                                src="../../assets/dashboard/plus.png"
                                class="vertical-align-unset me-1"
                                width="10"
                                alt=""
                            />
                            Add Ordered
                        </button>
                    </div>
                </div>
                <div class="table-responsive-custom">
                    <table
                        class="table table-bordered mt-4"
                        v-if="customers.length > 0"
                    >
                        <thead class="table-light">
                            <tr class="custom">
                                <th class="text-center custom" scope="col">
                                    No
                                </th>
                                <th class="text-center custom" scope="col">
                                    Full Name
                                </th>
                                <th class="text-center custom" scope="col">
                                    Phone
                                </th>
                                <th class="text-center custom" scope="col">
                                    Customers Type
                                </th>
                                <th class="text-center custom" scope="col">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="custom"
                                v-for="(item, index) in customers"
                                :key="index"
                            >
                                <td class="text-center custom">
                                    {{ index + 1 }}
                                </td>
                                <td class="custom">
                                    {{ item.name }}
                                </td>
                                <td class="text-center custom">
                                    {{ item.phone ? item.phone : "-" }}
                                </td>
                                <td class="text-center custom capitalize">
                                    {{ item.type }}
                                </td>
                                <td class="text-center custom">
                                    <a
                                        href="javascript:void(0)"
                                        @click="deleteCustomers(index)"
                                    >
                                        <img
                                            src="../../assets/dashboard/trash-icon.png"
                                            alt=""
                                            width="18"
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex mt-5">
                <div class="ms-auto">
                    <a
                        href="/crm/tags"
                        class="btn btn-outline-black btn-lg px-5 me-2"
                    >
                        Cancel
                    </a>
                    <button
                        class="btn btn-green btn-lg px-5"
                        type="button"
                        disabled
                        v-if="is_save"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Save
                    </button>
                    <button
                        class="btn btn-green btn-lg px-5"
                        :disabled="customers.length == 0"
                        @click="saveTags()"
                        v-else
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div
                class="overlay"
                v-if="leadsModal"
                @click="leadsModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="leadsModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Leads Data</h3>
                </div>
                <div class="position-relative mt-4">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="req.search_leads"
                        @input="changeSearchLeads()"
                        type="text"
                        placeholder="Search Leads"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <template v-if="leads.length > 0">
                    <div
                        :class="{ '': true, 'scroll-tag': leads.length > 5 }"
                        class="mb-2 mt-4"
                    >
                        <div
                            v-for="(data, index) in leads"
                            :key="index"
                            class="checkbox-option fw-medium capitalize"
                        >
                            <label :for="data.id">
                                <input
                                    type="checkbox"
                                    :id="data.id"
                                    :value="data"
                                    v-model="req.customers"
                                />
                                <span class="text mt-1">
                                    <table>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Name
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.name ? data.name : "-"
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Phone
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.phone
                                                        ? data.phone
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-5">
                        <img src="../../assets/no-data.png" width="80" alt="" />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            No Result
                        </h4>
                    </div>
                </template>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="leadsModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            @click="addCustomers()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="orderedModal"
                @click="orderedModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="orderedModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Ordered Data</h3>
                </div>
                <div class="position-relative mt-4">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="req.search_ordered"
                        @input="changeSearchOrdered()"
                        type="text"
                        placeholder="Search Ordered"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <template v-if="ordered.length > 0">
                    <div
                        :class="{ '': true, 'scroll-tag': ordered.length > 5 }"
                        class="mb-2 mt-4"
                    >
                        <div
                            v-for="(data, index) in ordered"
                            :key="index"
                            class="checkbox-option fw-medium capitalize"
                        >
                            <label :for="data.id">
                                <input
                                    type="checkbox"
                                    :id="data.id"
                                    :value="data"
                                    v-model="req.customers"
                                />
                                <span class="text mt-1">
                                    <table>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Name
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.name ? data.name : "-"
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Phone
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.phone
                                                        ? data.phone
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-5">
                        <img src="../../assets/no-data.png" width="80" alt="" />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            No Result
                        </h4>
                    </div>
                </template>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="orderedModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            @click="addCustomers()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div
                class="overlay"
                v-if="registeredModal"
                @click="registeredModal = false"
            ></div>
            <div class="modal-body-chat md" v-if="registeredModal">
                <div class="mb-3">
                    <h3 class="fw-bold">Registered Data</h3>
                </div>
                <div class="position-relative mt-4">
                    <input
                        class="form-control form-control-lg pl-search w-100"
                        v-model="req.search_registered"
                        @input="changeSearchRegistered()"
                        type="text"
                        placeholder="Search Registered"
                    />
                    <span class="search-icon left">
                        <i class="fa fa-search fa-beat fa-sm"></i>
                    </span>
                </div>
                <template v-if="registered.length > 0">
                    <div
                        :class="{
                            '': true,
                            'scroll-tag': registered.length > 5,
                        }"
                        class="mb-2 mt-4"
                    >
                        <div
                            v-for="(data, index) in registered"
                            :key="index"
                            class="checkbox-option fw-medium capitalize"
                        >
                            <label :for="data.id">
                                <input
                                    type="checkbox"
                                    :id="data.id"
                                    :value="data"
                                    v-model="req.customers"
                                />
                                <span class="text mt-1">
                                    <table>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Name
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.name ? data.name : "-"
                                                }}
                                            </td>
                                        </tr>
                                        <tr class="p-0">
                                            <td class="p-0" style="width: 70px">
                                                Phone
                                            </td>
                                            <td class="p-0" style="width: 20px">
                                                :
                                            </td>
                                            <td class="p-0">
                                                {{
                                                    data.phone
                                                        ? data.phone
                                                        : "-"
                                                }}
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </label>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-5">
                        <img src="../../assets/no-data.png" width="80" alt="" />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            No Result
                        </h4>
                    </div>
                </template>
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black w-100 btn-lg"
                            @click="registeredModal = false"
                        >
                            Cancel
                        </button>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-green w-100 btn-lg"
                            @click="addCustomers()"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalDeleteCustomer"></div>
            <div class="modal-body-chat vsm" v-if="modalDeleteCustomer">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/trash.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Delete Data?</h4>
                    <div class="mt-2">
                        Are You Sure You Want to Delete This Data?
                    </div>
                </div>
                <div class="mt-4 row">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="modalDeleteCustomer = false"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Cancel
                        </a>
                    </div>
                    <div class="col-md-6">
                        <button
                            @click="modalDeleteCustomer = false"
                            class="btn btn-outline-primary w-100 btn-lg"
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Congratulation</h4>
                    <div class="mt-2">Your Tags is Created!</div>
                </div>
                <div class="mt-4">
                    <a href="/crm/tags" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
// import moment from "moment";

export default {
    components: {},
    name: "TagsCreate",
    data() {
        return {
            req: {
                search_leads: "",
                search_registered: "",
                search_ordered: "",
                customers: [],
                tag_name: "",
            },
            per_page_leads: 10,
            currentPageLeads: 0,
            per_page_ordered: 10,
            currentPageOrdered: 0,
            per_page_registered: 10,
            currentPageRegistered: 0,
            leads: [],
            leadsModal: false,
            ordered: [],
            orderedModal: false,
            registered: [],
            registeredModal: false,
            customers: [],
            newCustomers: [],
            delete_value: null,
            modalDeleteCustomer: false,
            is_save: false,
            modalSuccess: false,
        };
    },
    created() {
        this.getListLeads();
        this.getListOrdered();
        this.getListRegistered();
    },
    methods: {
        getListLeads() {
            let data = {
                search: this.req.search_leads,
                limit: this.per_page_leads,
                page:
                    this.currentPageLeads == 0 || this.currentPageLeads == null
                        ? 0
                        : this.currentPageLeads - 0,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/leads`, {
                params: data,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data.list_data.map(function (key) {
                        key.type = "leads";
                        return key;
                    });
                    this.leads = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeSearchLeads() {
            this.getListLeads();
        },
        getListOrdered() {
            let data = {
                type: "ordered",
                search: this.req.search_ordered,
                limit: this.per_page_ordered,
                page:
                    this.currentPageOrdered == 0 ||
                    this.currentPageOrdered == null
                        ? 0
                        : this.currentPageOrdered - 1,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/orders`, {
                params: data,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data.list_data.map(function (key) {
                        key.type = "ordered";
                        return key;
                    });
                    this.ordered = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeSearchOrdered() {
            this.getListOrdered();
        },
        getListRegistered() {
            let data = {
                type: "registered",
                search: this.req.search_registered,
                limit: this.per_page_registered,
                page:
                    this.currentPageRegistered == 0 ||
                    this.currentPageRegistered == null
                        ? 0
                        : this.currentPageRegistered - 1,
            };
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/customer/orders`, {
                params: data,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data.list_data.map(function (key) {
                        key.type = "registered";
                        return key;
                    });
                    this.registered = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        changeSearchRegistered() {
            this.getListRegistered();
        },
        addCustomers() {
            this.req.customers.forEach((item) => {
                this.customers.push({
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    type: item.type,
                });
                const uniqueItems = Array.from(
                    new Set(this.customers.map((item) => item.id))
                ).map((id) => this.customers.find((item) => item.id === id));
                this.customers = uniqueItems;
            });
            this.leadsModal = false;
            this.registeredModal = false;
            this.orderedModal = false;
        },
        deleteCustomers(index) {
            this.$notify({
                group: "foo",
                type: "success",
                title: "Success",
                text: "Data is Deleted",
            });
            setTimeout(() => {
                this.customers.splice(index, 1);
                this.req.customers.splice(index, 1);
                this.getListLeads();
                this.getListOrdered();
                this.getListRegistered();
            }, 1000);
        },
        saveTags() {
            this.is_save = true;
            var data = {
                tag_name: this.req.tag_name,
                customers: this.customers,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/tags/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.modalSuccess = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
th.custom {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr.custom {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td.custom {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.checkbox-option label {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}
.checkbox-option label .text {
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #f3f4f6;
    padding-bottom: 15px;
}
.checkbox-option label input {
    display: none;
}
.checkbox-option label input + .text:after {
    font-family: FontAwesome;
    content: "\f096";
    line-height: 1;
    display: inline-block;
    color: #d9d9d9;
    font-size: 20px;
}
.checkbox-option label input:checked + .text:after {
    font-family: FontAwesome;
    content: "\f046";
    color: #3d8824;
    font-size: 20px;
}
.vertical-align-unset {
    vertical-align: unset;
}
.scroll-tag {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 320px;
}
</style>

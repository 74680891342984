<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="back()"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box p-0 mt-4">
            <div class="box no-shadow bc-gray p-4 no-radius-bottom">
                <h5 class="mb-0 fw-bold">Jurnal Syukur</h5>
            </div>
            <div class="row p-4">
                <div class="col-md-2">
                    <div class="d-flex m-bold">
                        Name
                        <span class="ms-2 me-2 d-block d-lg-none">:</span>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="d-flex">
                        <span class="me-4 d-none d-lg-block">:</span>
                        {{ data.name }}
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="d-flex m-bold">
                        Date
                        <span class="ms-2 me-2 d-block d-lg-none">:</span>
                    </div>
                </div>
                <div class="col-md-10 mt-2">
                    <div class="d-flex">
                        <span class="me-4 d-none d-lg-block">:</span>
                        {{ moment(data.date).format("DD MMMM YYYY") }}
                    </div>
                </div>
                <div class="col-md-2 mt-2">
                    <div class="d-flex m-bold">
                        Jurnal Syukur
                        <span class="d-block d-lg-none ms-2 me-2">:</span>
                    </div>
                </div>
                <div class="col-md-10 mt-2">
                    <div class="d-flex">
                        <div class="me-4 d-none d-lg-block">:</div>
                        <div v-if="data.gratitude.length > 0">
                            <template v-for="(item, index) in data.gratitude">
                                <div :key="index">
                                    {{ item.content }}
                                </div>
                            </template>
                        </div>
                        <span class="text-danger" v-else>
                            Not Filled Out Of Jurnal Syukur!
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <textarea
                class="form-control"
                cols="30"
                rows="3"
                placeholder="Write a comment"
                v-model="req.comment"
            ></textarea>
            <div class="mt-3 row">
                <div class="col-md-2">
                    <button
                        class="btn btn-green btn-lg w-100"
                        type="button"
                        disabled
                        v-if="is_comment"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Post
                    </button>
                    <button
                        class="btn btn-green btn-lg w-100"
                        @click="postComment()"
                        :disabled="req.comment == ''"
                        v-else
                    >
                        Post
                    </button>
                </div>
            </div>
        </div>

        <div class="box mt-4 pb-0" v-if="comments.length > 0">
            <template v-for="(comment, index) in comments">
                <div class="d-flex pb-comment" :key="index">
                    <div
                        class="user-profile-comment me-3"
                        :style="{
                            'background-image': 'url(' + comment.avatar + ')',
                        }"
                        v-if="comment.avatar"
                    ></div>
                    <div
                        class="user-profile-comment me-3"
                        :style="{
                            'background-image':
                                'url(' + require('../../assets/user.jpg') + ')',
                        }"
                        v-else
                    ></div>
                    <div class="w-100">
                        <div class="d-flex align-items-center no-flex">
                            <div class="size-16 fw-bold me-4 m-size-14">
                                {{ comment.name }}
                            </div>
                            <div class="text-gray solid m-size-12">
                                {{ comment.date }}
                            </div>
                            <div
                                class="ms-auto"
                                v-if="comment.user_id == user_id"
                            >
                                <div class="dropdown">
                                    <a
                                        class="dropdown-toggle"
                                        href="javascript:void(0)"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i
                                            class="fa fa-ellipsis-h size-20 m-size-14 text-dark"
                                        ></i>
                                    </a>
                                    <ul
                                        class="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                    >
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                href="javascript:void(0)"
                                                @click="editComment(comment)"
                                                >Edit</a
                                            >
                                        </li>
                                        <li>
                                            <a
                                                class="dropdown-item"
                                                href="javascript:void(0)"
                                                @click="
                                                    deleteComment(comment.id)
                                                "
                                                >Hapus</a
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span v-if="!comment.edit" class="m-size-13">
                                {{ comment.comment }}
                            </span>
                            <template v-else>
                                <textarea
                                    cols="30"
                                    rows="2"
                                    class="form-control mt-1"
                                    placeholder="Write a comment"
                                    v-model="comment.comment"
                                ></textarea>
                                <div class="mt-2">
                                    <a
                                        href="javascript:void(0)"
                                        @click="updateComment(comment)"
                                        class="text-green me-2"
                                    >
                                        Save
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        @click="cancelComment(comment)"
                                        class="text-danger"
                                    >
                                        Cancel
                                    </a>
                                </div>
                            </template>
                        </div>
                        <div class="mt-2" v-if="!comment.edit">
                            <a
                                href="javascript:void(0)"
                                @click="showCommentReply(comment.id)"
                            >
                                <u> Reply </u>
                            </a>
                        </div>

                        <template v-if="comment.reply.length > 0">
                            <template v-for="(reply, index) in comment.reply">
                                <div class="d-flex mt-4" :key="index">
                                    <div
                                        class="user-profile-comment me-3"
                                        :style="{
                                            'background-image':
                                                'url(' + reply.avatar + ')',
                                        }"
                                        v-if="reply.avatar"
                                    ></div>
                                    <div
                                        class="user-profile-comment me-3"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/user.jpg') +
                                                ')',
                                        }"
                                        v-else
                                    ></div>
                                    <div class="w-100">
                                        <div
                                            class="d-flex align-items-center no-flex"
                                        >
                                            <div
                                                class="size-16 m-size-14 fw-bold me-3"
                                            >
                                                {{ reply.name }}
                                            </div>
                                            <div
                                                class="text-gray solid m-size-12"
                                            >
                                                {{ reply.date }}
                                            </div>
                                            <div
                                                class="ms-auto"
                                                v-if="reply.user_id == user_id"
                                            >
                                                <div class="dropdown">
                                                    <a
                                                        class="dropdown-toggle"
                                                        href="#"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <i
                                                            class="fa fa-ellipsis-h size-20 m-size-14 text-dark"
                                                        ></i>
                                                    </a>
                                                    <ul
                                                        class="dropdown-menu"
                                                        aria-labelledby="dropdownMenuLink"
                                                    >
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    editReply(
                                                                        reply
                                                                    )
                                                                "
                                                                >Edit</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                href="javascript:void(0)"
                                                                @click="
                                                                    deleteReply(
                                                                        reply
                                                                    )
                                                                "
                                                                >Hapus</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <span
                                                v-if="!reply.edit"
                                                class="m-size-13"
                                            >
                                                {{ reply.comment }}
                                            </span>
                                            <template v-else>
                                                <textarea
                                                    cols="30"
                                                    rows="2"
                                                    class="form-control mt-1"
                                                    placeholder="Write a comment"
                                                    v-model="reply.comment"
                                                ></textarea>
                                                <div class="mt-2">
                                                    <a
                                                        href="javascript:void(0)"
                                                        @click="
                                                            updateReply(reply)
                                                        "
                                                        class="text-green me-2"
                                                    >
                                                        Save
                                                    </a>
                                                    <a
                                                        href="javascript:void(0)"
                                                        @click="
                                                            cancelReply(reply)
                                                        "
                                                        class="text-danger"
                                                    >
                                                        Cancel
                                                    </a>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>

                        <template v-if="comment.id == reply_id">
                            <div class="mt-4">
                                <textarea
                                    cols="30"
                                    rows="3"
                                    class="form-control"
                                    placeholder="Write a comment"
                                    v-model="req.comment_reply"
                                ></textarea>
                            </div>
                            <div class="mt-3 d-flex">
                                <div class="me-3">
                                    <button
                                        class="btn btn-outline-black btn-md px-4"
                                        @click="reply_id = null"
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button
                                        class="btn btn-green btn-md px-4"
                                        type="button"
                                        disabled
                                        v-if="is_comment_reply"
                                    >
                                        <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        Post
                                    </button>
                                    <button
                                        class="btn btn-green btn-md px-4"
                                        @click="replyComment(comment)"
                                        v-else
                                    >
                                        Post
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";

export default {
    components: {},
    name: "GratitudeJournal",
    data() {
        return {
            moment: moment,
            data: {
                gratitude: [],
            },
            req: {
                comment: "",
                comment_reply: "",
            },
            is_comment: false,
            is_comment_reply: false,
            user_id: null,
            comments: [],
            is_reply: false,
            reply_id: null,
        };
    },
    created() {
        this.getList();
        this.getCommentList();
        this.getUserLogin();
    },
    methods: {
        back() {
            window.history.go(-1);
        },
        getList() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/detail`,
                {
                    params: {
                        journal_id: this.$route.params.id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.data = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getCommentList() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist/comment`,
                {
                    params: {
                        journal_id: this.$route.params.id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data.map(function (key) {
                        key.edit = false;
                        key.reply = key.reply.map(function (val) {
                            val.edit = false;
                            return val;
                        });
                        return key;
                    });
                    this.comments = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        postComment() {
            this.is_comment = true;
            var data = {
                journal_id: this.data.id,
                message: this.req.comment,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist/comment/create`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.getCommentList();
                    this.req.comment = "";
                    this.is_comment = false;
                    this.reply_id = null;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: res.data.data.message,
                    });
                })
                .catch((err) => {
                    this.is_comment = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        deleteComment(id) {
            var data = {
                comment_id: id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist/comment/remove`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.getCommentList();
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: res.data.data.message,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.user_id = res.data.data.hash;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        editComment(comment) {
            this._originalComment = Object.assign({}, comment);
            comment.edit = true;
            this.reply_id = null;
        },
        cancelComment(comment) {
            Object.assign(comment, this._originalComment);
            comment.edit = false;
        },
        updateComment(comment) {
            var data = {
                comment_id: comment.id,
                message: comment.comment,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist/comment`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getCommentList();
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: res.data.data.message,
                    });
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        showCommentReply(id) {
            this.reply_id = id;
            this.req.comment_reply = "";
        },
        replyComment(comment) {
            this.is_comment_reply = true;
            var data = {
                comment_id: comment.id,
                message: this.req.comment_reply,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist/comment/reply`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getCommentList();
                    this.req.comment_reply = "";
                    this.is_reply = false;
                    this.is_comment_reply = false;
                    this.reply_id = null;
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: res.data.data.message,
                    });
                })
                .catch((err) => {
                    this.is_comment_reply = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        deleteReply(reply) {
            var data = {
                comment_id: reply.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist/comment/remove`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.getCommentList();
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: res.data.data.message,
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editReply(reply) {
            this._originalReply = Object.assign({}, reply);
            reply.edit = true;
            this.reply_id = null;
        },
        cancelReply(reply) {
            Object.assign(reply, this._originalReply);
            reply.edit = false;
        },
        updateReply(reply) {
            var data = {
                comment_id: reply.id,
                message: reply.comment,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist/comment`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getCommentList();
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: res.data.data.message,
                    });
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.user-profile-comment {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    border: none;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
}
.pb-comment {
    padding-bottom: 25px;
}
@media (max-width: 576px) {
    .user-profile-comment {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        object-fit: cover;
        /* background-position: center;
            background-repeat: no-repeat;
            background-size: cover; */
    }
}
</style>

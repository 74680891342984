<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Detail Ordered History</h3>
            </div>
            <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                <tr class="">
                    <td class="ps-0 w-200">Resi</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            detailHistory?.receipt_code
                                ? detailHistory?.receipt_code
                                : "-"
                        }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Date</td>
                    <td class="w-30">:</td>
                    <td>
                        {{ moment(detailHistory.date).format("DD/MM/YYYY") }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Price</td>
                    <td class="w-30">:</td>
                    <td>{{ currencyFormat(detailHistory?.amount) }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Route</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            detailHistory?.origin_regency
                                ? detailHistory?.origin_regency
                                : "-"
                        }}
                        -
                        {{
                            detailHistory?.destination_regency
                                ? detailHistory?.destination_regency
                                : "-"
                        }}
                    </td>
                </tr>
                <tr
                    class="border-top"
                    v-if="detailHistory.product == 'trawlpack'"
                >
                    <td class="ps-0 w-200">Weight</td>
                    <td class="w-30">:</td>
                    <td>{{ detailHistory.weight }}kg</td>
                </tr>
                <tr
                    class="border-top"
                    v-if="detailHistory.product == 'trawlpack'"
                >
                    <td class="ps-0 w-200">Packing</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            detailHistory.handling
                                ? detailHistory.handling
                                : "-"
                        }}
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Assurance</td>
                    <td class="w-30">:</td>
                    <td>{{ currencyFormat(detailHistory.assurance) }}</td>
                </tr>
                <tr
                    class="border-top"
                    v-if="detailHistory.product == 'trawlpack'"
                >
                    <td class="ps-0 w-200">Order</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            detailHistory.service_type
                                ? detailHistory.service_type
                                : "-"
                        }}
                    </td>
                </tr>
                <tr
                    class="border-top"
                    v-if="detailHistory.product == 'trawlpack'"
                >
                    <td class="ps-0 w-200">Item Type</td>
                    <td class="w-30">:</td>
                    <td>{{ detailHistory.item ? detailHistory.item : "-" }}</td>
                </tr>
                <tr
                    class="border-top"
                    v-if="
                        detailHistory.product == 'sewa-truck' ||
                        detailHistory.product == 'kirim-mobil'
                    "
                >
                    <td class="ps-0 w-200">Service</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        {{
                            (detailHistory.service_type
                                ? detailHistory.service_type
                                : "-"
                            )
                                .split("_")
                                .join(" ")
                        }}
                    </td>
                </tr>
                <tr
                    class="border-top"
                    v-if="detailHistory.product == 'sewa-truck'"
                >
                    <td class="ps-0 w-200">Armada</td>
                    <td class="w-30">:</td>
                    <td class="capitalize">
                        {{
                            (detailHistory.fleet_type
                                ? detailHistory.fleet_type
                                : "-"
                            )
                                .split("_")
                                .join(" ")
                        }}
                    </td>
                </tr>
                <tr
                    class="border-top"
                    v-if="detailHistory.product == 'kirim-mobil'"
                >
                    <td class="ps-0 w-200">Car Type</td>
                    <td class="w-30">:</td>
                    <td>
                        {{
                            detailHistory.car_desc
                                ? detailHistory.car_desc
                                : "-"
                        }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";

export default {
    name: "customersOrderedHistory",
    components: {},
    data() {
        return {
            moment: moment,
            detailHistory: {},
        };
    },
    created() {
        this.getDetailHistory();
    },
    computed: {},
    methods: {
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getDetailHistory() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/order/receipt`, {
                params: { receipt_code: this.$route.params.id },
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.detailHistory = res.data?.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped></style>

<template>
    <div
        class="box-dashboard w-100 mt-4"
        v-bind:class="{
            'box-active': first_dashboard?.company != null,
        }"
    >
        <h5 class="fw-semibold">Data Pelanggan</h5>
        <!-- Default Data -->
        <template v-if="first_dashboard?.company == null">
            <div class="row">
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/leads.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Leads
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            default_dashboard?.customer
                                                ?.leads ?? 0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/prospect-new.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Prospek
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            default_dashboard?.customer
                                                ?.prospect ?? 0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/repeat-order.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Repeat Order
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            default_dashboard?.customer
                                                ?.repeat_order ?? 0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- End Default Data -->

        <!-- Compare Data -->
        <template v-if="first_dashboard?.company != null">
            <div class="text-gray-second">
                {{ compare_data_first }}
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/leads.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Leads
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            first_dashboard?.customer?.leads ??
                                                0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/prospect-new.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Prospek
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            first_dashboard?.customer
                                                ?.prospect ?? 0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/repeat-order.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Repeat Order
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            first_dashboard?.customer
                                                ?.repeat_order ?? 0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mt-4" />
            <div class="text-gray-second">
                {{ compare_data_second }}
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/leads.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Leads
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            second_dashboard?.customer?.leads ??
                                                0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/prospect-new.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Prospek
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            second_dashboard?.customer
                                                ?.prospect ?? 0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="box-dashboard gray mt-3">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    src="../../assets/repeat-order.png"
                                    width="60"
                                    alt=""
                                />
                            </div>
                            <div>
                                <div class="text-gray-second size-16">
                                    Repeat Order
                                </div>
                                <div class="size-24 fw-bold text-gray-bold">
                                    {{
                                        decimalFormat(
                                            second_dashboard?.customer
                                                ?.repeat_order ?? 0
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- End Compare Data -->
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {},
    name: "CustomerData",
    props: [
        "default_dashboard",
        "first_dashboard",
        "second_dashboard",
        "compare_data_first",
        "compare_data_second",
    ],
    data() {
        return {
            moment: moment,
        };
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
@keyframes color-mix {
    0% {
        box-shadow: #c1ebd3 0px 1px 3px 0px;
    }
    100% {
        box-shadow: #37a968 0px 1px 3px 0px;
    }
}
.box-active {
    animation: color-mix 1s linear infinite alternate;
    box-shadow: 0 1px 12px -1px currentColor;
}
</style>

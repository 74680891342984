<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="back()"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box p-0 mt-4">
            <div class="box no-shadow bc-gray p-4 no-radius-bottom">
                <h5 class="mb-0 fw-bold">Create Jurnal Syukur</h5>
            </div>
            <div class="p-4">
                <div class="box p-head-title no-shadow bg-blue-thin mb-4">
                    <div class="fw-bold size-18">Afirmasi</div>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Afirmasi 1</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Afirmasi 1"
                        v-model="gratitudes[0].content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Afirmasi 2</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Afirmasi 2"
                        v-model="gratitudes[1].content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Afirmasi 3</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Afirmasi 3"
                        v-model="gratitudes[2].content"
                    ></textarea>
                </div>
                <div class="box p-head-title no-shadow bg-blue-thin mt-5 mb-4">
                    <div class="fw-bold size-18">Syukur</div>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Syukur 1</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Syukur 1"
                        v-model="gratitudes[3].content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Syukur 2</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Syukur 2"
                        v-model="gratitudes[4].content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Syukur 3</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Syukur 3"
                        v-model="gratitudes[5].content"
                    ></textarea>
                </div>
                <div class="box p-head-title no-shadow bg-blue-thin mt-5 mb-4">
                    <div class="fw-bold size-18">Self Talk</div>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Self Talk 1</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Self Talk 1"
                        v-model="gratitudes[6].content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Self Talk 2</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Self Talk 2"
                        v-model="gratitudes[7].content"
                    ></textarea>
                </div>
                <div class="mt-3">
                    <label class="mb-0 fw-bold">Self Talk 3</label>
                    <textarea
                        cols="30"
                        rows="3"
                        class="form-control mt-2"
                        placeholder="Enter Self Talk 3"
                        v-model="gratitudes[8].content"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <button
                            class="btn btn-outline-black btn-lg w-100"
                            @click="back()"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            class="btn btn-green btn-lg w-100"
                            type="button"
                            disabled
                            v-if="is_store"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Save
                        </button>
                        <button
                            class="btn btn-green btn-lg w-100"
                            @click="storeData()"
                            v-else
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        :href="'/crm/gratitude-journal/user/' + user_id"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";

export default {
    components: {},
    name: "GratitudeJournalCreate",
    data() {
        return {
            modalSuccess: false,
            gratitudes: [
                {
                    type: "affirmation",
                    content: "",
                },
                {
                    type: "affirmation",
                    content: "",
                },
                {
                    type: "affirmation",
                    content: "",
                },
                {
                    type: "gratitude",
                    content: "",
                },
                {
                    type: "gratitude",
                    content: "",
                },
                {
                    type: "gratitude",
                    content: "",
                },
                {
                    type: "self_talk",
                    content: "",
                },
                {
                    type: "self_talk",
                    content: "",
                },
                {
                    type: "self_talk",
                    content: "",
                },
            ],
            is_store: false,
            roles: [],
            user_id: null,
        };
    },
    created() {
        this.getUserLogin();
    },
    methods: {
        back() {
            window.history.go(-1);
        },
        storeData() {
            if (this.gratitudes[0].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Afirmasi 1 is Required",
                });
                return;
            } else if (this.gratitudes[1].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Afirmasi 2 is Required",
                });
                return;
            } else if (this.gratitudes[2].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Afirmasi 3 is Required",
                });
                return;
            } else if (this.gratitudes[3].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Styukur 1 is Required",
                });
                return;
            } else if (this.gratitudes[4].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Styukur 2 is Required",
                });
                return;
            } else if (this.gratitudes[5].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Styukur 3 is Required",
                });
                return;
            } else if (this.gratitudes[6].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Self Talk 1 is Required",
                });
                return;
            } else if (this.gratitudes[7].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Self Talk 2 is Required",
                });
                return;
            } else if (this.gratitudes[8].content == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Self Talk 3 is Required",
                });
                return;
            } else {
                if (
                    this.gratitudes[0].content.length <= 3 ||
                    this.gratitudes[1].content.length <= 3 ||
                    this.gratitudes[2].content.length <= 3 ||
                    this.gratitudes[3].content.length <= 3 ||
                    this.gratitudes[4].content.length <= 3 ||
                    this.gratitudes[5].content.length <= 3 ||
                    this.gratitudes[6].content.length <= 3 ||
                    this.gratitudes[7].content.length <= 3 ||
                    this.gratitudes[8].content.length <= 3
                ) {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: "Must be 3 Characters",
                    });
                    return;
                } else {
                    this.is_store = true;
                    var data = {
                        gratitudes: this.gratitudes,
                    };
                    Api.post(
                        `${process.env.VUE_APP_SERVICE_URL}/crm/gratitude/create`,
                        data,
                        {
                            headers: {
                                Accept: "application/json",
                                Authorization:
                                    "Bearer" + localStorage.getItem("token"),
                            },
                        }
                    )
                        .then((res) => {
                            this.modalSuccess = true;
                            console.log(res);
                            this.is_store = false;
                        })
                        .catch((err) => {
                            this.is_store = false;
                            this.$notify({
                                group: "foo",
                                type: "error",
                                title: "Error",
                                text: err.response.data.message,
                            });
                        });
                }
            }
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                    this.user_id = res.data.data.hash;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.bg-blue-thin {
    background: #daecf6;
}
.p-head-title {
    padding: 8px 18px 8px 18px !important;
}
</style>

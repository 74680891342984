<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/specialist" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0" v-if="data != ''">
                    Edit CRM Specialist
                </h3>
                <h3 class="fw-bold mb-0" v-else>Add CRM Specialist</h3>
            </div>
            <div class="row mt-5">
                <div
                    class="col-md-12 mb-3"
                    v-if="data == '' && isRole(managerRole)"
                >
                    <label class="fw-bold mb-2">Branch Name</label>
                    <multiselect
                        v-model="branch"
                        :options="branchs"
                        label="name"
                        track-by="id"
                        placeholder="Select Branch"
                        class="multi-form-custom"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Full Name</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Full Name"
                        v-model="req.name"
                    />
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-2">Gender</label>
                    <select
                        class="form-control form-select"
                        v-model="req.gender"
                    >
                        <option value="" disabled selected hidden>
                            Choose Gender
                        </option>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Role</label>
                    <select class="form-control form-select" v-model="req.role">
                        <option value="" disabled selected hidden>Role</option>
                        <option value="crm-specialist-cs">CS</option>
                        <option value="crm-specialist-sales">Sales</option>
                        <option value="crm-specialist-telemarketer">
                            Telemarketer
                        </option>
                        <option value="crm-specialist-cro">
                            CRO
                        </option>
                        <option value="crm-manager">Manager</option>
                        <!-- <option value="crm-bd">Business Development</option> -->
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Phone Number</label>
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Phone Number"
                        v-model="req.phone"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        v-model="req.email"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Password</label>
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control"
                            id="password"
                            placeholder="Password"
                            v-model="req.password"
                            v-if="showPassword"
                        />
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            placeholder="Password"
                            v-model="req.password"
                            v-else
                        />
                        <span
                            class="search-icon cursor-pointer"
                            @click="toggleShow"
                        >
                            <i
                                class="fa"
                                :class="{
                                    'fa-eye': showPassword,
                                    'fa-eye-slash': !showPassword,
                                }"
                            ></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Address</label>
                    <textarea
                        rows="5"
                        cols="4"
                        class="form-control"
                        placeholder="Address"
                        v-model="req.address"
                    ></textarea>
                </div>
                <div class="col-md-4 mt-4">
                    <label class="fw-bold">Pas Photo</label>
                    <div class="text-gray mb-2">Pas photo max 5MB</div>
                    <label
                        type="file"
                        :class="!imagePhoto ? 'upload__btn' : ''"
                    >
                        <img
                            v-if="imagePhoto"
                            class="photo"
                            :src="driverPictureUrl"
                        />
                        <p v-else class="mb-0" style="color: #adb5bd">
                            <i class="fa fa-plus"></i>
                        </p>
                        <input
                            class="cursor-pointer"
                            id="upload"
                            type="file"
                            name="file"
                            ref="file"
                            @change="uploadPhoto"
                        />
                    </label>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-4">
            <div class="d-flex">
                <div class="ms-auto">
                    <a
                        href="/crm/customers/leads"
                        class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                        >Cancel</a
                    >
                    <button
                        class="btn btn-green btn-lg px-5 me-3 m-mt-1 m-w-100"
                        type="button"
                        @click="setStore()"
                        :disabled="is_save"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            v-if="is_save"
                        ></span>
                        Save
                    </button>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a
                        href="/crm/specialist"
                        class="btn btn-green w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import noImage from "../../assets/no-photo.png";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            showPassword: false,
            imagePhoto: "",
            title: "Add CRM Specialist",
            data: "",
            req: {
                name: "",
                email: "",
                phone: "",
                password: "",
                gender: "",
                role: "",
                address: "",
                status: "active",
                photo: "",
            },
            modalSuccess: false,
            modalError: false,
            banks: [],
            is_save: false,
            branch: null,
            branchs: [],
            roles: [],
            managerRole: ["admin-crm"],
        };
    },
    created() {
        if (this.$route.params.id) {
            this.getData();
            this.title = "Edit CRM Specialist";
        }
        this.getBranchs();
        this.getUserLogin();
    },
    methods: {
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/account/user/me`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.roles = res.data.data.roles;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        setStore() {
            if (this.$route.params.id) {
                return this.updateData();
            }
            this.storeData();
        },
        uploadPhoto(event) {
            var input = event.target;
            this.req.photo = input.files[0];
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePhoto = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
            }
        },
        getData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist`,
                {
                    params: { id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.req.name = this.data.name;
                    this.req.gender = this.data.gender;
                    if (this.data.role == "Manager")
                        this.req.role = "crm-manager";
                    else if (this.data.role == "CS")
                        this.req.role = "crm-specialist-cs";
                    else if (this.data.role == "Sales")
                        this.req.role = "crm-specialist-sales";
                    else if (this.data.role == "Telemarketer")
                        this.req.role = "crm-specialist-telemarketer";
                    else if (this.data.role == "Business Development")
                        this.req.role = "crm-bd";
                    this.req.phone = Number(this.data.phone);
                    this.req.email = this.data.email;
                    this.req.status = this.data.is_active;
                    this.req.address = this.data.address;
                    this.imagePhoto = this.data.avatar;
                    // this.branch.id = this.data.branch_id;
                    // this.branch.name = this.data.branch_name;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                });
        },
        storeData() {
            var data = new FormData();

            data.append("name", this.req.name);
            data.append("gender", this.req.gender);
            data.append("role", this.req.role);
            data.append("phone", this.req.phone);
            data.append("email", this.req.email);
            data.append("password", this.req.password);
            data.append("is_active", this.req.status);
            data.append("address", this.req.address);
            data.append("branch_id", this.branch?.id);
            if (this.req.photo) {
                data.append("avatar", this.req.photo);
            }

            this.is_save = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/create`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    console.log(res);

                    if (res.status != 200) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: res.data.message,
                        });
                    } else {
                        this.modalSuccess = true;
                    }
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        updateData() {
            var data = new FormData();

            data.append("id", this.$route.params.id);
            data.append("name", this.req.name);
            data.append("gender", this.req.gender);
            data.append("role", this.req.role);
            data.append("phone", this.req.phone);
            data.append("email", this.req.email);
            data.append("password", this.req.password);
            data.append("is_active", this.req.status);
            data.append("address", this.req.address);
            // data.append("branch_id", this.branch?.id);
            if (this.req.photo) {
                data.append("avatar", this.req.photo);
            }

            this.is_save = true;

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    console.log(res);

                    if (res.status != 200) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: res.data.message,
                        });
                    } else {
                        this.modalSuccess = true;
                    }
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getBranchs() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/partnership/branches/dropdown`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.branchs = res.data.data;
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    computed: {
        driverPictureUrl() {
            return this.imagePhoto.length > 0 ? this.imagePhoto : noImage;
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
    border: dashed;
    color: #dee2e6;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>

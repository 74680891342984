<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/crm/gratitude-journal"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">
                    Detail Jurnal Syukur of {{ data?.list_data[0]?.name }}
                </h3>
                <div class="ms-auto">
                    <a
                        href="/crm/gratitude-journal/create"
                        class="btn btn-primary btn-lg px-5 me-2 m-mt-1 m-w-100"
                    >
                        Create Jurnal Syukur
                    </a>
                </div>
            </div>
            <div class="row mt-4">
                <!-- <div class="col-md-5">
                    <div class="position-relative">
                        <input type="text" class="form-control form-control-lg" placeholder="Search" v-model="req.search" @input="changeSearch()" />
                        <span class="search-icon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <div class="mt-4" v-if="is_list_ready">
                        <ListLoader />
                    </div>
                    <template v-else>
                        <template v-if="data.list_data.length > 0">
                            <div class="table-responsive-custom">
                                <table class="table table-bordered mt-4">
                                    <thead class="table-light">
                                        <tr>
                                            <th class="text-center" scope="col">
                                                No
                                            </th>
                                            <th class="text-center" scope="col">
                                                Date
                                            </th>
                                            <th class="text-center" scope="col">
                                                Jurnal Syukur
                                            </th>
                                            <th
                                                class="text-center"
                                                scope="col"
                                                style="width: 180px"
                                            >
                                                Status
                                            </th>
                                            <th class="text-center" scope="col">
                                                Penalty
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template
                                            v-for="(
                                                item, index
                                            ) in data.list_data"
                                        >
                                            <tr :key="index">
                                                <td class="text-center">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="text-center">
                                                    {{
                                                        moment(
                                                            item.date
                                                        ).format("DD/MM/YYYY")
                                                    }}
                                                </td>
                                                <td style="width: 200px">
                                                    <a
                                                        :href="
                                                            '/crm/gratitude-journal/user/detail/' +
                                                            item.id
                                                        "
                                                    >
                                                        <u>
                                                            Detail Jurnal Syukur
                                                        </u>
                                                    </a>
                                                </td>
                                                <td class="text-center">
                                                    <template
                                                        v-if="
                                                            item.status ==
                                                            'done'
                                                        "
                                                    >
                                                        <div
                                                            class="tag green-solid w-100"
                                                        >
                                                            Finish
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div
                                                            class="tag red-solid w-100"
                                                        >
                                                            Unfinished
                                                        </div>
                                                    </template>
                                                </td>
                                                <td class="text-center">
                                                    {{
                                                        currencyFormat(
                                                            item.penalty
                                                        )
                                                    }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="text-center mt-section">
                                <img
                                    src="../../assets/no-data.png"
                                    width="100"
                                    alt=""
                                />
                                <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                    Data Kosong
                                </h4>
                            </div>
                        </template>
                    </template>
                    <div class="mt-4">
                        <b-pagination-nav
                            v-if="data.list_data.length > 0"
                            :link-gen="linkGen"
                            v-model="currentPage"
                            :number-of-pages="data.total_page"
                            base-url="#"
                            first-number
                            align="right"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "GratitudeJournal",
    data() {
        return {
            moment: moment,
            is_list_ready: false,
            data: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
        };
    },
    created() {
        this.getList();
    },
    methods: {
        changeSearch() {
            this.currentPageOrder = 0;
            this.getList();
        },
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getList() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/gratitudes/specialist`,
                {
                    params: {
                        specialist_id: this.$route.params.id,
                        // search: this.req.search,
                        per_page: this.per_page,
                        page: this.$route.query.page,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.data = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #3782e6;
}
.search-icon {
    font-size: 16px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper chiller-theme",class:!_vm.isMobile ? 'toggled' : ''},[_c('notifications',{attrs:{"group":"foo","position":"top right"}}),_vm._m(0),_c('nav',{staticClass:"sidebar-wrapper",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-content"},[_vm._m(1),_c('div',{staticClass:"sidebar-header"},[_c('div',{},[_c('center',[_c('div',[_c('img',{attrs:{"src":require("../assets/logo.webp"),"width":"70","alt":""}})]),_c('div',{staticClass:"mt-3 fw-bold size-16"},[_vm._v(" "+_vm._s(_vm.appName)+" ")])])],1)]),_c('hr',{staticClass:"hr-horizontal mb-3"}),_c('div',{staticClass:"sidebar-menu"},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmNonSpecialist)),expression:"isRole(crmNonSpecialist)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active: this.$route.name == 'dashboard-crm',
                            },attrs:{"href":"/crm/dashboard"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Dashboard")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.onlyAdmin)),expression:"isRole(onlyAdmin)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active: this.$route.name == 'dashboard',
                            },attrs:{"href":"/dashboard"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Dashboard")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmStaffRoles)),expression:"isRole(crmStaffRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                    'dashboard-crm-staff',
                            },attrs:{"href":"/crm/dashboard/specialist"}},[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("Dashboard")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.onlyAdminCRM)),expression:"isRole(onlyAdminCRM)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'crm-specialist' ||
                                    this.$route.name ==
                                        'crm-specialist-detail' ||
                                    this.$route.name ==
                                        'crm-specialist-form',
                            },attrs:{"href":"/crm/specialist"}},[_c('i',{staticClass:"fa fa-user"}),_c('span',[_vm._v("CRM Specialist")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.assignCro)),expression:"isRole(assignCro)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'crm-assign-cro' ||
                                    this.$route.name ==
                                        'crm-assign-cro-detail',
                            },attrs:{"href":"/crm/assign/cro"}},[_c('i',{staticClass:"fa fa-user"}),_c('span',[_vm._v("Assign Data CRO")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                sub: true,
                                active:
                                    this.$route.name ==
                                        'crm-customers-leads' ||
                                    this.$route.name ==
                                        'crm-customers-leads-detail' ||
                                    this.$route.name ==
                                        'crm-customers-leads-prospect' ||
                                    this.$route.name ==
                                        'crm-customers-form' ||
                                    this.$route.name ==
                                        'crm-customers-form-edit' ||
                                    this.$route.name ==
                                        'crm-customers-leads-tags',
                            },attrs:{"href":"/crm/customers/leads"}},[_c('i',{staticClass:"fa fa-credit-card"}),_c('span',[_vm._v("Prospect and Leads Data")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}],staticClass:"sidebar-dropdown",class:{
                            '': true,
                            active:
                                this.$route.name == 'crm-prospect-today',
                        }},[_c('a',{class:this.$route.name == 'crm-prospect-today'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-tomorrow'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name == 'crm-prospect-all'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-two-days'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-week'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-month'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-need-follow-up'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-detail'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-prediction'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-lost'
                                    ? 'active'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-deal'
                                    ? 'active'
                                    : '',attrs:{"href":"javascript:void(0)"}},[_c('i',{staticClass:"fa fa-book"}),_c('span',[_vm._v("Follow Up")]),_c('i',{staticClass:"fa fa-angle-down pull-right"})]),_c('div',{staticClass:"sidebar-submenu",class:this.$route.name == 'crm-prospect-today'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-tomorrow'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name == 'crm-prospect-all'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-two-days'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-week'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-next-month'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-need-follow-up'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-detail'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-prediction'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-lost'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name ==
                                          'crm-prospect-deal'
                                    ? 'block'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-today'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name != 'crm-prospect-all'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-tomorrow'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-two-days'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-week'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-next-month'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-need-follow-up'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-detail'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-prediction'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-lost'
                                    ? 'none'
                                    : '' ||
                                      this.$route.name !=
                                          'crm-prospect-deal'
                                    ? 'none'
                                    : ''},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-all',
                                        },attrs:{"href":"/crm/prospect/all"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(2),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-all',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-all',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.today != null ? _vm.prospect_count?.today + _vm.prospect_count?.tomorrow + _vm.prospect_count?.next_2_days + _vm.prospect_count?.next_month + _vm.prospect_count?.need_follow_up : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-today',
                                        },attrs:{"href":"/crm/prospect/today"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(3),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-today',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-today',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.today ? _vm.prospect_count?.today : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-tomorrow',
                                        },attrs:{"href":"/crm/prospect/tomorrow"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(4),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-tomorrow',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-tomorrow',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.tomorrow ? _vm.prospect_count?.tomorrow : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-next-two-days',
                                        },attrs:{"href":"/crm/prospect/next-two-days"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(5),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-two-days',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-next-two-days',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.next_2_days ? _vm.prospect_count?.next_2_days : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-next-week',
                                        },attrs:{"href":"/crm/prospect/next-week"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(6),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-week',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-next-week',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.next_week ? _vm.prospect_count?.next_week : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-next-month',
                                        },attrs:{"href":"/crm/prospect/next-month"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(7),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-next-month',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-next-month',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.next_month ? _vm.prospect_count?.next_month : 0)+" ")])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRolesNonCRO)),expression:"isRole(crmRolesNonCRO)"}]},[_c('a',{class:{
                                            sub: true,
                                            active:
                                                this.$route.name ==
                                                'crm-prospect-need-follow-up',
                                        },attrs:{"href":"/crm/prospect/need-follow-up"}},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(8),_c('div',{staticClass:"ms-auto"},[_c('small',{staticClass:"tag radius",class:{
                                                        'blue-primary':
                                                            this.$route
                                                                .name ==
                                                            'crm-prospect-need-follow-up',
                                                        'gray-solid':
                                                            this.$route
                                                                .name !=
                                                            'crm-prospect-need-follow-up',
                                                    }},[_vm._v(" "+_vm._s(_vm.prospect_count?.need_follow_up ? _vm.prospect_count?.need_follow_up : 0)+" ")])])])])])])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmSpecialistCro)),expression:"isRole(crmSpecialistCro)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                        'crm-follow-up-cro' ||
                                    this.$route.name ==
                                        'crm-follow-up-cro-detail',
                            },attrs:{"href":"/crm/follow-up-cro"}},[_c('i',{staticClass:"fa fa-book"}),_c('span',[_vm._v("Follow Up CRO")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.onlyAdminCRM)),expression:"isRole(onlyAdminCRM)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'crm-target' ||
                                    this.$route.name ==
                                        'crm-order-create' ||
                                    this.$route.name == 'crm-order-edit',
                            },attrs:{"href":"/crm/target"}},[_c('i',{staticClass:"fa fa-building"}),_c('span',[_vm._v("Company Target")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRepeatOrder)),expression:"isRole(crmRepeatOrder)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'crm-order' ||
                                    this.$route.name == 'crm-order-detail',
                            },attrs:{"href":"/crm/order"}},[_c('i',{staticClass:"fa fa-cart-plus"}),_c('span',[_vm._v("Deal Order")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRepeatOrder)),expression:"isRole(crmRepeatOrder)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'crm-order-lost' ||
                                    this.$route.name ==
                                        'crm-order-lost-detail',
                            },attrs:{"href":"/crm/order-lost"}},[_c('i',{staticClass:"fa fa-shopping-cart"}),_c('span',[_vm._v("Lost Order")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmNonSpecialist)),expression:"isRole(crmNonSpecialist)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                        'gratitude-journal' ||
                                    this.$route.name ==
                                        'gratitude-journal-create' ||
                                    this.$route.name ==
                                        'gratitude-journal-user' ||
                                    this.$route.name ==
                                        'gratitude-journal-user-detail',
                            },attrs:{"href":"/crm/gratitude-journal"}},[_c('i',{staticClass:"fa fa-heart"}),_c('span',[_vm._v("Jurnal Syukur")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmRoles)),expression:"isRole(crmRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'crm-tags' ||
                                    this.$route.name == 'crm-tags-create' ||
                                    this.$route.name == 'crm-tags-detail',
                            },attrs:{"href":"/crm/tags"}},[_c('i',{staticClass:"fa fa-tags"}),_c('span',[_vm._v("Tags")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmAdminOnly)),expression:"isRole(crmAdminOnly)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name == 'crm-branchs' ||
                                    this.$route.name ==
                                        'crm-branchs-create' ||
                                    this.$route.name ==
                                        'crm-branchs-edit' ||
                                    this.$route.name ==
                                        'crm-branchs-detail',
                            },attrs:{"href":"/crm/branchs"}},[_c('i',{staticClass:"fa fa-bank"}),_c('span',[_vm._v("Klik’s Branch")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRole(_vm.crmStaffRoles)),expression:"isRole(crmStaffRoles)"}],staticClass:"sidebar-dropdown"},[_c('a',{class:{
                                '': true,
                                active:
                                    this.$route.name ==
                                        'gratitude-journal' ||
                                    this.$route.name ==
                                        'gratitude-journal-create' ||
                                    this.$route.name ==
                                        'gratitude-journal-user' ||
                                    this.$route.name ==
                                        'gratitude-journal-user-detail',
                            },attrs:{"href":'/crm/gratitude-journal/user/' + _vm.user_id}},[_c('i',{staticClass:"fa fa-heart"}),_c('span',[_vm._v("Jurnal Syukur")])])])])])])]),_c('main',{staticClass:"page-content"},[_c('div',{staticClass:"nav-top-main"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div'),_c('div',{staticClass:"ms-auto"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"notifications toggle dd-trigger me-4 cursor-pointer"},[_c('span',{staticClass:"count",attrs:{"id":"notifications-count"}},[_vm._v(" "+_vm._s(_vm.totalNotif)+" ")]),_c('span',{staticClass:"fa fa-bell-o size-22"})]),_c('div',{staticClass:"dropdown-menu"},[_vm._m(9),_c('hr'),(_vm.notif_all.length > 0)?[_c('div',{class:{
                                            'scroll-notif':
                                                _vm.notif_all.length > 4,
                                        }},[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"ms-auto"},[_c('a',{staticClass:"text-green",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readAllNotif()}}},[_c('u',[_vm._v("Tandai semua telah dibaca")])])])]),_vm._l((_vm.notif_all),function(notif,index){return [_c('a',{key:index,attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.readNotif(notif.id)}}},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('div',{staticClass:"me-2"},[_c('div',{staticClass:"user-avatar-sidebar user-notif",class:{
                                                                grayscale:
                                                                    notif.status ==
                                                                    'read',
                                                            },style:({
                                                                'background-image':
                                                                    'url(' +
                                                                    notif.icon +
                                                                    ')',
                                                            })},[(
                                                                    notif.status ==
                                                                    'pending'
                                                                )?_c('span',{staticClass:"position-absolute top-0 start-100 translate-middle p-alert-notif bg-warning border border-light rounded-circle"},[_c('span',{staticClass:"visually-hidden"},[_vm._v("New alerts")])]):_vm._e()])]),_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"size-16 fw-semibold",class:{
                                                                    'text-gray-dashboard':
                                                                        notif.status ==
                                                                        'read',
                                                                    'text-dark':
                                                                        notif.status ==
                                                                        'pending',
                                                                }},[_vm._v(" "+_vm._s(notif.title)+" ")]),_c('div',{staticClass:"ms-auto text-gray size-12"},[_vm._v(" "+_vm._s(notif.created_at)+" ")])]),_c('div',{class:{
                                                                'text-gray-dashboard':
                                                                    notif.status ==
                                                                    'read',
                                                                'text-dark':
                                                                    notif.status ==
                                                                    'pending',
                                                            }},[_vm._v(" "+_vm._s(notif.subtitle)+" ")])])])])]})],2)]:[_c('div',{staticClass:"mt-4"},[_c('center',[_c('img',{attrs:{"src":require("../assets/no-notif.png"),"width":"120","alt":""}}),_c('div',{staticClass:"mt-3 text-gray-dashboard"},[_vm._v(" Belum ada notifikasi ")])])],1)],(_vm.notif_all.length > 0)?_c('hr'):_vm._e(),(_vm.notif_all.length > 0)?_c('div',{staticClass:"text-center"},[_vm._m(10)]):_vm._e()],2)]),_c('div',{staticClass:"dropdown-profile"},[_c('div',{staticClass:"toggle-profile cursor-pointer"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"me-2"},[(_vm.user.avatar != null)?[_c('div',{staticClass:"user-avatar-sidebar user-small",style:({
                                                    'background-image':
                                                        'url(' +
                                                        _vm.user.avatar +
                                                        ')',
                                                })})]:[_c('div',{staticClass:"user-avatar-sidebar user-small",style:({
                                                    'background-image':
                                                        'url(' +
                                                        require('../assets/user.jpg') +
                                                        ')',
                                                })})]],2),_c('div',{staticClass:"fw-medium size-14",staticStyle:{"line-height":"17px"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.user.name)+" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                                        _vm.isRole(
                                                            _vm.crmDashboardRole
                                                        )
                                                    ),expression:"\n                                                        isRole(\n                                                            crmDashboardRole\n                                                        )\n                                                    "}],staticClass:"text-gray-second"},[_vm._v(" "+_vm._s(_vm.user.role_label)+" ")])]),_vm._m(11)])])])]),_c('div',{staticClass:"dropdown-menu-profile"},[_c('router-link',{attrs:{"to":{ name: _vm.profileRole() }}},[_c('a',{staticClass:"size-14 fw-semibold text-dark"},[_vm._v(" Edit Profile ")])]),_c('hr'),_c('a',{staticClass:"text-danger size-14 fw-semibold",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fa fa-sign-out me-1"}),_vm._v(" Log Out ")])],1)])])])])]),_c('div',{staticClass:"container-fluid mt-nav-top"},[_c('router-view',{attrs:{"message":this.$attrs.message,"totalNotif":_vm.totalNotif},on:{"update-data-event":_vm.getCountNotif}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn btn-sm btn-primary",attrs:{"id":"show-sidebar"}},[_c('i',{staticClass:"fa fa-angle-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-brand"},[_c('div',{attrs:{"id":"close-sidebar"}},[_c('img',{attrs:{"src":require("../assets/arrow-back.png"),"width":"30","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" All Follow Up ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Today ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Tomorrow ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next 2 Days ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next Week ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Next Month ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"fa fa-circle sub"}),_vm._v(" Need Follow Up ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex items-align-center"},[_c('div',{staticClass:"size-16 fw-semibold"},[_vm._v(" Notifikasi ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-green",attrs:{"href":"/notification"}},[_vm._v(" Lihat Semua Notifikasi "),_c('i',{staticClass:"fa fa-angle-right ms-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ms-2"},[_c('i',{staticClass:"fa fa-angle-down ms-2 size-16 fw-bold"})])
}]

export { render, staticRenderFns }
<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0 position-relative">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Notifikasi</h3>
            </div>
            <div class="d-flex align-items-center mb-3 mt-3">
                <div>
                    <button
                        class="btn btn-outline-black btn-sm px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div class="ms-auto" v-if="data.list_data.length > 0">
                    <a
                        href="javascript:void(0)"
                        @click="readAllNotif()"
                        class="text-green"
                    >
                        <u>Tandai semua telah dibaca</u>
                    </a>
                </div>
            </div>
            <div class="box-filter" v-if="isFilter">
                <h6 class="mb-0 fw-bold">Filter Notifikasi</h6>
                <div class="text-gray-dashboard mt-3">Tipe</div>
                <div class="text-gray-dashboard mt-3">Jangka Waktu</div>
                <div class="select_time_wrapper d-flex align-items-center">
                    <label for="today" class="mt-2 me-2">
                        <input
                            type="radio"
                            name="date"
                            v-model="date"
                            id="today"
                            value="today"
                            class="d-none"
                        />
                        <span class="text-center d-block"> Hari Ini </span>
                    </label>
                    <label for="last_week" class="mt-2 me-2">
                        <input
                            type="radio"
                            name="date"
                            v-model="date"
                            id="last_week"
                            value="last_week"
                            class="d-none"
                        />
                        <span class="text-center d-block"> 1 Minggu Lalu </span>
                    </label>
                    <label for="last_month" class="mt-2 me-2">
                        <input
                            type="radio"
                            name="date"
                            v-model="date"
                            id="last_month"
                            value="last_month"
                            class="d-none"
                        />
                        <span class="text-center d-block"> 1 Bulan Lalu </span>
                    </label>
                </div>
                <hr />
                <div class="d-flex">
                    <div class="ms-auto">
                        <button
                            class="btn btn-outline-red px-4 me-2"
                            @click="resetFilter()"
                            :disabled="type == '' && date == ''"
                        >
                            Reset
                        </button>
                        <button
                            class="btn btn-green px-4"
                            @click="filter()"
                            :disabled="type == '' && date == ''"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <template v-if="is_list_notif_ready">
                <ListLoader class="mt-4" />
            </template>
            <template v-else>
                <template v-if="data.list_data.length > 0">
                    <template v-for="(notif, index) in data.list_data">
                        <a
                            href="javascript:void(0)"
                            @click="readNotif(notif.id)"
                            :key="index"
                        >
                            <div class="box no-shadow border-gray p-3 mt-2">
                                <div class="d-flex align-items-center">
                                    <div class="me-2">
                                        <div
                                            class="notif-avatar-sidebar"
                                            v-bind:class="{
                                                grayscale:
                                                    notif.status == 'read',
                                            }"
                                            :style="{
                                                'background-image':
                                                    'url(' + notif.icon + ')',
                                            }"
                                        >
                                            <span
                                                class="position-absolute top-0 start-100 translate-middle p-alert-notif bg-warning border border-light rounded-circle"
                                                v-if="notif.status == 'pending'"
                                            >
                                                <span class="visually-hidden"
                                                    >New alerts</span
                                                >
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="d-flex align-items-center">
                                            <div
                                                class="size-16 fw-semibold"
                                                v-bind:class="{
                                                    'text-gray-dashboard':
                                                        notif.status == 'read',
                                                    'text-dark':
                                                        notif.status ==
                                                        'pending',
                                                }"
                                            >
                                                {{ notif.title }}
                                            </div>
                                        </div>
                                        <div
                                            v-bind:class="{
                                                'text-gray-dashboard':
                                                    notif.status == 'read',
                                                'text-dark':
                                                    notif.status == 'pending',
                                            }"
                                        >
                                            {{ notif.subtitle }}
                                        </div>
                                    </div>
                                    <div class="ms-auto text-gray">
                                        {{ notif.created_at }}
                                    </div>
                                </div>
                            </div>
                        </a>
                    </template>
                </template>
                <template v-else>
                    <div class="mt-4">
                        <center>
                            <img
                                src="../assets/no-notif.png"
                                width="120"
                                alt=""
                            />
                            <div class="mt-3 text-gray-dashboard">
                                Belum ada notifikasi
                            </div>
                        </center>
                    </div>
                </template>
            </template>
            <div class="mt-4">
                <b-pagination-nav
                    v-if="data.list_data.length > 0"
                    v-model="currentPage"
                    :number-of-pages="data.total_page"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    props: ["totalNotif"],
    name: "notificationPage",
    data() {
        return {
            per_page: 10,
            currentPage: 0,
            is_list_notif_ready: false,
            data: {
                list_data: [],
            },
            isFilter: false,
            type: "",
            date: "",
        };
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            let payload = {
                limit: this.per_page,
                type: this.type,
                date: this.date,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            this.is_list_notif_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/notification/user`, {
                params: payload,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.data = res.data.data;
                    this.is_list_notif_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_notif_ready = false;
                });
        },
        changePage() {
            this.getList();
        },
        readNotif(id) {
            var data = {
                notification_id: id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$emit("update-data-event");
                    this.getList();
                    console.log(res);
                    this.isFilter = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        readAllNotif() {
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/notification/user/update_all`,
                "",
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.getList();
                    this.$emit("update-data-event");
                    this.isFilter = false;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        filter() {
            this.getList();
            this.isFilter = false;
        },
        resetFilter() {
            this.type = "";
            this.date = "";
            this.isFilter = false;
            this.getList();
        },
    },
};
</script>

<style scoped>
.p-alert-notif {
    padding: 5px;
    left: 6px !important;
    top: 2px !important;
}
.radio_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f7f7f7;
    width: 100%;
    border-radius: 50px;
}
input[type="radio"] {
    appearance: none;
    display: none;
}

label.tab-radio-label {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    cursor: pointer;
    width: 33.33%;
    margin: 5px;
    height: 22px;
    text-align: center;
    border-radius: 50px;
    overflow: hidden;
    color: #000;
}

input.tab-radio-input[type="radio"]:checked + label {
    background-color: #3d8824;
    color: #fff;
    transition: 0.3s;
}
.grayscale {
    filter: grayscale(1);
    /* background-color: #e0e0e0; */
    background-blend-mode: overlay;
}
.notif-avatar-sidebar {
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.box-filter {
    background: #fff;
    padding: 15px !important;
    width: auto;
    position: absolute;
    z-index: 9999;
    left: 25px;
    top: 110px;
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border-radius: 12px;
}

.select_time_wrapper label span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: #fff;
    color: #808385;
    border: 2px solid #f7f7f7;
    padding: 2px 15px;
    border-radius: 20px;
}

.select_time_wrapper input:checked + span {
    background-color: #d8e7d3;
    cursor: pointer;
    color: #3d8824;
    border: 2px solid #d8e7d3;
    padding: 2px 15px;
    border-radius: 20px;
}
.custom-control-label::after {
    border-radius: 50% !important;
    width: 24px;
    height: 24px;
    top: 0px;
    color: transparent;
}
.form_bot_wrapper .custom-control-label::before {
    background-color: #ebebeb;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    top: 0;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ebebeb;
}
</style>

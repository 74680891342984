<template>
    <div>
        <!-- Default Data -->
        <div class="row" v-if="first_dashboard?.company == null">
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="box-dashboard w-100 mt-4">
                    <div class="d-flex align-items-center">
                        <h5 class="fw-semibold mb-0">Deal</h5>
                        <div class="ms-auto">
                            <a href="/crm/order" class="text-green">
                                <u>Lihat Detail</u>
                            </a>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="size-24 fw-bold mt-3">
                            {{
                                currency(default_dashboard?.result?.deal?.total)
                            }}
                        </div>
                        <div class="ms-3 mt-3 dropdown">
                            <a
                                href="javascript:void(0)"
                                id="dropdownDeleted"
                                data-bs-toggle="dropdown"
                            >
                                <img
                                    src="../../assets/info-green.png"
                                    width="20"
                                    alt=""
                                />
                            </a>
                            <div
                                class="dropdown-menu mt-1"
                                aria-labelledby="dropdownDeleted"
                            >
                                <div>New Order</div>
                                <div class="text-green fw-bold size-16">
                                    {{
                                        currency(
                                            default_dashboard?.result?.deal
                                                ?.new_order
                                        )
                                    }}
                                </div>
                                <div class="mt-2">Repeat Order</div>
                                <div class="text-green fw-bold size-16">
                                    {{
                                        currency(
                                            default_dashboard?.result?.deal
                                                ?.repeat_order
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-gray-second size-20">
                        {{
                            decimalFormat(
                                default_dashboard?.result?.deal?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="box-dashboard w-100 mt-4">
                    <div class="d-flex align-items-center">
                        <h5 class="fw-semibold mb-0">Prediksi</h5>
                    </div>
                    <div class="size-24 fw-bold mt-3">
                        {{
                            currency(
                                default_dashboard?.result?.prediction?.total
                            )
                        }}
                    </div>
                    <div class="text-gray-second size-20">
                        {{
                            decimalFormat(
                                default_dashboard?.result?.prediction
                                    ?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div class="box-dashboard w-100 mt-4">
                    <div class="d-flex align-items-center">
                        <h5 class="fw-semibold mb-0">Lost</h5>
                        <div class="ms-auto">
                            <a href="/crm/order-lost" class="text-green">
                                <u>Lihat Detail</u>
                            </a>
                        </div>
                    </div>
                    <div class="size-24 fw-bold mt-3">
                        {{
                            decimalFormat(
                                default_dashboard?.result?.lost?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
        </div>
        <!-- End Default Data -->

        <!-- Compare Data -->
        <div class="row" v-if="first_dashboard?.company != null">
            <div class="col-md-4 d-flex align-items-stretch">
                <div
                    class="box-dashboard w-100 mt-4"
                    v-bind:class="{
                        'box-active': first_dashboard?.company != null,
                    }"
                >
                    <div class="d-flex align-items-center">
                        <h5 class="fw-semibold mb-0">Deal</h5>
                        <div class="ms-auto">
                            <a href="" class="text-green">
                                <u>Lihat Detail</u>
                            </a>
                        </div>
                    </div>
                    <div class="mt-3 text-gray-second">
                        {{ compare_data_first }}
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="size-24 fw-bold">
                            {{ currency(first_dashboard?.result?.deal?.total) }}
                        </div>
                        <div class="ms-3 dropdown">
                            <a
                                href="javascript:void(0)"
                                id="dropdownDeleted"
                                data-bs-toggle="dropdown"
                            >
                                <img
                                    src="../../assets/info-green.png"
                                    width="20"
                                    alt=""
                                />
                            </a>
                            <div
                                class="dropdown-menu mt-1"
                                aria-labelledby="dropdownDeleted"
                            >
                                <div>New Order</div>
                                <div class="text-green fw-bold size-16">
                                    {{
                                        currency(
                                            first_dashboard?.result?.deal
                                                ?.new_order
                                        )
                                    }}
                                </div>
                                <div class="mt-2">Repeat Order</div>
                                <div class="text-green fw-bold size-16">
                                    {{
                                        currency(
                                            first_dashboard?.result?.deal
                                                ?.repeat_order
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-gray-second size-20">
                        {{
                            decimalFormat(
                                first_dashboard?.result?.deal?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                    <hr />
                    <div class="mt-3 text-gray-second">
                        {{ compare_data_second }}
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="size-24 fw-bold">
                            {{
                                currency(second_dashboard?.result?.deal?.total)
                            }}
                        </div>
                        <div class="ms-3 dropdown">
                            <a
                                href="javascript:void(0)"
                                id="dropdownDeleted"
                                data-bs-toggle="dropdown"
                            >
                                <img
                                    src="../../assets/info-green.png"
                                    width="20"
                                    alt=""
                                />
                            </a>
                            <div
                                class="dropdown-menu mt-1"
                                aria-labelledby="dropdownDeleted"
                            >
                                <div>New Order</div>
                                <div class="text-green fw-bold size-16">
                                    {{
                                        currency(
                                            second_dashboard?.result?.deal
                                                ?.new_order
                                        )
                                    }}
                                </div>
                                <div class="mt-2">Repeat Order</div>
                                <div class="text-green fw-bold size-16">
                                    {{
                                        currency(
                                            second_dashboard?.result?.deal
                                                ?.repeat_order
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-gray-second size-20">
                        {{
                            decimalFormat(
                                second_dashboard?.result?.deal?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div
                    class="box-dashboard w-100 mt-4"
                    v-bind:class="{
                        'box-active': first_dashboard?.company != null,
                    }"
                >
                    <div class="d-flex align-items-center">
                        <h5 class="fw-semibold mb-0">Prediksi</h5>
                    </div>
                    <div class="mt-3 text-gray-second">
                        {{ compare_data_first }}
                    </div>
                    <div class="size-24 fw-bold">
                        {{
                            currency(first_dashboard?.result?.prediction?.total)
                        }}
                    </div>
                    <div class="text-gray-second size-20">
                        {{
                            decimalFormat(
                                first_dashboard?.result?.prediction?.customer ??
                                    0
                            )
                        }}
                        Pelanggan
                    </div>
                    <hr />
                    <div class="mt-3 text-gray-second">
                        {{ compare_data_second }}
                    </div>
                    <div class="size-24 fw-bold">
                        {{
                            currency(
                                second_dashboard?.result?.prediction?.total
                            )
                        }}
                    </div>
                    <div class="text-gray-second size-20">
                        {{
                            decimalFormat(
                                second_dashboard?.result?.prediction
                                    ?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch">
                <div
                    class="box-dashboard w-100 mt-4"
                    v-bind:class="{
                        'box-active': first_dashboard?.company != null,
                    }"
                >
                    <div class="d-flex align-items-center">
                        <h5 class="fw-semibold mb-0">Lost</h5>
                        <div class="ms-auto">
                            <a href="" class="text-green">
                                <u>Lihat Detail</u>
                            </a>
                        </div>
                    </div>
                    <div class="mt-3 text-gray-second">
                        {{ compare_data_first }}
                    </div>
                    <div class="size-24 fw-bold">
                        {{
                            decimalFormat(
                                first_dashboard?.result?.lost?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                    <hr />
                    <div class="mt-3 text-gray-second">
                        {{ compare_data_first }}
                    </div>
                    <div class="size-24 fw-bold">
                        {{
                            decimalFormat(
                                second_dashboard?.result?.lost?.customer ?? 0
                            )
                        }}
                        Pelanggan
                    </div>
                </div>
            </div>
        </div>
        <!-- End Compare Data -->
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {},
    name: "DealPredicLost",
    props: [
        "default_dashboard",
        "first_dashboard",
        "second_dashboard",
        "compare_data_first",
        "compare_data_second",
    ],
    data() {
        return {
            moment: moment,
        };
    },
    created() {},
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
@keyframes color-mix {
    0% {
        box-shadow: #c1ebd3 0px 1px 3px 0px;
    }
    100% {
        box-shadow: #37a968 0px 1px 3px 0px;
    }
}
.box-active {
    animation: color-mix 1s linear infinite alternate;
    box-shadow: 0 1px 12px -1px currentColor;
}
.dropdown-menu {
    padding: 10px;
}
</style>

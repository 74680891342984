<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/crm/specialist" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">Detail CRM Specialist</h3>
            </div>
            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                <tr class="">
                    <td class="ps-0 w-200">Full Name</td>
                    <td class="w-30">:</td>
                    <td>{{ dataDetail.name }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Gender</td>
                    <td class="w-30">:</td>
                    <td>{{ titleCase(dataDetail.gender) }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Role</td>
                    <td class="w-30">:</td>
                    <td>{{ dataDetail.role }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Phone Number</td>
                    <td class="w-30">:</td>
                    <td>{{ dataDetail.phone }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Email</td>
                    <td class="w-30">:</td>
                    <td>{{ dataDetail.email }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Address</td>
                    <td class="w-30">:</td>
                    <td>{{ dataDetail.address }}</td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Status</td>
                    <td class="w-30">:</td>
                    <td>
                        <span
                            :class="[
                                dataDetail.is_active
                                    ? 'pill green'
                                    : 'pill red',
                            ]"
                            >{{
                                dataDetail.is_active ? "Active" : "Non Active"
                            }}</span
                        >
                    </td>
                </tr>
                <tr class="border-top">
                    <td class="ps-0 w-200">Photo</td>
                    <td class="w-30">:</td>
                    <td class="d-flex align-items-center">
                        <img
                            class="me-auto"
                            src="../../assets/image-icon.png"
                        /><a
                            href="javascript:void(0)"
                            @click="downloadImage(dataDetail.avatar)"
                            ><span style="color: #24516a"
                                >Download Photo
                                <img
                                    src="../../assets/download-icon.png" /></span
                        ></a>
                    </td>
                </tr>
            </table>
        </div>
        <div class="box mt-3">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">Target Information</h3>
            </div>

            <div class="d-flex align-items-center">
                <div class="me-4">
                    <div class="position-relative">
                        <input
                            class="form-control srch-size size-16 pl-30"
                            v-model="keySearchTarget"
                            @keyup.enter="searchData"
                            type="text"
                            style="width: 155px; padding-right: 30px"
                            placeholder="Search"
                        />
                        <span class="search-icon-left">
                            <i class="fa fa-search fa-beat fa-sm"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-auto me-2">Rows per page</div>
                <div>
                    <select
                        class="form-control form-select pr-28"
                        v-model="limitTarget"
                        @change="searchData"
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                    </select>
                </div>
            </div>

            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Month, Year</th>
                            <th class="text-center" scope="col">
                                Nominal Target
                            </th>
                            <th class="text-center" scope="col">Customers</th>
                            <th class="text-center" scope="col">
                                Nominal Achieved
                            </th>
                            <th class="text-center" scope="col">Gap</th>
                            <th class="text-center" scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in dataTarget" :key="index">
                            <td class="text-center">
                                {{
                                    numbering(
                                        index,
                                        currentPageTarget,
                                        limitTarget
                                    )
                                }}
                            </td>
                            <td class="">
                                {{ moment(item.date).format("DD MMMM YYYY") }}
                            </td>
                            <td class="text-center">
                                {{
                                    currency(
                                        item.nominal_target
                                            ? item.nominal_target
                                            : 0
                                    )
                                }}
                            </td>
                            <td class="text-center">
                                {{ item.customer_count }}
                            </td>
                            <td class="text-center">
                                {{ currency(item.nominal_achieved) }}
                            </td>
                            <td class="text-center">
                                {{ currency(item.gap) }}
                            </td>
                            <td class="text-center">
                                <span
                                    :class="[
                                        item.is_achieved
                                            ? 'pill green'
                                            : 'pill red',
                                    ]"
                                    >{{
                                        item.is_achieved
                                            ? "Achieved"
                                            : "Non Achieved"
                                    }}</span
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div
                    v-if="dataTarget.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="dataTarget.length > 0"
                    v-model="currentPageTarget"
                    :number-of-pages="totalPage"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage('target')"
                ></b-pagination-nav>
            </div>
        </div>

        <div class="box mt-3">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-3 me-auto">Activity Information</h3>
            </div>

            <div class="d-flex align-items-center">
                <div class="me-4">
                    <div class="position-relative">
                        <input
                            class="form-control srch-size size-16 pl-30"
                            v-model="keySearchActivity"
                            @keyup.enter="searchData"
                            type="text"
                            style="width: 155px; padding-right: 30px"
                            placeholder="Search"
                        />
                        <span class="search-icon-left">
                            <i class="fa fa-search fa-beat fa-sm"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-auto me-2">Rows per page</div>
                <div>
                    <select
                        class="form-control form-select pr-28"
                        v-model="limitActivity"
                        @change="searchData"
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                    </select>
                </div>
            </div>

            <div class="mt-4" v-if="is_my_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th
                                rowspan="2"
                                class="text-center align-middle"
                                scope="col"
                            >
                                No
                            </th>
                            <th
                                rowspan="2"
                                class="text-center align-middle"
                                scope="col"
                            >
                                Date
                            </th>
                            <th
                                rowspan="2"
                                class="text-center align-middle"
                                scope="col"
                            >
                                Activity
                            </th>
                            <th colspan="2" class="text-center" scope="col">
                                Prospect
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center" scope="col">
                                Customer Name
                            </th>
                            <th class="text-center" scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in dataActivity" :key="index">
                            <td class="text-center">
                                {{
                                    numbering(
                                        index,
                                        currentPageActivity,
                                        limitActivity
                                    )
                                }}
                            </td>
                            <td class="text-center">
                                {{ dateFormat(item.date, "DD/MM/YYYY") }}
                            </td>
                            <td class="">
                                {{ item.type }}
                            </td>
                            <td class="text-center">
                                {{ item.customer_name }}
                            </td>
                            <td class="text-center">
                                <span :class="checkStat(item.status)">{{
                                    setStatus(item.status)
                                }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div
                    v-if="dataActivity.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="overflow-auto mt-3">
                <b-pagination-nav
                    v-if="dataActivity.length > 0"
                    v-model="currentPageActivity"
                    :number-of-pages="totalPage"
                    base-url="#"
                    first-number
                    align="right"
                    @input="changePage('activity')"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    data() {
        return {
            moment: moment,
            dataDetail: "",
            dataTarget: "",
            dataActivity: "",
            keySearchTarget: "",
            keySearchActivity: "",
            is_my_list_ready: false,
            currentPageActivity: 0,
            currentPageTarget: 0,
            totalPage: 0,
            limitActivity: 10,
            limitTarget: 10,
        };
    },
    created() {
        this.getDataDetail();
        this.getDataTarget();
        this.getDataActivity();
    },
    methods: {
        searchData() {
            this.getDataTarget();
            this.getDataActivity();
        },
        changePage(value) {
            if (value == "activity") {
                this.getDataActivity();
            }
            if (value == "targer") {
                this.getDataTarget();
            }
        },
        getDataDetail() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist`,
                {
                    params: { id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataDetail = res.data.data;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                });
        },
        getDataTarget() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/target`,
                {
                    params: {
                        id: this.$route.params.id,
                        page:
                            this.currentPageTarget == 0 ||
                            this.currentPageTarget == null
                                ? 0
                                : this.currentPageTarget - 1,
                        limit: this.limitTarget,
                        search: this.keySearchTarget,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataTarget = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getDataActivity() {
            this.is_my_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/activities`,
                {
                    params: {
                        id: this.$route.params.id,
                        page:
                            this.currentPageActivity == 0 ||
                            this.currentPageActivity == null
                                ? 0
                                : this.currentPageActivity - 1,
                        limit: this.limitActivity,
                        search: this.keySearchActivity,
                    },
                    // params: { type: "employee", driver: 0 },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.dataActivity = res.data.data.list_data;
                    this.totalPage = res.data.data.total_page;
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    let msg = err.message;

                    if (err.response) {
                        // Request made and server responded
                        msg = err.response.data.message;
                        console.log(err.response.data);
                        console.log(err.response.status);
                        console.log(err.response.headers);
                    } else if (err.request) {
                        // The request was made but no response was received
                        console.log(err.request);
                        msg = "no response was received";
                    } else {
                        // Something happened in setting up the request that triggered an err
                        console.log("err", err.message);
                        msg = "err: " + err.message;
                    }

                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${msg}`,
                    });
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        downloadImage(url) {
            if (url) {
                window.open(url, "_blank").focus();
            } else {
                this.$notify({
                    group: "foo",
                    type: "warning",
                    title: "Message",
                    text: `image not available`,
                });
            }
        },
        setStatus(value) {
            if (value == "pending") return "Pending";
            if (value == "deal") return "Deal";
            if (value == "lost") return "Lost";
            return value;
        },
        checkStat(value) {
            if (value == "not_started") return "pill red";
            if (value == "in_progress") return "pill blue";
            if (value == "deferes") return "pill yellow";
            if (value == "completed") return "pill green";
        },
    },
};
</script>
<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.w-200 {
    width: 200px;
}
.w-30 {
    width: 30px;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px !important;
    height: 44px !important;
    border-radius: 8px;
}
.text-gray-dashboard {
    color: #61616a;
}
.srch-size {
    width: 294px !important;
    height: 44px !important;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
</style>

<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Back
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Deal Result</h3>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Resi</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Resi"
                        v-model="receipt_code"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2 m-mt-1">Customers Name</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Customers Name"
                        v-model="customer_name"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2 m-mt-1">Service</label>
                    <select class="form-control form-select" v-model="service">
                        <option value="">Select Service</option>
                        <option value="kirim-barang">Kirim Barang</option>
                        <option value="kirim-motor">Kirim Motor</option>
                        <option value="sewa-truck">Sewa Truk</option>
                        <option value="kirim-mobil">Kirim Mobil</option>
                        <option value="via-udara">Via Udara</option>
                        <option value="sewa-container">Sewa Container</option>
                    </select>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Price</label>
                    <money
                        v-model="price"
                        v-bind="price_input"
                        class="form-control"
                    >
                    </money>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Regency Origin</label>
                    <multiselect
                        v-model="regency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Select Regency"
                        class="multi-form-custom mt-2"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold">Regency Destination</label>
                    <multiselect
                        v-model="destination"
                        :options="destinations"
                        label="name"
                        track-by="id"
                        placeholder="Select Destination"
                        class="multi-form-custom mt-2"
                    >
                    </multiselect>
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="
                        receiptData?.product == 'kirim-barang' ||
                        receiptData == null
                    "
                >
                    <label class="fw-bold mb-2">Weight</label>
                    <money
                        v-model="weight"
                        v-bind="weight_input"
                        class="form-control"
                    >
                    </money>
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="receiptData?.product == 'sewa-truck'"
                >
                    <label class="fw-bold mb-2">Armada</label>
                    <input
                        type="text"
                        class="form-control capitalize"
                        placeholder="Armada"
                        v-model="fleet_type"
                    />
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="receiptData?.product == 'kirim-mobil'"
                >
                    <label class="fw-bold mb-2">Service</label>
                    <input
                        type="text"
                        class="form-control capitalize"
                        placeholder="Service"
                        v-model="service_type"
                    />
                </div>
                <div
                    class="col-md-6 mt-3"
                    v-if="receiptData?.product == 'kirim-mobil'"
                >
                    <label class="fw-bold mb-2">Car Type</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Car Type"
                        v-model="car_type"
                    />
                </div>
                <div v-if="isRole" class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">CRM Specialist</label>
                    <multiselect
                        v-model="crm_specialist_id"
                        :options="crmSpecialist"
                        label="name"
                        :loading="is_load"
                        track-by="id"
                        placeholder="CRM Specialist"
                    >
                    </multiselect>
                </div>
                <div class="col-md-12 mt-3">
                    <label class="fw-bold mb-2">Description</label>
                    <textarea
                        rows="5"
                        cols="4"
                        class="form-control"
                        placeholder="Description"
                        v-model="description"
                    ></textarea>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="d-flex">
                        <div class="ms-auto">
                            <a
                                href="javascript:history.back()"
                                class="btn btn-outline-black btn-lg px-5 me-3 m-w-100"
                                >Cancel</a
                            >
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                type="button"
                                disabled
                                v-if="is_save"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Save
                            </button>
                            <button
                                class="btn btn-green btn-lg px-5 me-3 m-w-100 m-mt-1"
                                @click="storeData()"
                                v-else
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Data Saved!!</h4>
                </div>
                <div class="mt-4">
                    <a href="/crm/order" class="btn btn-green w-100 btn-lg">
                        Ok
                    </a>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Penambahan Data Gagal!</h4>
                    <div class="mt-2">
                        Mohon maaf terjadi kesalahan! Silakan ulangi kembali
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "ManagementMasterRouteCreate",
    data() {
        return {
            modalSuccess: false,
            modalError: false,
            origin_regency: null,
            destination_regency: null,
            is_load: false,
            isRole: false,
            receiptData: null,
            crmSpecialist: [],
            customer_name: "",
            weight: 0,
            weight_input: {
                decimal: ",",
                thousands: ".",
                suffix: "",
                precision: 0,
                masked: false,
            },
            price: 0,
            price_input: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
            },
            prospect_route: "",
            crm_specialist_id: "",
            origin_regency_id: "",
            destination_regency_id: "",
            receipt_code: "",
            description: "",
            is_save: false,
            fleet_type: "",
            service_type: "",
            car_type: "",
            regencies: [],
            regency: "",
            destinations: [],
            destination: "",
            service: "",
        };
    },
    created() {
        this.getCrmSpecialist();
        this.getRegency();
        if (this.$route.params.isRole) {
            localStorage.setItem("isRole", this.$route.params.isRole);
        }
        this.isRole = localStorage.getItem("isRole");
    },
    methods: {
        getCrmSpecialist() {
            this.is_load = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/crm/account/specialist/dropdown`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.crmSpecialist = res.data.data;
                    this.is_load = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load = false;
                });
        },
        storeData() {
            this.is_save = true;
            var data = {
                id: this.$route.params.id,
                customer_name: this.customer_name,
                receipt_code: this.receipt_code,
                origin_regency_id: this.regency.id,
                destination_regency_id: this.destination.id,
                weight: this.weight,
                amount: this.price,
                description: this.description,
                service: this.service,
            };
            if (this.isRole) {
                data.crm_specialist_id = this.crm_specialist_id.id;
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/crm/prospect/result/deal`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                    this.destinations = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped>
.text-main-color {
    color: #24516a !important;
}
.text-gray-dashboard {
    color: #61616a;
}
.money-custom {
    height: 42px;
    font-size: 16px;
    padding-left: 15px;
}
</style>

<template>
    <div class="page-sidebar-layout">
        <Header></Header>
        <div class="page-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import Header from "../components/HeaderAdmin.vue";

    export default {
        components: {
            Header,
        },
        setup() {
        },
        created() {},
        methods: {
        }
    };
</script>